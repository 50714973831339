import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './core/guards/auth.guard';
import {AuthLayoutComponent} from './core/layouts/auth-layout/auth-layout.component';
import {ContentLayoutComponent} from './core/layouts/content-layout/content-layout.component';
import {CguComponent} from './core/pages/cgu/cgu.component';
import {ErrorPageComponent} from './core/pages/error-page/error-page.component';
import {PageNotFoundComponent} from './core/pages/page-not-found/page-not-found.component';
import {ModificationMdpGuard} from './core/guards/modification-mdp.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/home',
  },
  {
    path: '',
    component: ContentLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [ModificationMdpGuard],
    children: [
      {
        path: 'cgu',
        component: CguComponent,
        data: {consultation: true},
      },
      {
        path: 'home',
        loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'comptes',
        loadChildren: () => import('./modules/compte/compte.module').then((m) => m.CompteModule),
      },
      {
        path: 'cartes',
        loadChildren: () => import('./modules/carte/carte.module').then((m) => m.CarteModule),
      },
      {
        path: 'virements',
        loadChildren: () => import('./modules/virement/virement.module').then((m) => m.VirementModule),
      },
      {
        path: 'profil',
        loadChildren: () => import('./modules/profil-client/profil-client.module').then((m) => m.ProfilClientModule),
      },
      {
        path: 'messagerie',
        loadChildren: () => import('./modules/messagerie/messagerie.module').then((m) => m.MessagerieModule),
      },
      {
        path: 'delegations',
        loadChildren: () => import('./modules/delegation/delegation.module').then((m) => m.DelegationModule),
      },
      {
        path: 'alerte',
        loadChildren: () => import('./modules/alerte/alerte.module').then((m) => m.AlerteModule),
      },
      {
        path: 'chequier',
        loadChildren: () => import('./modules/chequier/chequier.module').then((m) => m.ChequierModule),
      },
      {
        path: 'releves',
        loadChildren: () => import('./modules/e-releves/e-releves.module').then((m) => m.ERelevesModule),
      },
      {
        path: 'budget',
        loadChildren: () => import('./modules/budget/budget.module').then((m) => m.BudgetModule),
      },
      {
        path: 'faq',
        loadChildren: () => import('./modules/faq/faq.module').then((m) => m.FaqModule),
      },
      {
        path: 'socsecure',
        loadChildren: () => import('./modules/socsecure/socsecure.module').then((m) => m.SocsecureModule),
      },
      {
        path: 'kyc',
        loadChildren: () => import('./modules/kyc/kyc.module').then((m) => m.KycModule),
      },
      {
        path: 'souscription',
        loadChildren: () => import('./modules/souscription/souscription.module').then((m) => m.SouscriptionModule),
      },
      {
        path: 'paiement-en-ligne',
        loadChildren: () => import('./modules/paiement-en-ligne/paiement-en-ligne.module').then((m) => m.PaiementEnLigneModule),
      },
      {
        path: 'demande-credit',
        loadChildren: () => import('./modules/demande-credit/demande-credit.module').then((m) => m.DemandeCreditModule),
      },
    ],
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'error',
    component: ErrorPageComponent,
  },
  // Fallback when no prior routes is matched
  {path: '**', component: PageNotFoundComponent},
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, { enableTracing: false, scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
