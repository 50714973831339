import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';


@Component({
  selector: 'app-compte-rendu-popup',
  templateUrl: './compte-rendu-popup.component.html',
  styleUrls: ['./compte-rendu-popup.component.css'],
})
export class CompteRenduPopupComponent implements OnInit {

  /** le texte à afficher */
  @Input() html?: string;

  /** fermeture de la modal */
  @Output() close: EventEmitter<any> = new EventEmitter<any>();


  constructor(readonly sanitized: DomSanitizer) {
  }
  static show() {
    const simpleModal = $('#compte-rendu-popup');
    if (!simpleModal.hasClass('js-active')) {
      simpleModal.addClass('js-active');
    }
  }

  static hide() {
    const simpleModal = $('#compte-rendu-popup');
    if (simpleModal.hasClass('js-active')) {
      simpleModal.removeClass('js-active');
    }
  }

  hide(){
    CompteRenduPopupComponent.hide();
    this.close.emit();
  }

  ngOnInit() {
  }

}
