import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationPopoverModule} from 'angular-confirmation-popover';
import {GoogleChartsModule} from 'angular-google-charts';
import {LaddaModule} from 'angular2-ladda';
import {NgBootstrapFormValidationModule} from 'ng-bootstrap-form-validation';
import {LoggerModule, NgxLoggerLevel} from 'ngx-logger';
import {NumericDirectiveDirective} from './directives/numeric-directive.directive';
import {AjaxLoaderComponent} from './components/ajax-loader/ajax-loader.component';
import {AnrGeneriqueComponent} from './components/anr-generique/anr-generique.component';
import {AnrComponent} from './components/anr/anr.component';
import {ConfirmationWesocComponent} from './components/confirmation-wesoc/confirmation-wesoc.component';
import {ContactWidgetComponent} from './components/contact-widget/contact-widget.component';
import {CookiesModalComponent} from './components/cookies-modal/cookies-modal.component';
import {CookiesComponent} from './components/cookies/cookies.component';
import {FooterContactComponent} from './components/footer-contact/footer-contact.component';
import {GrapheRepartitionCompteComponent} from './components/graphe-repartition-compte/graphe-repartition-compte.component';
import {GrapheSoldeCompteComponent} from './components/graphe-solde-compte/graphe-solde-compte.component';
import {GrapheValorisationAssuranceComponent} from './components/graphe-valorisation-assurance/graphe-valorisation-assurance.component';
import {BaseHeaderComponent} from './components/header/base-header.component';
import {InfosAnrComponent} from './components/infos-anr/infos-anr.component';
import {InfosMdpComponent} from './components/infos-mdp/infos-mdp.component';
import {MessageErreurComponent} from './components/message-erreur/message-erreur.component';
import {ModificationMotDePasseComponent} from './components/modification-mot-de-passe/modification-mot-de-passe.component';
import {SecuriteModalComponent} from './components/securite-modal/securite-modal.component';
import {SimplePopupComponent} from './components/simple-popup/simple-popup.component';
import {RadialIndicatorDirective} from './directives/radial-indicator.directive';
import {SlickCartesDirective} from './directives/slick-cartes.directive';
import {SlickComptesDirective} from './directives/slick-comptes.directive';
import {TokenInterceptor} from './interceptors/token-interceptor';
import {UnauthorizedInterceptor} from './interceptors/unauthorized-interceptor';
import {AuthLayoutComponent} from './layouts/auth-layout/auth-layout.component';
import {ContentLayoutComponent} from './layouts/content-layout/content-layout.component';
import {FooterComponent} from './layouts/footer/footer.component';
import {AlertesComponent} from './layouts/header/alertes/alertes.component';
import {DisplayUserComponent} from './layouts/header/display-user/display-user.component';
import {HeaderComponent} from './layouts/header/header.component';
import {NavigationComponent} from './layouts/header/navigation/navigation.component';
import {ParametresComponent} from './layouts/header/parametres/parametres.component';
import {ProfilComponent} from './layouts/header/profil/profil.component';
import {CguComponent} from './pages/cgu/cgu.component';
import {ErrorPageComponent} from './pages/error-page/error-page.component';
import {PageNotFoundComponent} from './pages/page-not-found/page-not-found.component';
import {ToastComponent} from './components/toast/toast.component';
import {AnrEmailComponent} from './components/anr-email/anr-email.component';
import {environment} from '../../environments/environment';
import {ForceAutoFocusDirective} from './directives/force-auto-focus.directive';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {AnrSocsecureComponent} from './components/anr-socsecure/anr-socsecure.component';
import {CompteRenduPopupComponent} from './components/compte-rendu-popup/compte-rendu-popup.component';
import {ErreurOrLoadComponent} from './components/erreur-or-load/erreur-or-load.component';
import {ListePagineeGeneriqueComponent} from './components/liste-paginee-generique/liste-paginee-generique.component';
import {ListeComponent} from './components/liste/liste.component';
import {NgbdSortableHeaderDirective} from './directives/sortable.directive';
import {SortableHeaderComponent} from './components/sortable-header/sortable-header.component';
import {LoadedDirective} from './directives/loaded.directive';
import {FileUploadModule} from 'ng2-file-upload';

@NgModule({
  declarations: [
    PageNotFoundComponent,
    FooterComponent,
    HeaderComponent,
    AuthLayoutComponent,
    ContentLayoutComponent,
    DisplayUserComponent,
    NavigationComponent,
    ProfilComponent,
    AlertesComponent,
    BaseHeaderComponent,
    RadialIndicatorDirective,
    FooterContactComponent,
    AjaxLoaderComponent,
    ContactWidgetComponent,
    GrapheValorisationAssuranceComponent,
    SlickComptesDirective,
    GrapheSoldeCompteComponent,
    GrapheRepartitionCompteComponent,
    SlickCartesDirective,
    AnrComponent,
    ParametresComponent,
    SimplePopupComponent,
    InfosAnrComponent,
    MessageErreurComponent,
    CguComponent,
    ErrorPageComponent,
    NumericDirectiveDirective,
    InfosMdpComponent,
    ModificationMotDePasseComponent,
    ConfirmationWesocComponent,
    BaseHeaderComponent,
    SecuriteModalComponent,
    AnrGeneriqueComponent,
    CookiesComponent,
    CookiesModalComponent,
    ToastComponent,
    AnrEmailComponent,
    ForceAutoFocusDirective,
    AnrSocsecureComponent,
    CompteRenduPopupComponent,
    ErreurOrLoadComponent,
    NgbdSortableHeaderDirective,
    LoadedDirective,
    ListeComponent,
    ListePagineeGeneriqueComponent,
    SortableHeaderComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    NgBootstrapFormValidationModule,
    LaddaModule,
    FormsModule,
    NgbModule,
    CommonModule,
    LoggerModule.forRoot({level: environment.logLevel, serverLogLevel: NgxLoggerLevel.OFF}),
    GoogleChartsModule.forRoot(),
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger',
      cancelButtonType: 'secondary',
      popoverClass: 'piti-popover',
      appendToBody: true,
      confirmText: 'Confirmer',
      cancelText: 'Annuler',
      closeOnOutsideClick: true,
    }),
    PdfViewerModule,
    FileUploadModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true,
    },
  ],
  exports: [
    RadialIndicatorDirective,
    AjaxLoaderComponent,
    SlickComptesDirective,
    GrapheSoldeCompteComponent,
    GrapheRepartitionCompteComponent,
    GrapheValorisationAssuranceComponent,
    SlickCartesDirective,
    AnrComponent,
    SimplePopupComponent,
    MessageErreurComponent,
    NumericDirectiveDirective,
    InfosMdpComponent,
    ConfirmationWesocComponent,
    ModificationMotDePasseComponent,
    BaseHeaderComponent,
    SecuriteModalComponent,
    HeaderComponent,
    AnrGeneriqueComponent,
    CookiesComponent,
    CookiesModalComponent,
    ToastComponent,
    ForceAutoFocusDirective,
    AnrSocsecureComponent,
    CompteRenduPopupComponent,
    ErreurOrLoadComponent,
    NgbdSortableHeaderDirective,
    LoadedDirective,
    ListeComponent,
    ListePagineeGeneriqueComponent,
    SortableHeaderComponent
  ]
})
export class CoreModule {
}
