import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-message-erreur[erreur]',
  templateUrl: './message-erreur.component.html',
  styleUrls: ['./message-erreur.component.css'],
})
export class MessageErreurComponent implements OnInit {
  /** le message d'erreur à afficher */
  @Input() erreur: string;
  /** doit-on afficher le picto */
  @Input() picto = true;

  constructor() {
  }

  ngOnInit() {
  }

}
