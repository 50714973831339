import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {environment} from '../../../../environments/environment';
import {SecuriteModalComponent} from '../../components/securite-modal/securite-modal.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {

  urls = environment.urlsInstitutionelles;
  version = environment.version;

  constructor(private ngModal: NgbModal) {
  }

  ngOnInit() {
  }

  securite() {

    const modalRef = this.ngModal.open(SecuriteModalComponent, {
      size: 'lg',
      windowClass: 'taux-modal',
      centered: true,
    });
  }
}
