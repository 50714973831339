<div class="anr_title_form text-start pb-3">Le contrôle ANR, qu’est-ce que c’est ?</div>

<p *ngIf="typeAnr === 'ANR'">Afin de renforcer la sécurité des opérations via WEBSOC), la Banque SOCREDO met en
  place un dispositif d’authentification par code envoyé par sms : l’Authentification Non Rejouable (ANR) ou
  code à usage unique.</p>

<p *ngIf="typeAnr === 'EMAIL'">Afin de renforcer la sécurité des opérations via WEBSOC), la Banque SOCREDO met en place
  un dispositif d’authentification par code envoyé par email :
  l’Authentification Non Rejouable (ANR) ou code à usage unique.</p>

Ce code ANR :
<ul>
  <li>- ne peut être utilisé que pour l’opération en cours</li>
  <li>- a une durée de validité de 5 minutes.</li>
</ul>
<ng-container *ngIf="typeAnr === 'ANR'">
  Sur vos outils en ligne, cette authentification sera activée dans les cas suivants :
  <ul>
    <li>- La saisie d’un nouveau bénéficiaire pour un virement (local, France/OM ou international)</li>
    <li> - La saisie d’un virement local, France/OM dépassant un montant seuil fixé par la banque</li>
    <li>- La saisie d’un virement international</li>
    <li>- L’opposition en ligne d’une carte</li>
    <li>- La modification temporaire d’un plafond de carte</li>
  </ul>
</ng-container>
La procédure est identique à chaque fois :
<ul>
  <li>- Réception d’un code comportant 6
    chiffres {{ typeAnr === 'ANR' ? 'sur le smartphone' : 'sur votre adresse email'}}</li>
  <li>- Saisie du code</li>
  <li>- Authentification acceptée</li>
  <li>- Poursuite de la saisie sur le site client</li>
</ul>
<p *ngIf="typeAnr==='ANR'">
  Dans le cas où vous n’avez pas de numéro de téléphone mobile enregistré dans votre fiche client SOCREDO (ou
  si le numéro de téléphone présenté est incorrect), le code ANR ne pourra pas vous être envoyé et donc la
  fonction « virement » du site client ne pourra pas être utilisée pour une somme &gt; seuil fixé par la
  banque
  ou nécessitant d’enregistrer un nouveau bénéficiaire.</p>
<p>
<p *ngIf="typeAnr==='EMAIL'">
  Dans le cas où vous n’avez pas d’adresse email enregistrée dans votre fiche client SOCREDO (ou si l’adresse email
  présentée est incorrecte), le code ANR ne pourra pas vous être envoyé, vous ne pourrez donc pas aller au bout de votre
  opération. </p>
<p>
  Nous vous invitons dans ce cas à appeler notre centre d’appel au 40 47 00 00 ou votre chargé de clientèle
  pour effectuer une mise à jour.
</p>
