<section class="modal confirm-remove-modal"
         id="simple-popup" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-sm" role="document">
    <div class="modal-content position-relative py-5 px-3">
      <div class="modal-header"><a class="close-modal-btn" (click)="close.emit()"><i
        class="icon-close cursor-pointer"></i></a>
      </div>
      <div class="modal-body text-center px-3">
        <p>{{bodyText}}</p>
      </div>
    </div>
  </div>
</section>
