import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-securite-modal',
  templateUrl: './securite-modal.component.html',
  styleUrls: ['./securite-modal.component.css'],
})
export class SecuriteModalComponent implements OnInit {

  constructor(private ngModalActive: NgbActiveModal) {
  }

  ngOnInit() {
  }

  closeModal() {
    this.ngModalActive.close();
  }

}
