/**
 * Hoba/wesoc API 3.0
 * API pour l'application bancaire SOCREDO.
 *
 * OpenAPI spec version: 3.00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpResponse} from '@angular/common/http';

import {Observable} from 'rxjs';

import {ComptesAComptesVirement} from '../model/comptesAComptesVirement';
import {ComptesBeneficiairesVirement} from '../model/comptesBeneficiairesVirement';
import {ComptesBeneficiairesVirementInternational} from '../model/comptesBeneficiairesVirementInternational';
import {DemandeAnr} from '../model/demandeAnr';
import {Virement} from '../model/virement';
import {VirementBeneficiaire} from '../model/virementBeneficiaire';
import {VirementBeneficiaireDiffere} from '../model/virementBeneficiaireDiffere';
import {VirementBeneficiaireInternational} from '../model/virementBeneficiaireInternational';
import {VirementBeneficiaireInternationalDiffere} from '../model/virementBeneficiaireInternationalDiffere';
import {VirementBeneficiairePermanent} from '../model/virementBeneficiairePermanent';
import {VirementCompteACompte} from '../model/virementCompteACompte';
import {VirementCompteACompteDiffere} from '../model/virementCompteACompteDiffere';
import {VirementCompteAComptePermanent} from '../model/virementCompteAComptePermanent';
import {VirementMultiBeneficiaire} from '../model/virementMultiBeneficiaire';
import {VirementMultiBeneficiaireComplet} from '../model/virementMultiBeneficiaireComplet';
import {VirementPermanent} from '../model/virementPermanent';
import {BASE_PATH} from '../conf/variables';
import {Configuration} from '../conf/configuration';
import {VirementListe} from '../model/virementListe';


@Injectable()
export class VirementService {

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  protected basePath = 'https://www.socms.pf/hoba/api';

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   *
   * Valide un virement en attente (Saisiseur-Valideur)
   * @param numeroClient numéro du client
   * @param idVirementMultiBeneficiaire id du virement multiBeneficiaire
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public validationVirementMultiBenef(numeroClient: string, idVirementMultiBeneficiaire: number, observe?: 'body', reportProgress?: boolean): Observable<DemandeAnr>;
  public validationVirementMultiBenef(numeroClient: string, idVirementMultiBeneficiaire: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DemandeAnr>>;
  public validationVirementMultiBenef(numeroClient: string, idVirementMultiBeneficiaire: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DemandeAnr>>;
  public validationVirementMultiBenef(numeroClient: string, idVirementMultiBeneficiaire: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling validationVirementMultiBenef.');
    }

    if (idVirementMultiBeneficiaire === null || idVirementMultiBeneficiaire === undefined) {
      throw new Error('Required parameter idVirementMultiBeneficiaire was null or undefined when calling validationVirementMultiBenef.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.put<DemandeAnr>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/virements/multiBeneficiaire/${encodeURIComponent(String(idVirementMultiBeneficiaire))}/valide`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Rejete un virement en attente (Saisiseur-Valideur)
   * @param numeroClient numéro du client
   * @param idVirementMultiBeneficiaire id du virement multiBeneficiaire
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public rejetVirementMultiBenef(numeroClient: string, idVirementMultiBeneficiaire: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public rejetVirementMultiBenef(numeroClient: string, idVirementMultiBeneficiaire: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public rejetVirementMultiBenef(numeroClient: string, idVirementMultiBeneficiaire: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public rejetVirementMultiBenef(numeroClient: string, idVirementMultiBeneficiaire: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling rejetVirementMultiBenef.');
    }

    if (idVirementMultiBeneficiaire === null || idVirementMultiBeneficiaire === undefined) {
      throw new Error('Required parameter idVirementMultiBeneficiaire was null or undefined when calling rejetVirementMultiBenef.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.put<any>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/virements/multiBeneficiaire/${encodeURIComponent(String(idVirementMultiBeneficiaire))}/rejete`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Annule un ordre de virement
   * @param numeroClient numéro du client
   * @param idVirement id du virement
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public annuleVirement(numeroClient: string, idVirement: string, observe?: 'body', reportProgress?: boolean): Observable<any>;

  public annuleVirement(numeroClient: string, idVirement: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;

  public annuleVirement(numeroClient: string, idVirement: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;

  public annuleVirement(numeroClient: string, idVirement: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling annuleVirement.');
    }

    if (idVirement === null || idVirement === undefined) {
      throw new Error('Required parameter idVirement was null or undefined when calling annuleVirement.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<any>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/virements/${encodeURIComponent(String(idVirement))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * annule un virement en attente (Saisiseur-Valideur)
   * @param numeroClient numéro du client
   * @param idVirementAValider id du virement à valider
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public annuleVirementAValider(numeroClient: string, idVirementAValider: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public annuleVirementAValider(numeroClient: string, idVirementAValider: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public annuleVirementAValider(numeroClient: string, idVirementAValider: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public annuleVirementAValider(numeroClient: string, idVirementAValider: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling annuleVirementAValider.');
    }

    if (idVirementAValider === null || idVirementAValider === undefined) {
      throw new Error('Required parameter idVirementAValider was null or undefined when calling annuleVirementAValider.');
    }

    let headers = this.defaultHeaders;

    // authentication (BearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('delete', `${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/virementsAValider/${encodeURIComponent(String(idVirementAValider))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * effecute un virement compte vers bénéficiaire différé
   * création d&#x27;un virement compte vers bénéficiaire différé
   * @param body le virement à effectuer
   * @param numeroClient numéro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createVirementBeneficiaireDiffere(body: VirementBeneficiaireDiffere, numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<any>;

  public createVirementBeneficiaireDiffere(body: VirementBeneficiaireDiffere, numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;

  public createVirementBeneficiaireDiffere(body: VirementBeneficiaireDiffere, numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;

  public createVirementBeneficiaireDiffere(body: VirementBeneficiaireDiffere, numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling createVirementBeneficiaireDiffere.');
    }

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling createVirementBeneficiaireDiffere.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json',
      'text/plain'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/virements/beneficiaire/differe/`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * effecute un virement compte vers bénéficiaire immédiat
   *
   * @param body le virement à effectuer
   * @param numeroClient numéro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createVirementBeneficiaireImmediat(body: VirementBeneficiaire, numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<DemandeAnr>;

  public createVirementBeneficiaireImmediat(body: VirementBeneficiaire, numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DemandeAnr>>;

  public createVirementBeneficiaireImmediat(body: VirementBeneficiaire, numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DemandeAnr>>;

  public createVirementBeneficiaireImmediat(body: VirementBeneficiaire, numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling createVirementBeneficiaireImmediat.');
    }

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling createVirementBeneficiaireImmediat.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json',
      'text/plain'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<DemandeAnr>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/virements/beneficiaire/immediat/`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * effecute un virement compte vers un bénéficiaire international en différé
   *
   * @param body le virement à effectuer
   * @param numeroClient numéro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createVirementBeneficiaireInternationalDiffere(body: VirementBeneficiaireInternationalDiffere, numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<any>;

  public createVirementBeneficiaireInternationalDiffere(body: VirementBeneficiaireInternationalDiffere, numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;

  public createVirementBeneficiaireInternationalDiffere(body: VirementBeneficiaireInternationalDiffere, numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;

  public createVirementBeneficiaireInternationalDiffere(body: VirementBeneficiaireInternationalDiffere, numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling createVirementBeneficiaireInternationalDiffere.');
    }

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling createVirementBeneficiaireInternationalDiffere.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json',
      'text/plain'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/virements/beneficiaireInternational/differe/`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * effecute un virement compte vers un bénéficiaire international en immédiat
   *
   * @param body le virement à effectuer
   * @param numeroClient numéro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createVirementBeneficiaireInternationalImmediat(body: VirementBeneficiaireInternational, numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<any>;

  public createVirementBeneficiaireInternationalImmediat(body: VirementBeneficiaireInternational, numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;

  public createVirementBeneficiaireInternationalImmediat(body: VirementBeneficiaireInternational, numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;

  public createVirementBeneficiaireInternationalImmediat(body: VirementBeneficiaireInternational, numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling createVirementBeneficiaireInternationalImmediat.');
    }

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling createVirementBeneficiaireInternationalImmediat.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json',
      'text/plain'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/virements/beneficiaireInternational/immediat/`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * effecute un virement compte vers bénéficiaire permanent
   *
   * @param body le virement à effectuer
   * @param numeroClient numéro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createVirementBeneficiairePermanent(body: VirementBeneficiairePermanent, numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<any>;

  public createVirementBeneficiairePermanent(body: VirementBeneficiairePermanent, numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;

  public createVirementBeneficiairePermanent(body: VirementBeneficiairePermanent, numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;

  public createVirementBeneficiairePermanent(body: VirementBeneficiairePermanent, numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling createVirementBeneficiairePermanent.');
    }

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling createVirementBeneficiairePermanent.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json',
      'text/plain'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/virements/beneficiaire/permanent/`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * effecute un virement compte à compte différé
   *
   * @param body le virement à effectuer
   * @param numeroClient numéro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createVirementCompteACompteDiffere(body: VirementCompteACompteDiffere, numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<any>;

  public createVirementCompteACompteDiffere(body: VirementCompteACompteDiffere, numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;

  public createVirementCompteACompteDiffere(body: VirementCompteACompteDiffere, numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;

  public createVirementCompteACompteDiffere(body: VirementCompteACompteDiffere, numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling createVirementCompteACompteDiffere.');
    }

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling createVirementCompteACompteDiffere.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json',
      'text/plain'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/virements/compteACompte/differe/`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * effecute un virement compte à compte immédiat
   *
   * @param body le virement à effectuer
   * @param numeroClient numéro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createVirementCompteACompteImmediat(body: VirementCompteACompte, numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<any>;

  public createVirementCompteACompteImmediat(body: VirementCompteACompte, numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;

  public createVirementCompteACompteImmediat(body: VirementCompteACompte, numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;

  public createVirementCompteACompteImmediat(body: VirementCompteACompte, numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling createVirementCompteACompteImmediat.');
    }

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling createVirementCompteACompteImmediat.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json',
      'text/plain'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/virements/compteACompte/immediat/`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * effecute un virement compte à compte permanent
   *
   * @param body le virement à effectuer
   * @param numeroClient numéro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createVirementCompteAComptePermament(body: VirementCompteAComptePermanent, numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<any>;

  public createVirementCompteAComptePermament(body: VirementCompteAComptePermanent, numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;

  public createVirementCompteAComptePermament(body: VirementCompteAComptePermanent, numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;

  public createVirementCompteAComptePermament(body: VirementCompteAComptePermanent, numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling createVirementCompteAComptePermament.');
    }

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling createVirementCompteAComptePermament.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json',
      'text/plain'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/virements/compteACompte/permanent/`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * effecute un virement multi bénéficiaire
   * @param body le virement à effectuer
   * @param numeroClient numéro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createVirementMultiBeneficiaire(body: VirementMultiBeneficiaire, numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<DemandeAnr>;

  public createVirementMultiBeneficiaire(body: VirementMultiBeneficiaire, numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DemandeAnr>>;

  public createVirementMultiBeneficiaire(body: VirementMultiBeneficiaire, numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DemandeAnr>>;

  public createVirementMultiBeneficiaire(body: VirementMultiBeneficiaire, numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling createVirementMultiBeneficiaire.');
    }

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling createVirementMultiBeneficiaire.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json',
      'text/plain'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<DemandeAnr>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/virements/multiBeneficiaire`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * duplique un virement multi bénéficiaire
   * @param body le virement à effectuer
   * @param numeroClient numéro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public dupliqueVirementMultiBeneficiaire(body: VirementMultiBeneficiaireComplet, numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<DemandeAnr>;

  public dupliqueVirementMultiBeneficiaire(body: VirementMultiBeneficiaireComplet, numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DemandeAnr>>;

  public dupliqueVirementMultiBeneficiaire(body: VirementMultiBeneficiaireComplet, numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DemandeAnr>>;

  public dupliqueVirementMultiBeneficiaire(body: VirementMultiBeneficiaireComplet, numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling dupliqueVirementMultiBeneficiaire.');
    }

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling dupliqueVirementMultiBeneficiaire.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json',
      'text/plain'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<DemandeAnr>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/virements/dupliqueMultiBeneficiaire`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Liste des comptes pour effectuer un virements compte à compte
   *
   * @param numeroClient numéro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listeComptesAComptes(numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<ComptesAComptesVirement>;

  public listeComptesAComptes(numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ComptesAComptesVirement>>;

  public listeComptesAComptes(numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ComptesAComptesVirement>>;

  public listeComptesAComptes(numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling listeComptesAComptes.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<ComptesAComptesVirement>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/virements/comptesAcomptes/`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Liste des comptes pour effectuer un virements compte vers bénéficiaire
   *
   * @param numeroClient numéro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listeComptesBeneficiaires(numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<ComptesBeneficiairesVirement>;

  public listeComptesBeneficiaires(numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ComptesBeneficiairesVirement>>;

  public listeComptesBeneficiaires(numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ComptesBeneficiairesVirement>>;

  public listeComptesBeneficiaires(numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling listeComptesBeneficiaires.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<ComptesBeneficiairesVirement>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/virements/comptesBeneficiaires/`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Liste des comptes pour effectuer un virements compte vers bénéficiaire internationaux
   *
   * @param numeroClient numéro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listeComptesBeneficiairesInternationaux(numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<ComptesBeneficiairesVirementInternational>;

  public listeComptesBeneficiairesInternationaux(numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ComptesBeneficiairesVirementInternational>>;

  public listeComptesBeneficiairesInternationaux(numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ComptesBeneficiairesVirementInternational>>;

  public listeComptesBeneficiairesInternationaux(numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling listeComptesBeneficiairesInternationaux.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<ComptesBeneficiairesVirementInternational>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/virements/comptesBeneficiairesInternationaux/`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * rejette un virement en attente (Saisiseur-Valideur)
   * @param numeroClient numéro du client
   * @param idVirementAValider id du virement à valider
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public rejetVirement(numeroClient: string, idVirementAValider: number, observe?: 'body', reportProgress?: boolean): Observable<any>;

  public rejetVirement(numeroClient: string, idVirementAValider: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;

  public rejetVirement(numeroClient: string, idVirementAValider: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;

  public rejetVirement(numeroClient: string, idVirementAValider: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling rejetVirement.');
    }

    if (idVirementAValider === null || idVirementAValider === undefined) {
      throw new Error('Required parameter idVirementAValider was null or undefined when calling rejetVirement.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.put<any>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/virementsAValider/${encodeURIComponent(String(idVirementAValider))}/rejete`,
      null,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Valide un virement en attente (Saisiseur-Valideur)
   * @param numeroClient numéro du client
   * @param idVirementAValider id du virement à valider
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public validationVirement(numeroClient: string, idVirementAValider: number, observe?: 'body', reportProgress?: boolean): Observable<DemandeAnr>;

  public validationVirement(numeroClient: string, idVirementAValider: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DemandeAnr>>;

  public validationVirement(numeroClient: string, idVirementAValider: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DemandeAnr>>;

  public validationVirement(numeroClient: string, idVirementAValider: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling validationVirement.');
    }

    if (idVirementAValider === null || idVirementAValider === undefined) {
      throw new Error('Required parameter idVirementAValider was null or undefined when calling validationVirement.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.put<DemandeAnr>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/virementsAValider/${encodeURIComponent(String(idVirementAValider))}/valide`,
      null,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Récupère le détail d&#x27;un ordre de virement compte à compte, local ou métropole
   * @param numeroClient numéro du client
   * @param idVirement id du virement
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public virement(numeroClient: string, idVirement: string, observe?: 'body', reportProgress?: boolean): Observable<Virement>;

  public virement(numeroClient: string, idVirement: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Virement>>;

  public virement(numeroClient: string, idVirement: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Virement>>;

  public virement(numeroClient: string, idVirement: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling virement.');
    }

    if (idVirement === null || idVirement === undefined) {
      throw new Error('Required parameter idVirement was null or undefined when calling virement.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Virement>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/virements/${encodeURIComponent(String(idVirement))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Récupère le détail d&#x27;un virement à valider
   * @param numeroClient numéro du client
   * @param idVirementAValider id du virement à valider
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public virementAValider(numeroClient: string, idVirementAValider: number, observe?: 'body', reportProgress?: boolean): Observable<Virement>;

  public virementAValider(numeroClient: string, idVirementAValider: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Virement>>;

  public virementAValider(numeroClient: string, idVirementAValider: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Virement>>;

  public virementAValider(numeroClient: string, idVirementAValider: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling virementAValider.');
    }

    if (idVirementAValider === null || idVirementAValider === undefined) {
      throw new Error('Required parameter idVirementAValider was null or undefined when calling virementAValider.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Virement>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/virementsAValider/${encodeURIComponent(String(idVirementAValider))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Récupère le détail d&#x27;un virement à valider
   * @param numeroClient numéro du client
   * @param idVirementAValider id du virement à valider
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public virementAValiderPermanent(numeroClient: string, idVirementAValider: number, observe?: 'body', reportProgress?: boolean): Observable<VirementPermanent>;

  public virementAValiderPermanent(numeroClient: string, idVirementAValider: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VirementPermanent>>;

  public virementAValiderPermanent(numeroClient: string, idVirementAValider: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VirementPermanent>>;

  public virementAValiderPermanent(numeroClient: string, idVirementAValider: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling virementAValiderPermanent.');
    }

    if (idVirementAValider === null || idVirementAValider === undefined) {
      throw new Error('Required parameter idVirementAValider was null or undefined when calling virementAValiderPermanent.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<VirementPermanent>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/virementsAValiderPermanent/${encodeURIComponent(String(idVirementAValider))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Récupère le détail d&#x27;un virement comptabilisé compte à compte, local ou métropole
   * @param numeroClient numéro du client
   * @param idVirement id du virement
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public virementComptable(numeroClient: string, idVirement: string, observe?: 'body', reportProgress?: boolean): Observable<Virement>;

  public virementComptable(numeroClient: string, idVirement: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Virement>>;

  public virementComptable(numeroClient: string, idVirement: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Virement>>;

  public virementComptable(numeroClient: string, idVirement: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling virementComptable.');
    }

    if (idVirement === null || idVirement === undefined) {
      throw new Error('Required parameter idVirement was null or undefined when calling virementComptable.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Virement>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/virementsComptabilises/${encodeURIComponent(String(idVirement))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Export d&#x27;un virement
   * @param numeroClient numéro du client
   * @param idVirement id du virement
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public virementExport(numeroClient: string, idVirement: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;

  public virementExport(numeroClient: string, idVirement: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;

  public virementExport(numeroClient: string, idVirement: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;

  public virementExport(numeroClient: string, idVirement: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling virementExport.');
    }

    if (idVirement === null || idVirement === undefined) {
      throw new Error('Required parameter idVirement was null or undefined when calling virementExport.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/pdf'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/virements/${encodeURIComponent(String(idVirement))}/export`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        responseType: 'blob',
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Récupère le détail d&#x27;un virement international
   * @param numeroClient numéro du client
   * @param idVirement id du virement
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public virementInternational(numeroClient: string, idVirement: string, observe?: 'body', reportProgress?: boolean): Observable<Virement>;

  public virementInternational(numeroClient: string, idVirement: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Virement>>;

  public virementInternational(numeroClient: string, idVirement: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Virement>>;

  public virementInternational(numeroClient: string, idVirement: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling virementInternational.');
    }

    if (idVirement === null || idVirement === undefined) {
      throw new Error('Required parameter idVirement was null or undefined when calling virementInternational.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Virement>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/virementsInternational/${encodeURIComponent(String(idVirement))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Export d&#x27;un virement
   * @param numeroClient numéro du client
   * @param idVirement id du virement
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public virementInternationalExport(numeroClient: string, idVirement: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;

  public virementInternationalExport(numeroClient: string, idVirement: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;

  public virementInternationalExport(numeroClient: string, idVirement: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;

  public virementInternationalExport(numeroClient: string, idVirement: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling virementInternationalExport.');
    }

    if (idVirement === null || idVirement === undefined) {
      throw new Error('Required parameter idVirement was null or undefined when calling virementInternationalExport.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/pdf'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/virementsInternational/${encodeURIComponent(String(idVirement))}/export`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        responseType: 'blob',
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Récupère le détail d&#x27;un virement permanent
   * @param numeroClient numéro du client
   * @param idVirement id du virement
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public virementPermanent(numeroClient: string, idVirement: string, observe?: 'body', reportProgress?: boolean): Observable<VirementPermanent>;

  public virementPermanent(numeroClient: string, idVirement: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VirementPermanent>>;

  public virementPermanent(numeroClient: string, idVirement: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VirementPermanent>>;

  public virementPermanent(numeroClient: string, idVirement: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling virementPermanent.');
    }

    if (idVirement === null || idVirement === undefined) {
      throw new Error('Required parameter idVirement was null or undefined when calling virementPermanent.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<VirementPermanent>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/virementsPermanent/${encodeURIComponent(String(idVirement))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Export d&#x27;un virement de nature compta
   * @param numeroClient numéro du client
   * @param idVirement id du virement
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public virementComptableExport(numeroClient: string, idVirement: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;

  public virementComptableExport(numeroClient: string, idVirement: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;

  public virementComptableExport(numeroClient: string, idVirement: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;

  public virementComptableExport(numeroClient: string, idVirement: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling virementComptableExport.');
    }

    if (idVirement === null || idVirement === undefined) {
      throw new Error('Required parameter idVirement was null or undefined when calling virementComptableExport.');
    }

    let headers = this.defaultHeaders;

    // authentication (BearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/pdf'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/virementsComptabilises/${encodeURIComponent(String(idVirement))}/export`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        responseType: 'blob',
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Récupère le détail d&#x27;un virement international en attente de transmission
   * @param numeroClient numéro du client
   * @param idVirement id du virement
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public virementInternationalEnAttente(numeroClient: string, idVirement: string, observe?: 'body', reportProgress?: boolean): Observable<Virement>;

  public virementInternationalEnAttente(numeroClient: string, idVirement: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Virement>>;

  public virementInternationalEnAttente(numeroClient: string, idVirement: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Virement>>;

  public virementInternationalEnAttente(numeroClient: string, idVirement: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling virementInternationalEnAttente.');
    }

    if (idVirement === null || idVirement === undefined) {
      throw new Error('Required parameter idVirement was null or undefined when calling virementInternationalEnAttente.');
    }

    let headers = this.defaultHeaders;

    // authentication (BearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Virement>('get', `${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/virementsInternationalEnAttente/${encodeURIComponent(String(idVirement))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Annule un virement international en attente
   * @param numeroClient numéro du client
   * @param idVirement id du virement
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public annuleVirementInternationalEnAttente(numeroClient: string, idVirement: string, observe?: 'body', reportProgress?: boolean): Observable<any>;

  public annuleVirementInternationalEnAttente(numeroClient: string, idVirement: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;

  public annuleVirementInternationalEnAttente(numeroClient: string, idVirement: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;

  public annuleVirementInternationalEnAttente(numeroClient: string, idVirement: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling annuleVirementInternationalEnAttente.');
    }

    if (idVirement === null || idVirement === undefined) {
      throw new Error('Required parameter idVirement was null or undefined when calling annuleVirementInternationalEnAttente.');
    }

    let headers = this.defaultHeaders;

    // authentication (BearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('delete', `${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/virementsInternationalEnAttente/${encodeURIComponent(String(idVirement))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Export d&#x27;un virement multibeneficiaire
   * @param numeroClient numéro du client
   * @param idVirementMultiBeneficiaire id du virement multiBeneficiaire
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public virementMultiBeneficiaireExport(numeroClient: string, idVirementMultiBeneficiaire: number, observe?: 'body', reportProgress?: boolean): Observable<Blob>;

  public virementMultiBeneficiaireExport(numeroClient: string, idVirementMultiBeneficiaire: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;

  public virementMultiBeneficiaireExport(numeroClient: string, idVirementMultiBeneficiaire: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;

  public virementMultiBeneficiaireExport(numeroClient: string, idVirementMultiBeneficiaire: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling virementMultiBeneficiaireExport.');
    }

    if (idVirementMultiBeneficiaire === null || idVirementMultiBeneficiaire === undefined) {
      throw new Error('Required parameter idVirementMultiBeneficiaire was null or undefined when calling virementMultiBeneficiaireExport.');
    }

    let headers = this.defaultHeaders;

    // authentication (BearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/pdf'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/virements/exportMultiBeneficiaire/${encodeURIComponent(String(idVirementMultiBeneficiaire))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        responseType: 'blob',
      }
    );
  }

  /**
   *
   * Annule un lot de virement en attente (Saisiseur-Valideur)
   * @param body les viremenst
   * @param numeroClient numéro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public annuleLotVirementAValider(body: Array<VirementListe>, numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<any>;

  public annuleLotVirementAValider(body: Array<VirementListe>, numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;

  public annuleLotVirementAValider(body: Array<VirementListe>, numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;

  public annuleLotVirementAValider(body: Array<VirementListe>, numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling annuleLotVirementAValider.');
    }

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling annuleLotVirementAValider.');
    }

    let headers = this.defaultHeaders;

    // authentication (BearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>('put', `${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/virementsAValider/lot/annuler`,
      {
        body,
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress
      }
    );
  }

  /**
   *
   * Rejete un lot de virement en attente (Saisiseur-Valideur)
   * @param body les viremenst
   * @param numeroClient numéro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public rejeteLotVirementAValider(body: Array<VirementListe>, numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<any>;

  public rejeteLotVirementAValider(body: Array<VirementListe>, numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;

  public rejeteLotVirementAValider(body: Array<VirementListe>, numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;

  public rejeteLotVirementAValider(body: Array<VirementListe>, numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling rejeteLotVirementAValider.');
    }

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling rejeteLotVirementAValider.');
    }

    let headers = this.defaultHeaders;

    // authentication (BearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>('put', `${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/virementsAValider/lot/rejeter`,
      {
        body,
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress
      }
    );
  }

  /**
   *
   * Valide un lot de virement en attente (Saisiseur-Valideur)
   * @param body les viremenst
   * @param numeroClient numéro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public valideLotVirementAValider(body: Array<VirementListe>, numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<DemandeAnr>;

  public valideLotVirementAValider(body: Array<VirementListe>, numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DemandeAnr>>;

  public valideLotVirementAValider(body: Array<VirementListe>, numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DemandeAnr>>;

  public valideLotVirementAValider(body: Array<VirementListe>, numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling valideLotVirementAValider.');
    }

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling valideLotVirementAValider.');
    }

    let headers = this.defaultHeaders;

    // authentication (BearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<DemandeAnr>('put', `${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/virementsAValider/lot/valider`,
      {
        body,
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress
      }
    );
  }

  /**
   * effecute un virement compte vers bénéficiaire instanté
   *
   * @param body le virement à effectuer
   * @param numeroClient numéro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createVirementBeneficiaireInstantane(body: VirementBeneficiaire, numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<DemandeAnr>;

  public createVirementBeneficiaireInstantane(body: VirementBeneficiaire, numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DemandeAnr>>;

  public createVirementBeneficiaireInstantane(body: VirementBeneficiaire, numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DemandeAnr>>;

  public createVirementBeneficiaireInstantane(body: VirementBeneficiaire, numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling createVirementBeneficiaireInstantane.');
    }

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling createVirementBeneficiaireInstantane.');
    }

    let headers = this.defaultHeaders;

    // authentication (BearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json',
      'text/plain'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<DemandeAnr>('post', `${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/virements/beneficiaire/instantane/`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * effecute un virement compte à compte instantané
   *
   * @param body le virement à effectuer
   * @param numeroClient numéro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createVirementCompteACompteInstantane(body: VirementCompteACompte, numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public createVirementCompteACompteInstantane(body: VirementCompteACompte, numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public createVirementCompteACompteInstantane(body: VirementCompteACompte, numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public createVirementCompteACompteInstantane(body: VirementCompteACompte, numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling createVirementCompteACompteInstantane.');
    }

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling createVirementCompteACompteInstantane.');
    }

    let headers = this.defaultHeaders;

    // authentication (BearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json',
      'text/plain'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/virements/compteACompte/instantane/`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

}
