<div class="overflow-hidden confirmation-virement-form-container">
  <section class="page-heading">
    <div class="container">
      <div class="row">
        <div class="col-12 d-flex align-items-center justify-content-between">
          <h2 class="page-heading__text">{{titre}}</h2>
        </div>
      </div>
    </div>
  </section>
  <ng-container *ngIf="!erreur else Erreur">
    <ng-content></ng-content>
    <section class="confirmation-virement-form mot_de_passe_section">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 pb-5">
            <div class="anr_block pt-5">
              <div class="anr_title_form text-center ">Veuillez renseigner votre mot de passe Espace client</div>
              <div class="text-center align-items-center  p-4">
                <img alt="main tapant un mot de passe" height="128" src="assets/images/ico-dial.png" width="128"/>
              </div>
            </div>
            <div class="mot_de_passe_section">
              <form class="anr_form" [formGroup]="formGroup" (validSubmit)="validSubmit()">
                <div class="code_block pt-3 pb-3">
                  <div class="code_content_block form-group p-1">
                    <div class="input_block d-flex align-items-center justify-content-center flex-grow-1 ps-4">
                      <input class="form-control" formControlName="mdp" maxlength="64"
                             type="password"
                             id="validation_code">
                    </div>
                  </div>
                </div>
                <div class="actions_block text-center">
                  <button class="btn btn-outline-secondary" mwlConfirmationPopover
                          [popoverTitle]="''"
                          type="button"
                          [closeOnOutsideClick]="true"
                          cancelText="Annuler"
                          confirmText="Confirmer"
                          [popoverMessage]="'Etes-vous sûr de vouloir abandonner votre saisie ?'"
                          (confirm)="retourLink()">Annuler
                  </button>
                  <button [ladda]="isSubmit" class="btn btn-outline-primary">Valider</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>

  </ng-container>
</div>
<ng-template #Erreur>
  <app-message-erreur [erreur]="erreur"></app-message-erreur>
</ng-template>
