<section class="modal"
         id="compte-rendu-popup" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" style="max-width: 1000px" role="document">
    <div class="modal-content position-relative pt-5 pd-0 px-3">
      <div class="modal-header"><a class="close-modal-btn" (click)="hide()"><i
        class="icon-close cursor-pointer"></i></a>
      </div>

      <div class="modal-body text-center px-3">
        <h3>Validation par lot</h3>
        <div *ngIf="html" [innerHtml]="this.sanitized.bypassSecurityTrustHtml(html)"></div>
      </div>
    </div>
  </div>
</section>
