import {Component} from '@angular/core';

@Component({
  selector: 'app-ajax-loader',
  templateUrl: './ajax-loader.component.html',
  styleUrls: ['./ajax-loader.component.css'],
})
export class AjaxLoaderComponent {

  constructor() {
  }


}
