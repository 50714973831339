import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-infos-mdp',
  templateUrl: './infos-mdp.component.html',
  styleUrls: ['./infos-mdp.component.css']
})
export class InfosMdpComponent implements OnInit {

  constructor() {
  }

  /** pour pouvoir utiliser cette fonction dans la template */
  toggleModal() {
    const modal = $('#moreinfo-modal');
    if (modal.hasClass('js-active')) {
      modal.removeClass('js-active');
    } else {
      modal.addClass('js-active');
    }
  }

  ngOnInit() {
  }

}
