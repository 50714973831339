/**
 * Hoba/wesoc API 3.0
 * API pour l'application bancaire SOCREDO.
 *
 * OpenAPI spec version: 3.00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import {HttpClient, HttpEvent, HttpHeaders, HttpResponse,} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {Observable} from 'rxjs';
import {Configuration} from '../conf/configuration';
import {BASE_PATH} from '../conf/variables';


@Injectable()
export class BICService {

  protected basePath = 'https://www.socms.pf/hoba/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Controle du BIC
   * retourne la libelle de la banque correspondant au BIC
   * @param bic Bank Identifier Code
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public controleBic(bic: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
  public controleBic(bic: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
  public controleBic(bic: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
  public controleBic(bic: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (bic === null || bic === undefined) {
      throw new Error('Required parameter bic was null or undefined when calling controleBic.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', 'text/plain, application/json; q=0.9');
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get(`${this.basePath}/bic/${encodeURIComponent(String(bic))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        responseType: 'text',
        reportProgress,
      },
    );
  }

}
