import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-base-header[titre]',
  templateUrl: './base-header.component.html',
  styleUrls: ['./base-header.component.css'],
})
export class BaseHeaderComponent {

  /** Titre de la page. */
  @Input()
  titre: string;
}
