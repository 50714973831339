import {Component, Input, OnInit} from '@angular/core';
import {TypeAnrEnum} from '../../../api/model/typeAnrEnum';

@Component({
  selector: 'app-infos-anr',
  templateUrl: './infos-anr.component.html',
  styleUrls: ['./infos-anr.component.css']
})
export class InfosAnrComponent implements OnInit {

  @Input()
  typeAnr: TypeAnrEnum = 'ANR';

  constructor() { }

  ngOnInit() {
  }

}
