<ng-container *ngIf="!erreur else Erreur">
  <app-anr #anrComponent (confirmationEvent)="onConfirmation($event)" *ngIf="demandeAnr?.type === 'ANR'"
           [demandeAnr]="demandeAnr"
           [lienAnnulation]="lienAnnulation"
           [titre]="titre"
           [bandeauAffiche]="bandeauAffiche">

    <ng-container *ngTemplateOutlet="Recapitulatif"></ng-container>

  </app-anr>
  <app-anr-email #anrComponent (confirmationEvent)="onConfirmation($event)" *ngIf="demandeAnr?.type === 'EMAIL'"
                 [demandeAnr]="demandeAnr"
                 [lienAnnulation]="lienAnnulation"
                 [titre]="titre">

    <ng-container *ngTemplateOutlet="Recapitulatif"></ng-container>

  </app-anr-email>

  <app-confirmation-wesoc (confirmationEvent)="onConfirmation($event)" *ngIf="demandeAnr?.type === 'CONFIRMATION_PWD'"
                          [demandeAnr]="demandeAnr" [titre]="titre">
    <ng-container *ngTemplateOutlet="Recapitulatif"></ng-container>
  </app-confirmation-wesoc>

  <app-anr-socsecure #anrComponent (confirmationEvent)="onConfirmation($event)" *ngIf="demandeAnr?.type === 'SOCSECURE'"
                          [demandeAnr]="demandeAnr" [titre]="titre">
    <ng-container *ngTemplateOutlet="Recapitulatif"></ng-container>
  </app-anr-socsecure>
</ng-container>

<ng-template #Recapitulatif>
  <ng-container *ngIf="demandeAnr">
    <ng-content></ng-content>
  </ng-container>
</ng-template>
<ng-template #Erreur>
  <div class="container">
    <app-message-erreur [erreur]="erreur"></app-message-erreur>
  </div>
</ng-template>
