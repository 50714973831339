import {Injectable} from '@angular/core';
import {ScriptLoaderService} from 'angular-google-charts';
import {NGXLogger} from 'ngx-logger';
import {CoreServiceModule} from '../../core-service.module';
import {GoogleChartsService} from './google-charts.service';

@Injectable({
  providedIn: CoreServiceModule,
})
export class GoogleChartRepartitionService extends GoogleChartsService {

  constructor(protected loaderService: ScriptLoaderService, protected logger: NGXLogger) {
    super(loaderService, logger);
  }

  /**
   * Construit le graphe.
   * @param elementId ID HTML
   * @param data les données
   * @param color les couleurs
   */
  public buildChart(elementId: string, data: any[], color: string[]): void {
    const chartFunc = () => {
      try {
        return new google.visualization.PieChart(document.getElementById(elementId));
      } catch (error) {
        this.logger.warn('Impossible de charge le graphe', error);
        return null;
      }
    };

    const dataTableFunc = (): google.visualization.DataTable => {
      const dataTable = new google.visualization.DataTable();
      dataTable.addColumn('string', 'Catégorie');
      dataTable.addColumn('number', 'Montant');
      dataTable.addColumn({type: 'string', role: 'tooltip', p: {html: true}});
      dataTable.addRows(data);
      this.logger.debug('datatable', dataTable);
      return dataTable;
    };

    const options = {
      pieHole: 0.75,
      legend: 'none',
      chartArea: {left: '5%', top: '5%', width: '90%', height: '90%'},
      slices: color,
      pieSliceText: 'none',
      tooltip: {isHtml: true},
    };

    this.buildChartDataTable(elementId, dataTableFunc, chartFunc, options);
  }
}
