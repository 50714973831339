import {HttpErrorResponse} from '@angular/common/http';
import {ErrorHandler, Injectable} from '@angular/core';
import * as Sentry from '@sentry/browser';
import {NGXLogger} from 'ngx-logger';
import {environment} from '../environments/environment';

// mise en place de sentry
Sentry.init({
  dsn: 'https://4dd66181b0a746e98ac02acf997c09d3@o49545.ingest.sentry.io/4652122',
  release: 'hoba-angular@' + environment.version,
  // https://github.com/getsentry/sentry-javascript/issues/2292
  ignoreErrors: [
    'Non-Error exception captured'
  ]
});

/** Gestion des erreurs globales. */
@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  private chunkFailedMessage = /Loading chunk [\d]+ failed/;
  // Pour gèrer ERROR [main.js:15494] Error: 'maxDate' [object Object] should be greater than 'minDate' [object Object]
  // des datepicker ngbootstrap
  private dateMaxMinMessage = /should be greater than 'minDate'/;
  /** pour traiter 'impossible de charger les favoris', 'Impossible de récupérer...' */
  private hobaMessage = /Impossible de/;

  constructor(private logger: NGXLogger) {
  }

  handleError(error) {
    // Pour gèrer les "loading chunk failed" qui se produisent lors de mise à jour de code
    // voir https://medium.com/@kamrankhatti/angular-lazy-routes-loading-chunk-failed-42b16c22a377
    if (this.chunkFailedMessage.test(error.message)) {
      window.location.reload();
      return;
    }

    // messages à ignorer
    if (this.dateMaxMinMessage.test(error.message) || this.hobaMessage.test(error.message)) {
      // on l'ignore
      return;
    }

    // pas de sentry pour les erreurs HTTP ou string (les nôtres!)
    if (!(error instanceof HttpErrorResponse) && (typeof error !== 'string')) {
      this.logger.error(error);
      const eventId = Sentry.captureException(error.originalError || error);
      if (environment.production) {
        // on continue
        throw error;
      } else {
        // Pour afficher une popup d'erreur
        Sentry.showReportDialog({eventId});
      }
    }
  }
}
