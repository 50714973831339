<a class="user-item__icon d-flex align-items-center position-relative cursor-pointer"><i
  class="icon-setting user-item__icon__image"></i></a>
<div class="user-menu">
  <div class="user-menu-wrapper">
    <a class="user-menu__item"
       [routerLink]="['/profil/informations']"><span>Mes informations personnelles</span></a>
    <a class="user-menu__item" [routerLink]="['/profil/modification-mdp']"><span>Mon mot de passe</span></a>
    <a class="user-menu__item" [routerLink]="['/socsecure/aide']"><span>SOC Secure</span></a>
    <a class="user-menu__item" [routerLink]="['/socsecure/securite']"><span>Sécurité</span></a>
  </div>
</div>
