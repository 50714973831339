import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {environment} from '../../../environments/environment';
import {CoreServiceModule} from '../core-service.module';
import {Cookie} from '../models/cookie';
import {HomeService} from '../../api/api/home.service';
import {BaseService} from './base.service';
import {ErreursService} from './erreurs.service';
import {UserService} from './user.service';

/** Pour le Google Analytics. */
declare let gtag: any;

/**
 *  Interface du service des cookies.
 */
export interface ICookieService {
  /** Accepte tous les cookies. */
  accepteAllCookies(): void;

  /** Accepte les cookies en l'état. */
  accepteCookies(): void;

  /** Refuse tous les cookies (sauf les required). */
  refuseCookies(): void;
}

@Injectable({
  providedIn: CoreServiceModule,
})
export class CookiesService extends BaseService implements ICookieService {
  /** données des cookies. */
  public readonly cookies: Cookie;
  /** Clé "cookies" pour le stockage dans le local storage. */
  private readonly cookiesKey: string = 'cookies';
  /** script GA chargé. */
  private scriptGaCharge = false;

  constructor(private readonly homeService: HomeService, private router: Router, protected logger: NGXLogger, readonly erreursService: ErreursService
    , readonly userService: UserService) {
    super(erreursService, logger);
    try {
      const store = localStorage.getItem(this.cookiesKey);
      if (store) {
        this.logger.debug('Données récupérée des cookies', store);
        this.cookies = Object.assign(new Cookie(), JSON.parse(store));
        this.cookies.checkPerime();
        this.loadGa();
        this.logger.debug('Cookies récupérés dans le local storage', this.cookies);
      } else {
        this.logger.debug('Pas de cookies stockés');
        this.cookies = new Cookie();
      }
    } catch (e) {
      this.logger.warn('Localstorage indisponible', e);
    }
  }

  /** Stocke les cookies dans le local storage. */
  stockeCookie(): void {
    try {
      localStorage.setItem(this.cookiesKey, JSON.stringify(this.cookies));
    } catch (e) {
      this.logger.warn('Localstorage indisponible', e);
    }
  }

  accepteAllCookies(): void {
    this.cookies?.accepteAllCookies();
    this.stockeCookie();
    this.loadGa();
  }

  accepteCookies(): void {
    this.cookies?.accepteRequiredCookies();
    this.stockeCookie();
    this.loadGa();
    this.sendCookie();
  }

  refuseCookies(): void {
    this.cookies?.refuseAllCookies();
    this.stockeCookie();
    this.loadGa();
  }

  /**
   * Charge le script Google analytics si pas chargé
   */
  private loadGa() {
    if (this.cookies?.isGoogleAnalyticsAccepted()) {

      window['ga-disable-UA-' + environment.googleAnalytics] = true;

      // on active le script s'il n'est pas déjà là!
      if (!this.scriptGaCharge) {
        // d'abord le JS...
        const gaScript = document.createElement('script');
        gaScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.googleAnalytics;
        gaScript.async = true;
        document.head.appendChild(gaScript);

        // ...puis la 2ème partie
        const script2 = document.createElement('script');
        script2.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        `;
        document.head.appendChild(script2);

        // maintenant on écoute le routage des pages
        this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd && this.isGoogleAnalyticsCharge()) {
            gtag('config', environment.googleAnalytics, {
              page_path: event.urlAfterRedirects,
            });
          }
        });

        this.scriptGaCharge = true;
      }
    } else {
      window['ga-disable-UA-' + environment.googleAnalytics] = false;
    }
  }


  /**
   * @return true si GA est chargé et accepté
   */
  private isGoogleAnalyticsCharge(): boolean {
    return this.scriptGaCharge && this.cookies?.isGoogleAnalyticsAccepted();
  }

  /**
   * Envoi l'acceptation des cookies dans Hoba
   *
   * @returns les annonces de la homepage.
   */
  private sendCookie(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.homeService.cookies(
        {
          cookieAccepte: this.cookies.cookieAccepte,
          analyticsAccepte: this.cookies.analyticsAccepte,
          numeroAbonne: this.userService.user == null ? null : this.userService.user.numeroAbonne
        }).subscribe((value) => {
        this.logger.debug('Cookies', value);
        resolve(value);
      }, (error1) => {
        this.logger.error('Impossible d\'envoyer l\'acceptation des cookies');
        reject(this.errorBuilder(error1, 'Impossible d\'envoyer l\'acceptation des cookies'));
      });
    });
  }

}
