/**
 * Hoba/wesoc API 3.0
 * API pour l'application bancaire SOCREDO.
 *
 * OpenAPI spec version: 3.00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpResponse} from '@angular/common/http';

import {Observable} from 'rxjs';

import {Configuration} from '../conf/configuration';
import {BASE_PATH} from '../conf/variables';
import {Carte} from '../model/carte';
import {CarteListe} from '../model/carteListe';
import {DemandeAnr} from '../model/demandeAnr';
import {EncoursCarte} from '../model/encoursCarte';
import {EncoursCarteCompte} from '../model/encoursCarteCompte';
import {NewPlafondsCarte} from '../model/newPlafondsCarte';
import {OperationCarte} from '../model/operationCarte';

@Injectable()
export class CarteService {

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  protected basePath = 'https://www.socms.pf/hoba/api';

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   *
   * Deplafonnement d&#x27;une carte
   * @param body les nouveaux plafonds
   * @param numeroClient numéro du client
   * @param idCarte id de la carte
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deplafonnementCarte(body: NewPlafondsCarte, numeroClient: string, idCarte: string, observe?: 'body', reportProgress?: boolean): Observable<DemandeAnr>;

  public deplafonnementCarte(body: NewPlafondsCarte, numeroClient: string, idCarte: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DemandeAnr>>;

  public deplafonnementCarte(body: NewPlafondsCarte, numeroClient: string, idCarte: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DemandeAnr>>;

  public deplafonnementCarte(body: NewPlafondsCarte, numeroClient: string, idCarte: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling deplafonnementCarte.');
    }

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling deplafonnementCarte.');
    }

    if (idCarte === null || idCarte === undefined) {
      throw new Error('Required parameter idCarte was null or undefined when calling deplafonnementCarte.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<DemandeAnr>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/cartes/${encodeURIComponent(String(idCarte))}/deplafonnement`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Détail d&#x27;une carte&#x27;
   * @param numeroClient numéro du client
   * @param idCarte id de la carte
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public detailCarte(numeroClient: string, idCarte: string, observe?: 'body', reportProgress?: boolean): Observable<Carte>;

  public detailCarte(numeroClient: string, idCarte: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Carte>>;

  public detailCarte(numeroClient: string, idCarte: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Carte>>;

  public detailCarte(numeroClient: string, idCarte: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling detailCarte.');
    }

    if (idCarte === null || idCarte === undefined) {
      throw new Error('Required parameter idCarte was null or undefined when calling detailCarte.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Carte>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/cartes/${encodeURIComponent(String(idCarte))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Détail de l&#x27;encours carte et des plafonds
   * @param numeroClient numéro du client
   * @param idCarte id de la carte
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public encoursCarte(numeroClient: string, idCarte: string, observe?: 'body', reportProgress?: boolean): Observable<EncoursCarte>;

  public encoursCarte(numeroClient: string, idCarte: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EncoursCarte>>;

  public encoursCarte(numeroClient: string, idCarte: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EncoursCarte>>;

  public encoursCarte(numeroClient: string, idCarte: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling encoursCarte.');
    }

    if (idCarte === null || idCarte === undefined) {
      throw new Error('Required parameter idCarte was null or undefined when calling encoursCarte.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<EncoursCarte>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/cartes/${encodeURIComponent(String(idCarte))}/encours`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * liste les cartes du client
   * @param numeroClient numéro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listeCartes(numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<CarteListe>;

  public listeCartes(numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CarteListe>>;

  public listeCartes(numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CarteListe>>;

  public listeCartes(numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling listeCartes.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<CarteListe>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/cartes`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Récupère l&#x27;encours carte des X derniers mois d&#x27;un compte
   * @param numeroClient numéro du client
   * @param idCarte id de la carte
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public mensualitesCarte(numeroClient: string, idCarte: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EncoursCarteCompte>>;

  public mensualitesCarte(numeroClient: string, idCarte: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EncoursCarteCompte>>>;

  public mensualitesCarte(numeroClient: string, idCarte: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EncoursCarteCompte>>>;

  public mensualitesCarte(numeroClient: string, idCarte: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling mensualitesCarte.');
    }

    if (idCarte === null || idCarte === undefined) {
      throw new Error('Required parameter idCarte was null or undefined when calling mensualitesCarte.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<EncoursCarteCompte>>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/cartes/${encodeURIComponent(String(idCarte))}/mensualites`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Récupère les opérations d&#x27;une mensualité d&#x27;une carte
   * @param numeroClient numéro du client
   * @param idCarte id de la carte
   * @param date date en yyyy-mm-dd
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public operationsCarte(numeroClient: string, idCarte: string, date: string, observe?: 'body', reportProgress?: boolean): Observable<Array<OperationCarte>>;

  public operationsCarte(numeroClient: string, idCarte: string, date: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<OperationCarte>>>;

  public operationsCarte(numeroClient: string, idCarte: string, date: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<OperationCarte>>>;

  public operationsCarte(numeroClient: string, idCarte: string, date: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling operationsCarte.');
    }

    if (idCarte === null || idCarte === undefined) {
      throw new Error('Required parameter idCarte was null or undefined when calling operationsCarte.');
    }

    if (date === null || date === undefined) {
      throw new Error('Required parameter date was null or undefined when calling operationsCarte.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<OperationCarte>>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/cartes/${encodeURIComponent(String(idCarte))}/operations/${encodeURIComponent(String(date))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Opposition d&#x27;une carte
   * @param body le motif de l&#x27;opposition
   * @param numeroClient numéro du client
   * @param idCarte id de la carte
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public oppositionCarte(body: string, numeroClient: string, idCarte: string, observe?: 'body', reportProgress?: boolean): Observable<DemandeAnr>;

  public oppositionCarte(body: string, numeroClient: string, idCarte: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DemandeAnr>>;

  public oppositionCarte(body: string, numeroClient: string, idCarte: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DemandeAnr>>;

  public oppositionCarte(body: string, numeroClient: string, idCarte: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling oppositionCarte.');
    }

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling oppositionCarte.');
    }

    if (idCarte === null || idCarte === undefined) {
      throw new Error('Required parameter idCarte was null or undefined when calling oppositionCarte.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'text/plain'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<DemandeAnr>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/cartes/${encodeURIComponent(String(idCarte))}/opposition`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

}
