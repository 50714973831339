import {Component, OnInit} from '@angular/core';
import * as $ from 'jquery';
import {MenuService} from '../../../services/menu.service';
import {UserService} from '../../../services/user.service';
import {MenuItem} from '../../../classes/menu/menu-item';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css'],
})
export class NavigationComponent implements OnInit {
  sousMenusFiltres: MenuItem[][];

  constructor(public menuService: MenuService, private userService: UserService) {
  }

  ngOnInit() {
    this.jsMenu();
  }

  /**
   * La date de dernière connexion
   */
  derniereConnexion(): Date {
    if (this.userService.user) {
      return this.userService.user.derniereConnexion();
    }
    return null;
  }

  /**
   * Met en place le JS sur le menu.
   */
  private jsMenu() {
    $(() => {
      const activeClass = 'js-active';
      const $body = $('body');
      const $topNav = $('.top-nav');
      const $topNavBtn = $('.top-nav-btn');
      const $mainNav = $('.main-nav');
      const $mainNavBtn = $('.main-nav-btn');
      const $subNavs = $('.subnav');
      const $contactWidget = $('.contact-widget');
      const $searchBlock = $('.search-block');
      const $searchHolderBtn = $('.search-holder-btn');
      const $closeMainNav = $('.close-navbar');

      // TOP NAVBAR HANDLE
      $topNavBtn.on('click', (e) => {
        e.preventDefault();

        if (!$topNav.length) {
          return;
        }

        $topNav.slideToggle(300);
      });

      // MOBILE SEARCH HANDLE
      $searchHolderBtn.on('click', (e) => {
        e.preventDefault();

        if (!$searchBlock.length) {
          return;
        }

        $searchBlock.slideToggle(300);
      });

      // MAIN NAV-BAR HANDLE
      $mainNavBtn.on('click', (e) => {
        e.preventDefault();

        if (!$mainNav.length) {
          return;
        }

        $mainNav.addClass(activeClass);

        if ($contactWidget.length) {
          $contactWidget.addClass(activeClass);
        }

        $body.addClass('no-scroll fixed');
      });

      $closeMainNav.on('click', (e) => {
        e.preventDefault();

        $mainNav.removeClass(activeClass);

        if ($contactWidget.length) {
          $contactWidget.removeClass(activeClass);
        }

        if ($subNavs.length) {
          $subNavs.removeClass(activeClass);
        }

        if (!$(`.${activeClass}`).length) {
          $body.removeClass('no-scroll fixed');
        }
      });


      $mainNav.on('click', '.nav-link', (e) => {
        const $item = $(e.currentTarget).parent();
        const $subNav = $item.find('.subnav');

        if (!$subNav.length) {
          return;
        }
        e.preventDefault();
        e.stopPropagation();
        const $others = $item.siblings().find('.subnav.' + activeClass);

        $item.siblings().removeClass(activeClass);
        $others.removeClass(activeClass);
        $subNav.toggleClass(activeClass);
        $item.toggleClass(activeClass);
      });

      $mainNav.on('click', '.subnav-back', (e) => {
        e.preventDefault();
        e.stopPropagation();
        const $target = $(e.currentTarget);
        const $subNav = $target.parent();
        const $subNav2 = $target.parent().parent();

        $subNav.removeClass(activeClass);
        $subNav2.removeClass(activeClass);
      });

      // CLICK OUTSIDE NAVS
      $body.on('mouseup', (e) => {
        const $items = $(`.subnav.${activeClass}`);
        const $subnav_link = $('.subnav__list__item__link');
        const $navLink = $('.nav-link');

        if ($navLink.is(e.target) && $(e.target).closest(`.nav-item.${activeClass}`).length) {
          return;
        }

        if ($(e.target).hasClass('submenu_none')) {
          $mainNav.removeClass(activeClass);

          if ($contactWidget.length) {
            $contactWidget.removeClass(activeClass);
          }
          if (!$('.' + activeClass).length) {
            $body.removeClass('no-scroll fixed');
          }
        }
        if (!$items.is(e.target) && $items.has(e.target).length === 0 || $subnav_link.is(e.target)) {
          $items.parent().removeClass(activeClass);
          $items.removeClass(activeClass);

          if ($subnav_link.is(e.target)) {
            $mainNav.removeClass(activeClass);

            if ($contactWidget.length) {
              $contactWidget.removeClass(activeClass);
            }

            if ($subNavs.length) {
              $subNavs.removeClass(activeClass);
            }

            if (!$('.' + activeClass).length) {
              $body.removeClass('no-scroll fixed');
            }
          }

        }
      });
    });
  }
}
