import {Injectable} from '@angular/core';
import {ScriptLoaderService} from 'angular-google-charts';
import {NGXLogger} from 'ngx-logger';
import {fromEvent, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {CoreServiceModule} from '../../core-service.module';

class ChartStore {
  /** la souscription sur l'événement "resize */
  subscription: Subscription;

  constructor(public chart: any) {
  }
}

@Injectable({
  providedIn: CoreServiceModule,
})
export class GoogleChartsService {
  /** les graphes stockés. */
  charts = new Map<string, ChartStore>();

  constructor(protected loaderService: ScriptLoaderService, protected logger: NGXLogger) {
    // google.charts.load('current', {packages: ['corechart']});
  }

  /**
   * Détruit un graphe
   * @param elementId id du graphe
   */
  public destroyChart(elementId: string) {
    if (this.charts.has(elementId)) {
      const chartStore = this.charts.get(elementId);
      chartStore.chart.clearChart();
      chartStore.subscription.unsubscribe();
      this.charts.delete(elementId);
    }
  }

  protected buildChartDataTable(elementId: string, dataTable: () => google.visualization.DataTable, chartFunc: any, options: any): void {

    this.loaderService.loadChartPackages('corechart').subscribe(() => {
      const chart = chartFunc();
      if (chart !== null) {
        this.charts.set(elementId, new ChartStore(chart));
        chart.draw(dataTable(), options);

        this.charts.get(elementId).subscription = fromEvent(window, 'resize')
          .pipe(debounceTime(100))
          .subscribe(() => {
            chart.draw(dataTable(), options);
          });
      }
    });
  }

  protected buildChartDataView(elementId: string, dataviewFunc: () => google.visualization.DataView, chartFunc: any, options: any): void {
    this.loaderService.loadChartPackages('corechart').subscribe(() => {
      const chart = chartFunc();
      if (chart !== null) {
        this.charts.set(elementId, new ChartStore(chart));
        chart.draw(dataviewFunc(), options);

        this.charts.get(elementId).subscription = fromEvent(window, 'resize')
          .pipe(debounceTime(100))
          .subscribe(() => {
            chart.draw(dataviewFunc(), options);
          });
      }
    });
  }
}
