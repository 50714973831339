import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {StatutUtilisateurEnum} from '../../api/model/statutUtilisateurEnum';
import {CoreModule} from '../core.module';
import {UserService} from '../services/user.service';
import {jwtDecode} from 'jwt-decode';
import {NGXLogger} from 'ngx-logger';

/**
 * Guard pour l'authentification des utilisateurs.
 */
@Injectable({
  providedIn: CoreModule,
})
export class AuthGuard {

  constructor(private readonly userService: UserService, private readonly router: Router, private readonly logger: NGXLogger) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    // l'utilisateur est connecté ?
    if (this.userService.getToken() == null) {
      this.router.navigate(['/auth/login']);
      return false;
    }

    //token: on vérifie le scope
    const token = this.userService.getToken();
    if (token) {
      const decoded = jwtDecode<{
        scope: string
      }>(token);
      this.logger.trace('Token', decoded);
      //on vérifie que l'utilisateur a le scope qui va bien
      if (decoded.scope !== 'client') {
        this.logger.warn('L\'utilisateur n\'a pas le scope \'client\'');
        this.router.navigate(['/error'], {state: {erreur: 'Vous n\'avez les droits pour accéder à la page demandée'}});
        return false;
      }
      //on continue
    } else {
      return false;
    }

    // l'utilisateur est en migration ?
    if (!this.userService.user || !this.userService.user.profil) {
      this.router.navigate(['/error'], {state: {erreur: 'Impossible de charger votre profil. Veuillez réessayer ultérieurement.'}});
      return false;
    } else if (this.userService.user.profil.statut === StatutUtilisateurEnum.MIGRATIONUTILISATEURETAPE1) {
      this.router.navigate(['/auth/etape1']);
      return false;
    } else if (this.userService.user.profil.statut === StatutUtilisateurEnum.MIGRATIONUTILISATEURETAPE2) {
      this.router.navigate(['/auth/etape2']);
      return false;
    } else if (this.userService.user.profil.statut === StatutUtilisateurEnum.REINITIALISATIONMDP) {
      // ce contrôle est fait dans ModificationMdpGuard car les pages sont privées
    } else if (!this.userService.user.profil.cguAccepte) { // l'utilisateur doit accepter des cgu ?
      this.router.navigate(['/auth/cgu']);
      return false;
    }

    return true;
  }

}
