<a (click)="loadAlertes()" class="user-item__icon d-flex align-items-center position-relative cursor-pointer"><i
  class="icon-bell user-item__icon__image"></i>
  <div *ngIf="nbMessageNonLu && nbMessageNonLu != '0'" class="user-item__icon__number">{{nbMessageNonLu}}</div>
</a>
<div class="user-menu user-menu--noti">
  <ng-container *ngIf="!erreur else Erreur">
    <div *ngIf="!isLoading else Loader " class="user-menu-wrapper">
      <div *ngIf="listeAlertes &&  listeAlertes.length else AucuneAlerte ">
        <a *ngFor="let alerte of listeAlertes" class="user-menu__item" [routerLink]=""><span
          class="user-menu__item__date">{{alerte.date | date: 'shortDate'}}</span><span
          class="user-menu__item__text">{{alerte.libelle}}</span></a>
      </div>
    </div>
  </ng-container>
  <a class="user-menu-button" [routerLink]="" (click)="toutesAlertes()">Voir toutes les alertes</a>
</div>

<ng-template #Loader>
  <app-ajax-loader></app-ajax-loader>
</ng-template>

<ng-template #Erreur>
  <app-message-erreur class="text--12 user-menu-wrapper" [erreur]="erreur"></app-message-erreur>
</ng-template>
<ng-template #AucuneAlerte>
  <div class="text--12 user-menu__item ">Aucune alerte</div>
</ng-template>

