import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AnrsService} from '../../services/anrs.service';
import {BaseComponent} from '../../classes/base-component';
import {HttpResponse} from '@angular/common/http';
import {DemandeAnr} from '../../../api/model/demandeAnr';
import {interval, Subscription} from 'rxjs';

@Component({
  selector: 'app-anr-socsecure[titre]',
  templateUrl: './anr-socsecure.component.html',
  styleUrls: ['./anr-socsecure.component.css'],

})
export class AnrSocsecureComponent extends BaseComponent implements OnInit {

  /** le titre de la page d'anr */
  @Input() titre: string;
  /** le lien vers la page de confirmation */
  @Output() confirmationEvent: EventEmitter<HttpResponse<DemandeAnr>> = new EventEmitter<HttpResponse<DemandeAnr>>();

  /** subscription */
  subCheckValidationAnr: Subscription;
  /** l'anr à envoyer */
  @Input() demandeAnr: DemandeAnr;

  constructor(private readonly anrsService: AnrsService) {
    super();
  }

  ngOnInit() {
    this.subCheckValidationAnr = interval(2000)
      .subscribe((val) => {
        this.anrsService.checkValidationAnr(this.demandeAnr.cleAnr).then((value) => {
          if (value.body.valider != null) {
            if (value.body.valider) {
              // on simule une réponse demande anr à 201
              this.confirmationEvent.emit(new HttpResponse<any>({status: 201, body:value.body.bilanExecution}));
            } else {
              this.erreur = 'Opération refusée ou périmée';
              this.subCheckValidationAnr.unsubscribe();
            }
          }

        }).catch((reason) => {
          //Si le message est vide, c'est que c'est une 422
          this.erreur = reason.message ?? 'Opération refusée';
          this.subCheckValidationAnr.unsubscribe();
        });
      });
  }

  ngOnDestroy(): void {
    this.subCheckValidationAnr.unsubscribe();
  }

}
