/**
 * Hoba/wesoc API 2.0
 * API pour l'application bancaire SOCREDO.
 *
 * OpenAPI spec version: 3.00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import {ValorisationAssurance} from './valorisationAssurance';

/**
 * assurance d'un client
 */
export interface Assurance {
  /**
   * ID de l'assurance
   */
  id?: string;
  /**
   * type d'assurance
   */
  type?: Assurance.TypeEnum;
  /**
   * libellé de l'assurance
   */
  libelle?: string;
  /**
   * numéro du contrat
   */
  numeroContrat?: string;
  /**
   * nom du titulaire
   */
  nomTitulaire?: string;
  /**
   * prenom du titulaire
   */
  prenomTitulaire?: string;
  /**
   * date d'effet
   */
  dateEffet?: string;
  valorisation?: ValorisationAssurance[];
}

export namespace Assurance {
  export type TypeEnum = 'PREPAR VIE' | 'CARDIF';
  export const TypeEnum = {
    PREPARVIE: 'PREPAR VIE' as TypeEnum,
    CARDIF: 'CARDIF' as TypeEnum,
  };
}
