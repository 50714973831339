import {Component, OnInit} from '@angular/core';
import {Cookie} from '../../models/cookie';
import {CookiesService} from '../../services/cookies.service';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.css'],
})
export class CookiesComponent implements OnInit {
  /** Les préférences des cookies. */
  public cookies: Cookie;

  constructor(private cookiesService: CookiesService) {
  }

  ngOnInit(): void {
    this.cookies = this.cookiesService.cookies;
  }
}
