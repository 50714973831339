<ng-container *ngIf="!cookies?.isAccepted()">
  <div class="container fixed-bottom">
    <div class="alert alert-warning mb-0" role="alert">
      <button aria-label="Close" class="btn-close" data-bs-dismiss="alert" type="button"></button>
      <div class="row">
        <div class="col-md-7">
          <p>En poursuivant votre navigation sur ce site, vous acceptez que la Banque SOCREDO utilise des cookies pour
            analyser le fonctionnement et l'efficacité du site.</p>
        </div>
        <div class="col-md-5 text-end">
          <button class="btn btn-outline-primary m-1" data-bs-target="#cookieModal" data-bs-toggle="modal" type="button">
            Personnaliser mes choix
          </button>
        </div>
      </div>
    </div>
  </div>

  <app-cookies-modal idModal="cookieModal"></app-cookies-modal>

</ng-container>
