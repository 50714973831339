import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {SimplePopupComponent} from '../../components/simple-popup/simple-popup.component';
import {ErreursService} from '../../services/erreurs.service';
import {TutorielService} from '../../services/tutoriel.service';

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.css'],
})
export class ContentLayoutComponent implements OnInit, OnDestroy, AfterViewInit {
  /** l'erreur à afficher */
  erreur: string;
  /** souscription. */
  subscription = new Subscription();

  constructor(private erreursService: ErreursService, private tutorielService: TutorielService) {
  }

  ngOnInit() {
    this.subscription.add(this.erreursService.message$.subscribe((value) => {
      this.erreur = value;
      SimplePopupComponent.show();
    }));
  }

  ngAfterViewInit(): void {
    this.tutorielService.iniTutoriel();
  }

  /** ferme la popup d'erreur */
  closeError() {
    SimplePopupComponent.hide();
    this.erreur = undefined;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
