/**
 * Hoba/wesoc API 2.0
 * API pour l'application bancaire SOCREDO.
 *
 * OpenAPI spec version: 3.00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import {HttpClient, HttpEvent, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';

import {Observable} from 'rxjs';
import {Configuration} from '../conf/configuration';
import {BASE_PATH} from '../conf/variables';
import {ListeMessage} from '../model/listeMessage';
import {Message} from '../model/message';
import {InfosMessageAlerte} from '../model/infosMessageAlerte';

@Injectable()
export class MessageService {

  protected basePath = 'https://www.socms.pf/hoba/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Envoi d&#x27;un message
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public envoiMessage(body?: FormData, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public envoiMessage(body?: FormData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public envoiMessage(body?: FormData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public envoiMessage(body?: FormData, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header

    const consumes: string[] = [
      'multipart/form-data',
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      //headers = headers.set('Content-Type', httpContentTypeSelected);
      //headers = headers.set('Content-Type', undefined);
    }

    return this.httpClient.post<any>(`${this.basePath}/messages`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }


  /**
   * Nombre de messages non lus de la messagerie
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public derniersMessages(observe?: 'body', reportProgress?: boolean): Observable<InfosMessageAlerte>;
  public derniersMessages(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InfosMessageAlerte>>;
  public derniersMessages(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InfosMessageAlerte>>;
  public derniersMessages(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<InfosMessageAlerte>('get', `${this.basePath}/messages/derniersMessages`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }


  /**
   * suppression d&#x27;un message
   *
   * @param idMessage id du message
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteMessage(idMessage: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public deleteMessage(idMessage: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public deleteMessage(idMessage: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public deleteMessage(idMessage: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (idMessage === null || idMessage === undefined) {
      throw new Error('Required parameter idMessage was null or undefined when calling deleteMessage.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<any>(`${this.basePath}/messages/${encodeURIComponent(String(idMessage))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Lecture d&#x27;un message
   *
   * @param idMessage id du message
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public message(idMessage: number, observe?: 'body', reportProgress?: boolean): Observable<Message>;
  public message(idMessage: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Message>>;
  public message(idMessage: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Message>>;
  public message(idMessage: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (idMessage === null || idMessage === undefined) {
      throw new Error('Required parameter idMessage was null or undefined when calling message.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Message>(`${this.basePath}/messages/${encodeURIComponent(String(idMessage))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }

  /**
   * Liste des messages
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public messages(observe?: 'body', reportProgress?: boolean): Observable<ListeMessage>;
  public messages(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListeMessage>>;
  public messages(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListeMessage>>;
  public messages(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<ListeMessage>(`${this.basePath}/messages`,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }

  /**
   * Récupère la pièce jointe d&#x27;un message
   *
   * @param idMessage id du message
   * @param idPieceJointe id de la pièce jointe
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public pieceJointeMessage(idMessage: number, idPieceJointe: number, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
  public pieceJointeMessage(idMessage: number, idPieceJointe: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
  public pieceJointeMessage(idMessage: number, idPieceJointe: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
  public pieceJointeMessage(idMessage: number, idPieceJointe: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (idMessage === null || idMessage === undefined) {
      throw new Error('Required parameter idMessage was null or undefined when calling pieceJointeMessage.');
    }

    if (idPieceJointe === null || idPieceJointe === undefined) {
      throw new Error('Required parameter idPieceJointe was null or undefined when calling pieceJointeMessage.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/octet-stream',
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', 'application/octet-stream, application/json; q=0.9');
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get(`${this.basePath}/messages/${encodeURIComponent(String(idMessage))}/pieceJointe/${encodeURIComponent(String(idPieceJointe))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        responseType: 'blob',
        reportProgress,
      },
    );
  }

}
