import {Component, Input} from '@angular/core';

import {ListePagineeGeneriqueComponent} from '../liste-paginee-generique/liste-paginee-generique.component';
import {IPaged} from '../../../api/model/credit/iPaged';


@Component({
  selector: 'app-sortable-header [dataTable] [columnName]',
  templateUrl: './sortable-header.component.html',
  styleUrls: ['./sortable-header.component.scss'],
})
export class SortableHeaderComponent<T, S, P extends IPaged> {
  @Input()
  columnName: string;
  @Input()
  dataTable:
    | ListePagineeGeneriqueComponent<T, S, P>;
}
