<footer class="main-footer">
  <div class="copyright position-relative">
    <span class="position-absolute" style="bottom: 5px; right: 5px; font-size: 8px">{{version}}</span>
    <div class="container">
      <div class="row py-3">
        <div
          class="col-12 d-flex align-items-end justify-content-center justify-content-md-between flex-md-row flex-wrap">
          <div class="copyright-logo py-2 py-md-0"><img src="assets/images/logo-footer.png" alt="#">
          </div>
          <div class="footer-menu justify-content-center justify-content-md-end flex-wrap py-2 py-md-0">
            <a [href]="urls.mentionsLegales" class="footer-menu__item" target="_blank">Mentions légales</a>
            <a [href]="urls.donneesPersonnelles" class="footer-menu__item" target="_blank">Données personnelles</a>
            <a class="footer-menu__item" [routerLink]="['/cgu']">Condition d'utilisation</a>
            <a (click)="securite()" class="footer-menu__item cursor-pointer">Sécurité</a>
            <a [routerLink]="['/faq']" class="footer-menu__item">Aide</a>
            <a class="footer-menu__item" [routerLink]="['/messagerie']">Nous contacter</a>
          </div>
          <div class="socials py-2 py-md-0">
            <a [href]="urls.facebook" class="social" target="_blank"><i class="fab fa-facebook-f"></i></a>
            <a [href]="urls.instagram" class="social" target="_blank"><i class="fab fa-instagram"></i></a>
            <a [href]="urls.youtube" class="social" target="_blank"><i class="fab fa-youtube"></i></a>
            <a [href]="urls.linkedin" class="social" target="_blank"><i class="fab fa-linkedin-in"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

