/**
 * Hoba/wesoc API 3.0
 * API pour l'application bancaire SOCREDO.
 *
 * OpenAPI spec version: 3.01
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpResponse} from '@angular/common/http';

import {Observable} from 'rxjs';

import {Anr} from '../model/anr';
import {Etape1MdpPerduResponse} from '../model/etape1MdpPerduResponse';
import {FinMdpPerdu} from '../model/finMdpPerdu';
import {InitMdpPerdu} from '../model/initMdpPerdu';
import {TokenMdpPerdu} from '../model/tokenMdpPerdu';
import {BASE_PATH} from '../conf/variables';
import {Configuration} from '../conf/configuration';


@Injectable()
export class MotDePasseService {

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  protected basePath = 'https://hoba-tests.socredo.pf/hoba3/api';

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   *
   * valide le code mail et passe à l&#x27;étape 2
   * @param body Le code mail et le token
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public etap1MdpPerdu(body: Anr, observe?: 'body', reportProgress?: boolean): Observable<Etape1MdpPerduResponse>;

  public etap1MdpPerdu(body: Anr, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Etape1MdpPerduResponse>>;

  public etap1MdpPerdu(body: Anr, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Etape1MdpPerduResponse>>;

  public etap1MdpPerdu(body: Anr, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling etap1MdpPerdu.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Etape1MdpPerduResponse>('put', `${this.basePath}/motDePassePerdu/etape1`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * valide le code SMS et passe à l&#x27;étape 3
   * @param body Le code SMS et le token
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public etap2MdpPerdu(body: Anr, observe?: 'body', reportProgress?: boolean): Observable<TokenMdpPerdu>;

  public etap2MdpPerdu(body: Anr, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TokenMdpPerdu>>;

  public etap2MdpPerdu(body: Anr, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TokenMdpPerdu>>;

  public etap2MdpPerdu(body: Anr, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling etap2MdpPerdu.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<TokenMdpPerdu>('put', `${this.basePath}/motDePassePerdu/etape2`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Effectue le changement de mot de pas se.
   * @param body le token et le nouveau mot de passe
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public finMdpPerdu(body: FinMdpPerdu, observe?: 'body', reportProgress?: boolean): Observable<any>;

  public finMdpPerdu(body: FinMdpPerdu, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;

  public finMdpPerdu(body: FinMdpPerdu, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;

  public finMdpPerdu(body: FinMdpPerdu, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling finMdpPerdu.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>('put', `${this.basePath}/motDePassePerdu/fin`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Initialise la procédure de changement de mot de passe
   * @param body Le login concerné et le recaptcha
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public initMdpPerdu(body: InitMdpPerdu, observe?: 'body', reportProgress?: boolean): Observable<any>;

  public initMdpPerdu(body: InitMdpPerdu, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;

  public initMdpPerdu(body: InitMdpPerdu, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;

  public initMdpPerdu(body: InitMdpPerdu, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling initMdpPerdu.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/motDePassePerdu/init`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

}
