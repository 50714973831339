import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {CoreServiceModule} from '../core-service.module';

@Injectable({
  providedIn: CoreServiceModule,
})
export class ErreursService {

  /** observable sur les erreurs à afficher */
  message$: Subject<string> = new Subject<string>();

  constructor() {
  }

  traiteErreur(message: string) {
    this.message$.next(message);
  }

}
