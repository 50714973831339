import {InjectionToken} from '@angular/core';
import {environment} from '../../../environments/environment';

// Pour définir les URLs de l'API
export const BASE_PATH = new InjectionToken<string>('basePath', {
  providedIn: 'root',
  factory: () => environment.urlApi,
});

export const COLLECTION_FORMATS = {
  'csv': ',',
  'tsv': '   ',
  'ssv': ' ',
  'pipes': '|'
};

