import {AfterViewInit, Component, Input, OnDestroy} from '@angular/core';
import {parse} from 'date-fns';
import {NGXLogger} from 'ngx-logger';
import {Compte} from '../../../api/model/compte';
import {EvolutionSolde} from '../../../api/model/evolutionSolde';
import {ComptesService} from '../../services/comptes.service';
import {GoogleSoldeChartsService} from '../../services/google-chart/google-solde-charts.service';

@Component({
  selector: 'app-graphe-solde-compte',
  templateUrl: './graphe-solde-compte.component.html',
  styleUrls: ['./graphe-solde-compte.component.css'],
})
export class GrapheSoldeCompteComponent implements AfterViewInit, OnDestroy {
  @Input() grapheId: string;
  @Input() compte: Compte;
  /** Erreur*/
  erreur: string;

  constructor(private readonly compteService: ComptesService, private readonly logger: NGXLogger, private readonly googleSoldeService: GoogleSoldeChartsService) {
  }

  ngOnDestroy() {
    this.googleSoldeService.destroyChart(this.grapheId);
  }

  ngAfterViewInit(): void {
    // on appelle le WS
    this.compteService.soldeQuotidien(this.compte).then((evolution: EvolutionSolde) => {
      const data = [];
      for (const solde of evolution.soldes) {
        data.push([parse(solde.date), evolution.montantADD ? -evolution.montantADD : 0, solde.solde]);
      }
      this.googleSoldeService.buildSoldeChart(this.grapheId, data, evolution.devise, (evolution.montantADD && evolution.montantADD > 0));
    }).catch((reason) => {
      this.erreur = reason.message;
    });
  }
}
