/**
 * Hoba/wesoc API 3.0
 * API pour l'application bancaire SOCREDO.
 *
 * OpenAPI spec version: 3.00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';

import {Observable} from 'rxjs';
import {Configuration} from '../conf/configuration';
import {BASE_PATH} from '../conf/variables';
import {DeviceActivation} from '../model/deviceActivation';
import {MessageNotificationAlerte} from '../model/messageNotificationAlerte';
import {ProfilAlerteClient} from '../model/profilAlerteClient';
import {ProfilAlerteCompte} from '../model/profilAlerteCompte';
import {CustomHttpUrlEncodingCodec} from '../conf/encoder';
import {InfosMessageNotificationAlerte} from '../model/infosMessageNotificationAlerte';

@Injectable()
export class AlerteService {

  protected basePath = 'https://www.socms.pf/hoba/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   *
   * Envoi des paramètres d&#x27;alertes sur un nouveau compte choisis par l&#x27;utilisateur
   * @param body le profil à créer
   * @param numeroClient numéro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createProfilAlerteCompte(body: ProfilAlerteCompte, numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public createProfilAlerteCompte(body: ProfilAlerteCompte, numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public createProfilAlerteCompte(body: ProfilAlerteCompte, numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public createProfilAlerteCompte(body: ProfilAlerteCompte, numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling createProfilAlerteCompte.');
    }

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling createProfilAlerteCompte.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/profilsAlerteCompte`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Supprimer tous les messages de Notification/Alerte de la convention
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteAllMessage(observe?: 'body', reportProgress?: boolean): Observable<any>;
  public deleteAllMessage(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public deleteAllMessage(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public deleteAllMessage(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<any>(`${this.basePath}/messagesNotificationAlerte`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Supprimer un message de Notification/Alerte de la liste active
   * @param idMessageNotificationAlerte id du message de notification alerte
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteMessageAlerte(idMessageNotificationAlerte: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public deleteMessageAlerte(idMessageNotificationAlerte: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public deleteMessageAlerte(idMessageNotificationAlerte: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public deleteMessageAlerte(idMessageNotificationAlerte: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (idMessageNotificationAlerte === null || idMessageNotificationAlerte === undefined) {
      throw new Error('Required parameter idMessageNotificationAlerte was null or undefined when calling deleteMessageAlerte.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<any>(`${this.basePath}/messagesNotificationAlerte/${encodeURIComponent(String(idMessageNotificationAlerte))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Suppression des alertes sur un compte choisie par l&#x27;utilisateur
   * @param numeroClient numéro du client
   * @param idProfilAlerteCompte id du profil d&#x27;alerte compte
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteProfilAlerteCompte(numeroClient: string, idProfilAlerteCompte: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public deleteProfilAlerteCompte(numeroClient: string, idProfilAlerteCompte: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public deleteProfilAlerteCompte(numeroClient: string, idProfilAlerteCompte: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public deleteProfilAlerteCompte(numeroClient: string, idProfilAlerteCompte: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling deleteProfilAlerteCompte.');
    }

    if (idProfilAlerteCompte === null || idProfilAlerteCompte === undefined) {
      throw new Error('Required parameter idProfilAlerteCompte was null or undefined when calling deleteProfilAlerteCompte.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<any>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/profilsAlerteCompte/${encodeURIComponent(String(idProfilAlerteCompte))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * retourne les 5 derniers messages
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public derniersMessagesAlerte(observe?: 'body', reportProgress?: boolean): Observable<Array<MessageNotificationAlerte>>;
  public derniersMessagesAlerte(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MessageNotificationAlerte>>>;
  public derniersMessagesAlerte(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MessageNotificationAlerte>>>;
  public derniersMessagesAlerte(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<MessageNotificationAlerte>>(`${this.basePath}/messagesNotificationAlerte/derniersMessages`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * retourne les infos concernant les messages de notification
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public infosMessagesAlerte(observe?: 'body', reportProgress?: boolean): Observable<InfosMessageNotificationAlerte>;
  public infosMessagesAlerte(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InfosMessageNotificationAlerte>>;
  public infosMessagesAlerte(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InfosMessageNotificationAlerte>>;
  public infosMessagesAlerte(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<InfosMessageNotificationAlerte>(`${this.basePath}/messagesNotificationAlerte/infosMessages`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * retourne la liste des messages de Notification/Alerte
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listeMessagesAlerte(observe?: 'body', reportProgress?: boolean): Observable<Array<MessageNotificationAlerte>>;
  public listeMessagesAlerte(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MessageNotificationAlerte>>>;
  public listeMessagesAlerte(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MessageNotificationAlerte>>>;
  public listeMessagesAlerte(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<MessageNotificationAlerte>>(`${this.basePath}/messagesNotificationAlerte`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Active/désactive la génération des notifications PUSH sur ce périphérique pour la convention courante
   * @param body Identifiant du périphérique avec son état de notification pour cette convention
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public notificationActivation(body: DeviceActivation, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public notificationActivation(body: DeviceActivation, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public notificationActivation(body: DeviceActivation, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public notificationActivation(body: DeviceActivation, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling notificationActivation.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<any>(`${this.basePath}/notificationActivation`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Retourne la liste des profils d&#x27;alertes sur la convention et les comptes du client, et la capacité du périphérique à recevoir les
   * notifications PUSH
   * @param numeroClient numéro du client
   * @param deviceId Device ID du périphérique exécutant l&#x27;application
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public profilAlerteClient(numeroClient: string, deviceId?: string, observe?: 'body', reportProgress?: boolean): Observable<ProfilAlerteClient>;
  public profilAlerteClient(numeroClient: string, deviceId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProfilAlerteClient>>;
  public profilAlerteClient(numeroClient: string, deviceId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProfilAlerteClient>>;
  public profilAlerteClient(numeroClient: string, deviceId?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling profilAlerteClient.');
    }


    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (deviceId !== undefined && deviceId !== null) {
      queryParameters = queryParameters.set('deviceId', <any>deviceId);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<ProfilAlerteClient>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/profilAlerteClient`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Envoi des nouveaux paramètres d&#x27;alertes sur un compte choisis par l&#x27;utilisateur
   * @param body le profil à mettre à jour
   * @param numeroClient numéro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateProfilAlerteCompte(body: ProfilAlerteCompte, numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public updateProfilAlerteCompte(body: ProfilAlerteCompte, numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public updateProfilAlerteCompte(body: ProfilAlerteCompte, numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public updateProfilAlerteCompte(body: ProfilAlerteCompte, numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling updateProfilAlerteCompte.');
    }

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling updateProfilAlerteCompte.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<any>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/profilsAlerteCompte`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
