import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {format, subDays} from 'date-fns';
import {NGXLogger} from 'ngx-logger';
import {AgenceService} from '../../api/api/agence.service';
import {CategorieService} from '../../api/api/categorie.service';
import {ChequierService} from '../../api/api/chequier.service';
import {ClientService} from '../../api/api/client.service';
import {CompteService} from '../../api/api/compte.service';
import {MouvementService} from '../../api/api/mouvement.service';
import {Agence} from '../../api/model/agence';
import {BorneMinMax} from '../../api/model/borneMinMax';
import {Categorie} from '../../api/model/categorie';
import {Client} from '../../api/model/client';
import {CommandeChequier} from '../../api/model/commandeChequier';
import {CommandeChequierListe} from '../../api/model/commandeChequierListe';
import {Compte} from '../../api/model/compte';
import {CompteListe} from '../../api/model/compteListe';
import {DelivranceChequier} from '../../api/model/delivranceChequier';
import {DemandeAnr} from '../../api/model/demandeAnr';
import {EncoursCarteCompte} from '../../api/model/encoursCarteCompte';
import {EvolutionSolde} from '../../api/model/evolutionSolde';
import {Flux} from '../../api/model/flux';
import {Mouvement} from '../../api/model/mouvement';
import {OperationsCarteCompte} from '../../api/model/operationsCarteCompte';
import {OppositionCheque} from '../../api/model/oppositionCheque';
import {RechercheMouvement} from '../../api/model/rechercheMouvement';
import {RechercheMouvementExport} from '../../api/model/rechercheMouvementExport';
import {RechercheVirement} from '../../api/model/rechercheVirement';
import {Repartition} from '../../api/model/repartition';
import {SensMouvementEnum} from '../../api/model/sensMouvementEnum';
import {Synthese} from '../../api/model/synthese';
import {SyntheseCompte} from '../../api/model/syntheseCompte';
import {TauxCompte} from '../../api/model/tauxCompte';
import {TypeListeCompteEnum} from '../../api/model/typeListeCompteEnum';
import {VirementListe} from '../../api/model/virementListe';
import {VirementPermanentListe} from '../../api/model/virementPermanentListe';
import {CoreServiceModule} from '../core-service.module';
import {BaseService} from './base.service';
import {ErreursService} from './erreurs.service';
import {UserService} from './user.service';
import FormatEnum = RechercheMouvementExport.FormatEnum;

/**
 * Interface du service des comptes.
 */
export interface IComptesService {
  /**
   * Les mouvements d'une synthèse compte.
   *
   * @param synhteseCompte la synthèse compte
   * @param dateDebut la date de début
   * @param dateFin la date de fin
   * @param indice l'indice de départ
   * @param nbElements le nombre d'éléments
   */
  mouvements(synhteseCompte: SyntheseCompte, dateDebut: Date, dateFin: Date, indice: number, nbElements: number): Promise<Mouvement[]>;

  /**
   * Charge la synthèse client (liste des comptes)
   * @param client le client concerné
   */
  synthese(client: Client): Promise<Synthese>;

  /**
   * Le suivi mensuel d'un compte
   * @param numeroCompte le numero du compte
   * @param mois Mois en cours - {@param mois} relatif au moi en cours
   */
  flux(numeroCompte: string, mois: number): Promise<Flux>;

  /**
   * Sauvegarde les préférences de la jauge d'un compte
   * @param bornesMinMax les bornes de la jauge
   * @param numeroCompte le numero du compte
   */
  updateJauge(bornesMinMax: BorneMinMax, numeroCompte: string): Promise<void>;

  /**
   * Recherche les mouvements d'un compte
   * @param rechercheMouvement recherche les mouvements d'un compte
   * @param numeroCompte le numero du compte de recherche
   */
  rechercheMouvement(rechercheMouvement: RechercheMouvement, numeroCompte: string): Promise<Mouvement[]>;

  /**
   * Permutte le pointage d'un mouvement
   * @param idMouvement le mmouvement à pointer/dé-pointer
   */
  pointage(idMouvement: string): Promise<void>;

  /**
   * Met à jour une catégorie d'un mouvement
   * @param codeCategorie le code de la catégorie
   * @param idMouvement l'id du mouvement
   */
  majCategorie(codeCategorie: string, idMouvement: string): Promise<void>;

  /**
   * Récupère la liste des catégories disponibles
   * @param sensMouvementEnum le sens Credit ou debit
   * @param numeroCompte le numero du compte concerné
   */
  listeCategories(sensMouvementEnum: SensMouvementEnum, numeroCompte: string): Promise<Categorie[]>;

  /**
   * Ajoute une nouvelle catégorie
   * @param libelleCategorie le libelle de la catégorie
   * @param typeCategorie le type de la catégorie
   * @param numeroCompte le numero du compte concerné
   */
  ajouterCategorie(libelleCategorie: string, typeCategorie: SensMouvementEnum, numeroCompte: string): Promise<Categorie>;

  /**
   * Récupère le détail d'un compte
   * @param numeroCompte l'id du compte
   */
  detailCompte(numeroCompte: string): Promise<Compte>;

  /**
   * Récupère la répartition par catégorie des mouvements.
   * @param numeroCompte le compte concerné
   * @param dateDebut la date de début de la recherche
   * @param dateFin la date de fin de la recherche
   */
  repartitionCompte(numeroCompte: string, dateDebut: Date, dateFin: Date): Promise<Repartition>;

  /**
   * Récupère l'historique des taux
   * @param numeroCompte le compte concerné
   */
  historiqueTaux(numeroCompte: string): Promise<TauxCompte[]>;

  /**
   * Appel le WS du soldes quotidien
   * @param compte le compte concerné
   */
  soldeQuotidien(compte: Compte): Promise<EvolutionSolde>;

  /** Appel le WS d'export des mouvements en Pdf */
  mouvementsExportPdf(numeroCompte: string, recherche: RechercheMouvementExport): Promise<void>;

  /** Mouvements à venir
   *
   * @param numeroCompte le compte concerné
   */
  aVenir(numeroCompte: string): Promise<Mouvement[]>;

  /**
   * Remonte les encours cartes
   * @param numeroCompte le compte
   */
  encoursCartes(numeroCompte: string): Promise<EncoursCarteCompte[]>;

  /**
   * Remonte le détail des encours carte
   * @param numeroCompe le compte
   * @param date date
   */
  detailEncoursCarte(numeroCompe: string, date: string): Promise<OperationsCarteCompte>;

  /**
   * récupère la liste des virements d'un compte
   * @param numeroCompte le numero de compte
   */listeVirements(numeroCompte: string): Promise<VirementListe[]>;

  /**
   * Liste des virements permanent
   * @param recherche le bean de recherche
   * @param numeroCompte le numero de compte
   */
  listeVirementsPermanent(recherche: RechercheVirement, numeroCompte: string): Promise<VirementPermanentListe[]>;

  /**
   * liste des commandes de chéquier en cours
   * @param numeroCompte le compte
   */
  listeCommandeChequier(numeroCompte: string): Promise<CommandeChequierListe[]>;

  /**
   * Liste des comptes pour le budget
   */
  listeCompteBudget(): Promise<CompteListe[]>;

  /**
   * Commande des chéquiers
   * @param commande la commande
   * @param numeroCompte le compte
   */
  commandeChequier(commande: CommandeChequier, numeroCompte: string): Promise<DemandeAnr>;

  /**
   * fait opposition a des chèques
   * @param opposition l'opposition
   * @param numeroCompte le numero de compte
   */
  oppositionChequier(opposition: OppositionCheque, numeroCompte: string): Promise<DemandeAnr>;

  /**
   * liste des comptes pouvant avoir un chequier (historique)
   */
  listeCompteCheque(): Promise<CompteListe[]>;

  /**
   * liste des comptes pouvant commander un chequier
   */
  listeCompteCommandeCheque(): Promise<CompteListe[]>;

  /**
   * Liste des informations de délivrance dt'un chéquier
   * @param numeroCompte le compte
   */
  delivranceChequier(numeroCompte: string): Promise<DelivranceChequier>;

  /**
   * liste des agences de délivrance d'un chéqueir
   */
  agencesDelivrance(): Promise<Agence[]>;

  /**
   * Liste des comptes pour virement
   */
  listeCompteVirement(): Promise<CompteListe[]>;

  /** liste des comptes pour les transfert de fichier */
  listeCompteTransfert(): Promise<CompteListe[]>;

  /** liste des virement a valider */
  listeVirementAValider(numeroCompte: string): Promise<VirementListe[]>;

  /** liste des virements permanents à valider */
  listeVirementPermanentAValier(numeroCompte: string): Promise<VirementPermanentListe[]>;

  /** Liste des virements rejetés   */
  listeVirementAValiderRejetes(numeroCompte: string): Promise<VirementListe[]>;

  /** Liste des virements permanents rejetés */
  listeVirementPermanentAValierRejetes(numeroCompte: string): Promise<VirementPermanentListe[]>;

  /** liste des syntheses compte pour les releves */
  synthesePourReleves(): Promise<Synthese>;

  /** liste des comptes pour le partage de données */
  listeComptePartageDonnees(): Promise<CompteListe[]>;
}

/**
 * Gestion des comptes.
 */
@Injectable({
  providedIn: CoreServiceModule,
})
export class ComptesService extends BaseService implements IComptesService {

  constructor(private userService: UserService, private compteApiService: CompteService, private clientApiService: ClientService,
              private mouvementService: MouvementService, protected logger: NGXLogger,
              private categorieApiService: CategorieService, erreursService: ErreursService,
              private chequierService: ChequierService, private agenceService: AgenceService) {
    super(erreursService, logger);
  }

  /**
   * Les mouvements d'une synthèse compte.
   *
   * @param synhteseCompte la synthèse compte
   * @param dateDebut la date de début
   * @param dateFin la date de fin
   * @param indice l'indice de départ
   * @param nbElements le nombre d'éléments
   */
  mouvements(synhteseCompte: SyntheseCompte, dateDebut: Date, dateFin: Date, indice: number, nbElements: number): Promise<Mouvement[]> {

    return new Promise<Mouvement[]>((resolve, reject) => {
      this.logger.debug('Appel des mouvements avec les params suivants : ',
        this.userService.user.client.id, synhteseCompte.id, format(dateDebut, 'YYYY-MM-DD'),
        format(dateFin, 'YYYY-MM-DD'), indice, nbElements);
      this.compteApiService.mouvements(this.userService.user.client.id, synhteseCompte.id, format(dateDebut, 'YYYY-MM-DD'),
        format(dateFin, 'YYYY-MM-DD'), indice, nbElements, 'body').subscribe({

        next: (response: Mouvement[]) => {
          this.logger.debug('mouvements récupérés', response);
          resolve(response);
        },

        error: (err: HttpErrorResponse) => {
          this.logger.error('mouvements en échec', err.status, err.message);
          reject(this.errorBuilder(err, 'Impossible de charger la liste des opérations'));
        },

      });
    });
  }

  /**
   * Charge la synthèse client (liste des comptes)
   * @param client le client concerné
   */
  synthese(client: Client): Promise<Synthese> {
    return new Promise<Synthese>((resolve, reject) => {
      this.clientApiService.synthese(client.id).subscribe(
        (synth: Synthese) => {
          this.logger.info('Liste comptes', synth);
          resolve(synth);
        },
        (error: HttpErrorResponse) => {
          this.logger.error('Erreur lors de l\'appel du WS synthèse pour le client', client);
          reject(this.errorBuilder(error, 'Impossible de charger la liste des comptes'));
        });
    });
  }

  /**
   * Flux des 30 derniers jours.
   * @param numeroCompte le compte concerné
   */
  flux(numeroCompte: string): Promise<Flux> {
    return new Promise<Flux>((resolve, reject) => {
      this.compteApiService.flux(this.userService.clientActif().id, numeroCompte,
        format(subDays(new Date(), 30), 'YYYY-MM-DD'), format(new Date(), 'YYYY-MM-DD')).subscribe((next: Flux) => {
        this.logger.debug('Flux reçu: ', next);
        resolve(next);
      }, (error: HttpErrorResponse) => {
        this.logger.error('Erreur lors de l\'appel du WS flux pour le tuple {client, compte}',
          this.userService.clientActif()?.id, numeroCompte);
        reject(this.errorBuilder(error, 'Impossible de charger les flux'));
      });
    });
  }

  /**
   * Mise à jour de la jauge d'un compte.
   * @param borneMinMax bornes min et max
   * @param numeroCompte le compte concerné
   */
  updateJauge(borneMinMax: BorneMinMax, numeroCompte: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.compteApiService.updateJauge(borneMinMax, this.userService.clientActif().id, numeroCompte).subscribe(
        (next: void) => {
          this.logger.debug('Bornes sauvegardées:', borneMinMax);
          resolve(next);
        },
        (error: HttpErrorResponse) => {
          this.logger.error('Erreur lors de l\'appel du WS updateJauge pour le compte: ', numeroCompte);
          reject(this.errorBuilder(error, 'Impossible de mettre à jour les préférences de la jauge'));
        });
    });
  }

  /**
   * Recherche des opérations/mouvements
   * @param rechercheMouvement la recherche à effectuer
   * @param numeroCompte le numéro de compte concerné
   */
  rechercheMouvement(rechercheMouvement: RechercheMouvement, numeroCompte: string): Promise<Mouvement[]> {
    this.logger.debug('Recherche mouvement appelée avec les params suivants : ', rechercheMouvement, numeroCompte);
    return new Promise<Mouvement[]>(
      (resolve, reject) => {
        this.compteApiService.rechercheMouvements(rechercheMouvement, this.userService.clientActif().id, numeroCompte).subscribe(
          (result) => {
            this.logger.debug('Mouvements recherchés reçus: ', result);
            resolve(result);
          }
          , (error: HttpErrorResponse) => {
            this.logger.error('Erreur lors de l\'apel du WS rechercheMouvement pour les paramètres', rechercheMouvement, numeroCompte);
            reject(this.errorBuilder(error, 'Erreur lors de la recherche d\'un mouvement'));
          });
      });
  }

  /**
   * Pointe un mvt.
   * @param idMouvement ID du mouvement.
   */
  pointage(idMouvement: string): Promise<void> {
    return new Promise<void>(
      ((resolve, reject) => {
        this.mouvementService.pointage(this.userService.clientActif().id, idMouvement).subscribe(
          () => {
            this.logger.debug('mouvement ' + idMouvement + ' pointé');
            resolve();
          },
          (error) => {
            this.logger.error('Impossible de pointer le mouvement: ' + idMouvement + ' du client: ' + this.userService.clientActif()?.id);
            reject(this.errorBuilder(error, 'Erreur lors du pointage d\'un mouvement'));
          },
        );
      }),
    );
  }

  /**
   * Catégorise un mouvement
   * @param codeCategorie la catégorie où mettre le mvt
   * @param idMouvement le mouvement concerné
   */
  majCategorie(codeCategorie: string, idMouvement: string): Promise<void> {
    return new Promise<void>(
      ((resolve, reject) => {
        this.mouvementService.updateCategorieMouvement(this.userService.clientActif().id, idMouvement, codeCategorie).subscribe(
          () => {
            this.logger.debug('mouvement ' + idMouvement + ' catégorisé en ' + idMouvement);
            resolve();
          },
          (error) => {
            this.logger.error('Impossible de catégoriser le mouvement: ' + idMouvement + ' du client: '
              + this.userService.clientActif()?.id + ' pour la catégorie ' + codeCategorie);
            reject(this.errorBuilder(error, 'Erreur lors de la catégorisation d\'un mouvement'));
          },
        );
      }),
    );
  }

  /**
   * Liste les catégories d'un type (débit ou crédit)
   * @param sensMouvementEnum le sens de la catégorie
   * @param numeroCompte le numéro de compte.
   */
  listeCategories(sensMouvementEnum: SensMouvementEnum, numeroCompte: string): Promise<Categorie[]> {
    return new Promise<Categorie[]>(
      ((resolve, reject) => {
        this.categorieApiService.listeCategories(this.userService.clientActif().id, numeroCompte, sensMouvementEnum).subscribe(
          (next) => {
            this.logger.debug('Liste de catégories retrouvés :', next);
            resolve(next);
          },
          (error) => {
            this.logger.error('Impossible de récupérer les catégories du compte: ' + numeroCompte + ' du client: '
              + this.userService.clientActif()?.id + ' danns le sens ' + sensMouvementEnum);
            reject(this.errorBuilder(error, 'Impossible de récupérer les catégories disponibles'));
          },
        );
      }),
    );
  }

  /**
   * Ajoute une catégorie
   * @param libelleCategorie libellé de la catégorie
   * @param typeCategorie le type de catégorie (débit ou crédit)
   * @param numeroCompte le numéro de compte concerné
   * @deprecated n'est plus utilisé car plus de catégorie personnalisée
   */
  ajouterCategorie(libelleCategorie: string, typeCategorie: SensMouvementEnum, numeroCompte: string): Promise<Categorie> {
    return new Promise<Categorie>(
      ((resolve, reject) => {
        this.categorieApiService.ajouteCategorie(this.userService.clientActif().id,
          numeroCompte, libelleCategorie, typeCategorie).subscribe(
          (next) => {
            this.logger.debug('Catégorie ajoutée :', next);
            resolve(next);
          },
          (error) => {
            this.logger.error('Impossible d\ajouter la catégorie ' + libelleCategorie + ' de type ' + typeCategorie +
              ' au compte: ' + numeroCompte + ' du client: ' + this.userService.clientActif()?.id);
            reject(this.errorBuilder(error, 'Impossible d\'ajouter la catégorie'));
          },
        );
      }),
    );
  }

  /**
   * Appel le WS du détail d'un compte.
   * @param numeroCompte le numéro de compte concerné
   */
  detailCompte(numeroCompte: string): Promise<Compte> {
    return new Promise<Compte>(
      (resolve, reject) => {
        this.compteApiService.compte(this.userService.clientActif().id, numeroCompte).subscribe((next) => {
          this.logger.debug('Compte récupéré ' + next.id);
          resolve(next);
        }, (error1) => {
          this.logger.error('Impossible de récupérer le compte ' + numeroCompte);
          reject(this.errorBuilder(error1, 'Impossible de récupérer le compte demandé'));
        });
      });
  }

  repartitionCompte(numeroCompte: string, dateDebut: Date, dateFin: Date): Promise<Repartition> {
    return new Promise<Repartition>(((resolve, reject1) => {
      this.compteApiService.repartitionCategorie(this.userService.clientActif().id,
        numeroCompte, format(dateDebut, 'YYYY-MM-DD'), format(dateFin, 'YYYY-MM-DD')).subscribe(
        (next) => {
          this.logger.debug('Répartition récupérée pour le compte ' + numeroCompte + ' :', next);
          resolve(next);
        },
        (error1) => {
          this.logger.error('Impossible de récupérer la répartition par catégorie pour les paramètres suivants : ',
            this.userService.clientActif()?.id, numeroCompte, dateDebut.toISOString(), dateFin.toISOString());
          reject1(this.errorBuilder(error1, 'Impossible de récupérer la répartition par catégorie'));
        },
      );
    }));
  }

  historiqueTaux(numeroCompte: string): Promise<TauxCompte[]> {
    return new Promise<TauxCompte[]>(
      (resolve, reject) => {
        this.compteApiService.tauxCompte(this.userService.clientActif().id, numeroCompte).subscribe((next) => {
          this.logger.debug('Historique récupéré', next);
          resolve(next);
        }, (error1) => {
          this.logger.error('Impossible de récupérer le l\'historique des taux du compte ' + numeroCompte);
          reject(this.errorBuilder(error1, 'Impossible de récupérer le l\'historique des taux'));
        });
      });

  }

  /**
   * Appel le WS du soldes quotidien
   * @param compte le compte concerné
   */
  soldeQuotidien(compte: Compte): Promise<EvolutionSolde> {
    return new Promise<EvolutionSolde>(
      (resolve, reject) => {
        this.compteApiService.evolutionSolde(this.userService.clientActif().id, compte.id,
          format(subDays(new Date(), compte.nbJoursMaxMouvement), 'YYYY-MM-DD'), format(new Date(), 'YYYY-MM-DD'))
          .subscribe((next) => {
            this.logger.debug('soldes récupérés', next);
            resolve(next);
          }, (error1) => {
            this.logger.error('Impossible de récupérer les soldes quotidiens du compte ' + compte?.id);
            reject(this.errorBuilder(error1, 'Impossible de récupérer les soldes quotidiens du compte ' + compte.id));
          });
      });
  }

  mouvementsExportPdf(numeroCompte: string, recherche: RechercheMouvement): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.mouvementService.mouvementsExport(this.toRechercheMouvementExport(recherche, 'PDF'), this.userService.clientActif().id, numeroCompte)
        .subscribe((value) => {
          // this.logger.debug('Pdf des mouvements reçu :', value);
          this.userService.dowloadFile(value, 'Mouvement_compte_' + numeroCompte + '.pdf', 'application/pdf');
          resolve();
        }, (error1) => {
          this.logger.error(error1);
          this.logger.error('Impossible de télécharcher le pdf pour les valeurs suivantes: ',
            this.userService.clientActif().id, numeroCompte, recherche);
          reject(this.errorBuilder(error1, 'Impossible de télécharger le fichier PDF'));
        });
    });
  }

  mouvementsExportCsv(numeroCompte: string, recherche: RechercheMouvement): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.mouvementService.mouvementsExport(this.toRechercheMouvementExport(recherche, 'CSV'), this.userService.clientActif().id, numeroCompte)
        .subscribe((value) => {
          this.userService.dowloadFile(value, 'Mouvement_compte_' + numeroCompte + '.csv', 'text/csv');
          resolve();
        }, (error1) => {
          this.logger.error(error1);
          this.logger.error('Impossible de télécharcher le csv pour les valeurs suivantes: ',
            this.userService.clientActif().id, numeroCompte, recherche);
          reject(this.errorBuilder(error1, 'Impossible de télécharger le fichier CSV'));
        });
    });
  }

  aVenir(numeroCompte: string): Promise<Mouvement[]> {
    return new Promise<Mouvement[]>((resolve, reject) => {
      this.mouvementService.aVenir(this.userService.clientActif().id, numeroCompte).subscribe((value) => {
        this.logger.debug('Opérations à venir récupérées : ', value);
        resolve(value);
      }, (error1) => {
        this.logger.error('Impossible de récupérer les opérations à venir du compte ', numeroCompte);
        reject(this.errorBuilder(error1, 'Impossible de récupérer les opérations à venir.'));
      });
    });
  }

  encoursCartes(numeroCompte: string, toutes?: boolean): Promise<EncoursCarteCompte[]> {
    return new Promise<EncoursCarteCompte[]>((resolve, reject) => {
      this.compteApiService.encoursCarteCompte(this.userService.clientActif().id, numeroCompte, toutes).subscribe((value) => {
        this.logger.debug('Encours cartes récupérés: ', value);
        resolve(value);

      }, (error1) => {
        this.logger.error('Impossible de récupérer les encours cartes du compte : ', numeroCompte);
        reject(this.errorBuilder(error1, 'Impossible de récupérer les encours cartes.'));
      });
    });
  }

  detailEncoursCarte(numeroCompe: string, date: string): Promise<OperationsCarteCompte> {
    return new Promise<OperationsCarteCompte>((resolve, reject) => {
      this.compteApiService.operationsCarteCompte(this.userService.clientActif().id,
        numeroCompe, date).subscribe((value) => {
          this.logger.debug('Opérations Cartes récupérées :', value);
          resolve(value);
        },
        (error1) => {
          this.logger.error('Impossible de récupérer le détail des encours cartes du tuple compte,date', numeroCompe, date);
          reject(this.errorBuilder(error1, 'Impossible de récupérer les opérations cartes'));
        });
    });
  }

  listeVirements(numeroCompte: string): Promise<VirementListe[]> {
    return new Promise<VirementListe[]>((resolve, reject) => {
      this.compteApiService.virements(this.userService.clientActif().id, numeroCompte).subscribe((value) => {
        this.logger.debug('Virement reçu', value);
        resolve(value);
      }, (error1) => {
        this.logger.error('Impossible de récupérer la liste des virements de ces paramètres', numeroCompte, error1);
        reject(this.errorBuilder(error1, 'Impossible de récupérer la liste des virements'));
      });
    });
  }

  listeVirementsPermanent(recherche: RechercheVirement, numeroCompte: string): Promise<VirementPermanentListe[]> {
    return new Promise<VirementPermanentListe[]>((resolve, reject) => {
      this.compteApiService.virementsPermanent(recherche, this.userService.clientActif().id, numeroCompte).subscribe((value) => {
        this.logger.debug('Virements reçu', value);
        resolve(value);
      }, (error1) => {
        this.logger.error('Impossible de récupérer la liste des virements permanent de ces paramètres', numeroCompte, recherche, error1);
        reject(this.errorBuilder(error1, 'Impossible de récupérer la liste des virements permanents'));
      });
    });
  }

  listeCommandeChequier(numeroCompte: string): Promise<CommandeChequierListe[]> {
    return new Promise<CommandeChequierListe[]>((resolve, reject) => {
      this.chequierService.listeCommandeChequier(this.userService.clientActif().id, numeroCompte).subscribe((value) => {
        this.logger.debug('Liste des commandes chequier récupérée', value);
        resolve(value);
      }, (error) => {
        this.logger.error('Impossible de récupérer la liste des commandes de chéquier', numeroCompte);
        reject(this.errorBuilder(error, 'Impossible de récupérer la liste des commandes de chéquiers'));
      });
    });
  }

  commandeChequier(commande: CommandeChequier, numeroCompte: string): Promise<DemandeAnr> {

    if (this.userService.user?.isDemo) {
      return this.generePromiseDemandeAnrDemo();
    }
    return new Promise<DemandeAnr>((resolve, reject) => {
      this.chequierService.commandeChequier(commande, this.userService.clientActif().id, numeroCompte).subscribe((value) => {
        this.logger.debug('Commande de chéquiers effectuée', value);
        resolve(value);
      }, (error) => {
        this.logger.error('Impossible de commander un chéquier', commande, numeroCompte);
        reject(this.errorBuilder(error, 'Impossible de commander un chéquier.'));
      });
    });
  }

  oppositionChequier(opposition: OppositionCheque, numeroCompte: string): Promise<DemandeAnr> {

    if (this.userService.user?.isDemo) {
      return this.generePromiseDemandeAnrDemo();
    }
    return new Promise<DemandeAnr>((resolve, reject) => {
      this.chequierService.oppositionCheque(opposition, this.userService.clientActif().id, numeroCompte).subscribe((value) => {
        this.logger.debug('Opposition effectuée', value);
        resolve(value);
      }, (error) => {
        this.logger.error('Impossible de faire opposition', opposition, numeroCompte);
        const erreur: Error = this.errorBuilder(error);
        // on traite les 404 avec une popup, pour éviter de suprimer la saisie utilisateur
        if (erreur.name === '404') {
          this.erreursService.traiteErreur(erreur.message);
          erreur.message = undefined;
        }
        reject(erreur);
      });
    });
  }

  listeCompteCheque(): Promise<CompteListe[]> {
    return new Promise<CompteListe[]>((resolve, reject) => {
      this.compteApiService.listeComptes(this.userService.clientActif().id, TypeListeCompteEnum.ListeChequier).subscribe((value) => {
        this.logger.debug('Liste compte chéquier récupérée', value);
        resolve(value);
      }, (error) => {
        this.logger.error('Impossible de récupérer la liste des comptes chèque', error);
        reject(this.errorBuilder(error));
      });
    });
  }

  listeCompteCommandeCheque(): Promise<CompteListe[]> {
    return new Promise<CompteListe[]>((resolve, reject) => {
      this.compteApiService.listeComptes(this.userService.clientActif().id, TypeListeCompteEnum.CommandeChequier).subscribe((value) => {
        this.logger.debug('Liste compte commande chéquier récupérée', value);
        resolve(value);
      }, (error) => {
        this.logger.error('Impossible de récupérer la liste des comptes de commande de chéquiers', error);
        reject(this.errorBuilder(error));
      });
    });
  }

  listeComptePartageDonnees(): Promise<CompteListe[]> {
    return new Promise<CompteListe[]>((resolve, reject) => {
      this.compteApiService.listeComptes(this.userService.clientActif().id, TypeListeCompteEnum.PartageDonnees).subscribe((value) => {
        this.logger.debug('Liste compte partage données récupérée', value);
        resolve(value);
      }, (error) => {
        this.logger.error('Impossible de récupérer la liste des comptes de partage de données', error);
        reject(this.errorBuilder(error));
      });
    });
  }

  delivranceChequier(numeroCompte: string): Promise<DelivranceChequier> {
    return new Promise<DelivranceChequier>((resolve, reject) => {
      this.chequierService.delivranceChequier(this.userService.clientActif().id, numeroCompte).subscribe((value) => {
        this.logger.debug('Délivrance chéquier récupéré ', value);
        resolve(value);
      }, (error) => {
        this.logger.error('Impossible les récupérer les modes de délivrance', error);
        reject(this.errorBuilder(error));
      });
    });
  }

  agencesDelivrance(): Promise<Agence[]> {
    return new Promise<Agence[]>((resolve, reject) => {
      this.agenceService.agencesSab().subscribe((value) => {
        this.logger.debug('Agences SAB récupérées', value);
        resolve(value);
      }, (error) => {
        this.logger.error('Impossible de récupérer la liste des agences');
        reject(this.errorBuilder(error, 'Impossible de récupérer la liste des agences'));
      });
    });
  }

  listeCompteVirement(): Promise<CompteListe[]> {
    return new Promise((resolve, reject) => {
      this.compteApiService.listeComptes(this.userService.clientActif().id, TypeListeCompteEnum.Virement).subscribe((value) => {
        this.logger.debug('Liste des comptes virement récupérés', value);
        resolve(value);
      }, (error) => {
        this.logger.error('Impossible de récupérer la liste des virements');
        reject(this.errorBuilder(error, 'Impossible de récupérer la liste des comptes'));
      });
    });
  }

  listeCompteTransfert(): Promise<CompteListe[]> {
    return new Promise((resolve, reject) => {
      this.compteApiService.listeComptes(this.userService.clientActif().id, TypeListeCompteEnum.Transfert).subscribe((value) => {
        this.logger.debug('Liste des comptes transfert récupérés', value);
        resolve(value);
      }, (error) => {
        this.logger.error('Impossible de récupérer la liste des comptes de transfert en consultation');
        reject(this.errorBuilder(error, 'Impossible de récupérer la liste des comptes'));
      });
    });
  }

  listeCompteBudget(): Promise<CompteListe[]> {
    return new Promise((resolve, reject) => {
      this.compteApiService.listeComptes(this.userService.clientActif().id, TypeListeCompteEnum.Budget).subscribe((value) => {
        this.logger.debug('Liste des comptes Budget récupérés', value);
        resolve(value);
      }, (error) => {
        this.logger.error('Impossible de récupérer la liste des comptes de Budget');
        reject(this.errorBuilder(error, 'Impossible de récupérer la liste des comptes'));
      });
    });
  }

  listeVirementAValider(numeroCompte: string): Promise<VirementListe[]> {
    return new Promise((resolve, reject) => {
      this.compteApiService.virementsAValider(this.userService.clientActif().id, numeroCompte).subscribe((value) => {
        this.logger.debug('Liste des virments a valider récupéré', value);
        resolve(value);
      }, (error) => {
        this.logger.error('Impossible de récupérer la liste des virements à valider', error);
        reject(this.errorBuilder(error, 'Impossible de récupérer les virements à valider'));
      });
    });
  }

  listeVirementPermanentAValier(numeroCompte: string): Promise<VirementPermanentListe[]> {
    return new Promise((resolve, reject) => {
      this.compteApiService.virementsAValiderPermanent(this.userService.clientActif().id, numeroCompte).subscribe((value) => {
        this.logger.debug('Liste des virments permanent a valider récupéré', value);
        resolve(value);
      }, (error) => {
        this.logger.error('Impossible de récupérer la liste des virements permanent à valider', error);
        reject(this.errorBuilder(error, 'Impossible de récupérer les virements permanents à valider'));
      });
    });
  }

  listeVirementAValiderRejetes(numeroCompte: string): Promise<VirementListe[]> {
    return new Promise((resolve, reject) => {
      this.compteApiService.virementsRejetes(this.userService.clientActif().id, numeroCompte).subscribe((value) => {
        this.logger.debug('Liste des virments rejetés récupéré', value);
        resolve(value);
      }, (error) => {
        this.logger.error('Impossible de récupérer la liste des virements rejetés', error);
        reject(this.errorBuilder(error, 'Impossible de récupérer les virements rejetés'));
      });
    });
  }

  listeVirementPermanentAValierRejetes(numeroCompte: string): Promise<VirementPermanentListe[]> {
    return new Promise((resolve, reject) => {
      this.compteApiService.virementsPermanantsRejetes(this.userService.clientActif().id, numeroCompte).subscribe((value) => {
        this.logger.debug('Liste des virments permanent rejetés récupéré', value);
        resolve(value);
      }, (error) => {
        this.logger.error('Impossible de récupérer la liste des virements permanent rejetés', error);
        reject(this.errorBuilder(error, 'Impossible de récupérer les virements permanents rejetés'));
      });
    });
  }

  synthesePourReleves(): Promise<Synthese> {
    return new Promise<Synthese>((resolve, reject) => {
      this.synthese(this.userService.clientActif()).then((value) => {
        value.listeComptesCourants = value.listeComptesCourants ? value.listeComptesCourants.filter((value1) => value1.categorie !== 'dat') : [];
        value.listeComptesDevises = value.listeComptesDevises ? value.listeComptesDevises.filter((value1) => value1.categorie !== 'dat') : [];
        value.listeComptesEpargne = value.listeComptesEpargne ? value.listeComptesEpargne.filter((value1) => value1.categorie !== 'dat') : [];
        value.listeComptesTiers = value.listeComptesTiers ? value.listeComptesTiers.filter((value1) => value1.categorie !== 'dat') : [];
        resolve(value);
      }).catch((reason) => {
        reject(reason);
      });
    });
  }

  /**
   * Converti une recherche mouvement en recherche mouvement avec un type d'export
   * @param rechercheMouvement la recherche mouvement
   * @param formatEnum le type d'export
   */
  private toRechercheMouvementExport(rechercheMouvement: RechercheMouvement, formatEnum: FormatEnum): RechercheMouvementExport {
    return {
      comparaison: rechercheMouvement.comparaison,
      dateDebut: rechercheMouvement.dateDebut,
      dateFin: rechercheMouvement.dateFin,
      format: formatEnum,
      indiceDebut: rechercheMouvement.indiceDebut,
      libelle: rechercheMouvement.libelle,
      montant: rechercheMouvement.montant,
      nbElements: rechercheMouvement.nbElements,
      sensMouvement: rechercheMouvement.sensMouvement,
    };

  }

}
