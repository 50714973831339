import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {ClientService} from '../../api/api/client.service';
import {Delegation} from '../../api/model/delegation';
import {DemandeAnr} from '../../api/model/demandeAnr';
import {ListesDelegations} from '../../api/model/listesDelegations';
import {NewDelegation} from '../../api/model/newDelegation';
import {ProfilClient} from '../../api/model/profilClient';
import {BaseService} from './base.service';
import {ErreursService} from './erreurs.service';
import {UserService} from './user.service';
import {ComptesDelegation} from '../../api/model/comptesDelegation';
import {CoreServiceModule} from '../core-service.module';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {InfoSouscription} from '../../api/model/infoSouscription';
import {Souscription} from '../../api/model/souscription';

export interface IClientService {

  profilClient(): Promise<ProfilClient>;

  updateProfilClient(profilClient: ProfilClient): Promise<DemandeAnr>;

  /**
   * Remonte la liste des délégations
   */
  delegations(): Promise<ListesDelegations>;

  /**
   * Créer une nouvelle délégation
   * @param newDelegation la nouvelle délégation à créer
   */
  createDelegation(newDelegation: NewDelegation): Promise<DemandeAnr>;

  /**
   * Récupère le détail d'une délégation
   * @param idDelegation le détail de la délégation
   */
  detailDelegation(idDelegation: number): Promise<Delegation>;

  /**
   * Met a jour une délégation
   * @param delegation la délégation a mettre à jour
   */
  updateDelegation(delegation: Delegation): Promise<DemandeAnr>;

  /**
   * Remonte la liste des comptes délégables
   */
  listeComptesDelegation(): Promise<ComptesDelegation>;


  /**
   * Valide ou rejete une délégation
   * @param idDelegation l'id de la délégation
   * @param isValid true si l'utilisateur valide, false sinon
   */
  validateDelegation(idDelegation: number, isValid: boolean): Promise<HttpResponse<DemandeAnr>>;


  /**
   * Récupère les infos de la souscription
   */
  infoSouscription(): Promise<InfoSouscription>;

  /**
   * Envoi la souscription
   * @param souscription la souscription a envoyer
   */
  envoiSouscription(souscription: Souscription): Promise<DemandeAnr>;

  /**
   * Récupère le token Quadient du client
   * @param numeroClient - le numero client
   */
  promiseTokenQuadient(numeroClient: string): Promise<string>;


}

@Injectable({
  providedIn: CoreServiceModule,
})
export class ClientsService extends BaseService implements IClientService {

  constructor(private userService: UserService, private clientService: ClientService, protected logger: NGXLogger, erreursService: ErreursService) {
    super(erreursService, logger);
  }

  profilClient(): Promise<ProfilClient> {
    return new Promise<ProfilClient>((resolve, reject) => {
      this.clientService.profilClient(this.userService.clientActif().id).subscribe((value) => {
          this.logger.debug('Informations client récupérées :', value);
          resolve(value);
        }, (error1) => {
          this.logger.error('Impossible de récupérer les informations clients :', this.userService.clientActif()?.id);
          reject(this.errorBuilder(error1, 'Impossible de récupérer les informations client'));
        },
      );
    });
  }

  updateProfilClient(profilClient: ProfilClient): Promise<DemandeAnr> {

    if (this.userService.user?.isDemo) {
      return this.generePromiseDemandeAnrDemo();
    }
    return new Promise<DemandeAnr>((resolve, reject) => {
      this.clientService.updateProfilClient(profilClient, this.userService.clientActif().id).subscribe((value) => {
        this.logger.debug('Profil Client mit à jour, id Anr: ', value);
        resolve(value);
      }, (error1) => {
        this.logger.error('Impossible de mettre à jour les informations client:', this.userService.clientActif()?.id, profilClient);
        reject(this.errorBuilder(error1, 'Impossible de mettre à jour les informations client.'));
      });
    });
  }

  delegations(): Promise<ListesDelegations> {
    return new Promise<ListesDelegations>((resolve, reject) => {
      this.clientService.delegations(this.userService.clientActif().id).subscribe((value) => {
        this.logger.debug('Liste des délégations récupérés.', value);
        resolve(value);
      }, (error1) => {
        this.logger.error('Impossible de récupérer la liste des délégation pour :' + this.userService.clientActif()?.id, error1);
        reject(this.errorBuilder(error1, 'Impossible de récupérer la liste des délégations'));
      });
    });
  }

  createDelegation(newDelegation: NewDelegation): Promise<DemandeAnr> {

    if (this.userService.user?.isDemo) {
      return this.generePromiseDemandeAnrDemo();
    }
    return new Promise<DemandeAnr>((resolve, reject) => {
      this.clientService.createDelegation(newDelegation, this.userService.clientActif().id).subscribe((value) => {
        this.logger.debug('Anr de création d\'une délégation envoyé', newDelegation);
        resolve(value);
      }, (error1) => {
        this.logger.error('Erreur à la création d\'une délégation', newDelegation, error1);
        reject(this.errorBuilder(error1, 'Impossible de créer la délégation'));
      });
    });
  }

  detailDelegation(idDelegation: number): Promise<Delegation> {
    return new Promise<Delegation>((resolve, reject) => {
      this.clientService.detailDelegation(this.userService.clientActif().id, idDelegation).subscribe((value) => {
        this.logger.debug('Détail délégation:', value);
        resolve(value);
      }, (error1) => {
        this.logger.error('Impossible de récupérer le détail de la délégation ', idDelegation);
        reject(this.errorBuilder(error1, 'Impossible de récupérer le détail de la délégation'));
      });
    });
  }

  updateDelegation(delegation: Delegation): Promise<DemandeAnr> {

    if (this.userService.user?.isDemo) {
      return this.generePromiseDemandeAnrDemo();
    }
    return new Promise<DemandeAnr>((resolve, reject) => {
      this.clientService.updateDelegation(delegation, this.userService.clientActif().id).subscribe((value) => {
        this.logger.debug('Demande de mise a jour de délégation envoyé', delegation);
        resolve(value);
      }, (error1) => {
        this.logger.error('Impossible de mettre à jour la délégation', delegation, error1);
        reject(this.errorBuilder(error1, 'Impossible de mettre à jour la délégation'));
      });
    });
  }

  validateDelegation(idDelegation: number, isValid: boolean): Promise<HttpResponse<DemandeAnr>> {
    return new Promise<HttpResponse<DemandeAnr>>((resolve, reject) => {
      this.clientService.valideDelegation(this.userService.clientActif().id,
        idDelegation, isValid, 'response').subscribe((value) => {
        this.logger.debug('Délégation validée');
        resolve(value);
      }, (error) => {
        this.logger.error('Impossible de valider la délégation', idDelegation, error);
        reject(this.errorBuilder(error, 'Impossible de valider la délégation'));
      });
    });
  }

  listeComptesDelegation(): Promise<ComptesDelegation> {
    return new Promise<ComptesDelegation>((resolve, reject) => {
      this.clientService.listeComptesDelegation(this.userService.clientActif().id).subscribe((value) => {
        this.logger.debug('Liste des comptes valides pour une délégation : ', value);
        resolve(value);
      }, (error) => {
        this.logger.error('Impossible de récupérer la liste des comptes valides pour délégation.', error);
        reject(this.errorBuilder(error, 'Impossible de récupérer la liste des comptes'));
      });
    });
  }


  infoSouscription(): Promise<InfoSouscription> {
    return new Promise<InfoSouscription>((resolve, reject) => {
      this.clientService.infoSouscription(this.userService.clientActif().id).subscribe((value) => {
        this.logger.debug('Info Souscription récupéré avec succès : ', value);
        resolve(value);
      }, (error) => {
        this.logger.error('Impossible de récupérer les infos de souscription pour le client ', this.userService.clientActif().id);
        reject(this.errorBuilder(error, 'Impossible de récupérer les infos de souscription en ligne'));
      });
    });
  }


  envoiSouscription(souscription: Souscription): Promise<DemandeAnr> {

    if (this.userService.user?.isDemo) {
      return this.generePromiseDemandeAnrDemo();
    }
    return new Promise<DemandeAnr>((resolve, reject) => {
      this.clientService.submitSubscription(souscription, this.userService.clientActif().id).subscribe((value) => {
        this.logger.debug('Souscription envoiée : ', souscription);
        resolve(value);
      }, (error) => {
        this.logger.error('Erreur à l\'envoi de la souscription en ligne : ', souscription);
        reject(this.errorBuilder(error, 'Erreur à l\'envoi de la souscription en ligne'));
      });
    });
  }


  public promiseTokenQuadient(numeroClient: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.clientService.tokenQuadient(numeroClient, 'body', false).subscribe({
        next: (response: string) => {
          this.logger.info('Token quadient récupéré avec succés', response);
          resolve(response);
        },
        error: (err: HttpErrorResponse) => {
          this.logger.error('token quadient', err);
          reject(this.errorBuilder(err));
        },
      });
    });
  }

  public updateVueInformationKyc(numeroClient: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.clientService.updateVueInformationKyc(numeroClient, 'body', false).subscribe({
        next: () => {
          this.logger.info('Date vu information KYC mis à jour');
          resolve();
        },
        error: (err: HttpErrorResponse) => {
          this.logger.error('Date vu information KYC', err);
          reject(this.errorBuilder(err));
        },
      });
    });
  }

}
