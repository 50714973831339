import {Component, Input, OnInit} from '@angular/core';
import {catchError, debounceTime, switchMap, tap} from 'rxjs/operators';
import {Pageable} from '../../classes/pageable';
import {IPaged} from '../../../api/model/credit/iPaged';
import {ListeComponent} from '../liste/liste.component';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-liste-paginee-generique [loadData]',
  templateUrl: '../liste/liste.component.html',
  styleUrls: ['./liste-paginee-generique.component.scss'],
})
export class ListePagineeGeneriqueComponent<T, S, P extends IPaged>
  extends ListeComponent<T, S>
  implements OnInit {

  @Input()
  loadData: (filtre: S | undefined, pageable: Pageable) => Observable<P>;

  override ngOnInit(): void {
    super.ngOnInit();
    this.subscription.add(
      this._search$
        .pipe(
          debounceTime(200),
          tap(() => this.isLoading = true),
          switchMap(() => this._search()),
          tap(() => this.isLoading = false)
        )
        .subscribe({
          next: (result) => {
            this.listData = result.data;
            this._total = result.recordsTotal;
          },
          error: (error) => this.handleErreur(error),
        })
    );

    this._search$.next();
    this.isLoading = false;
  }

  private _search(): Observable<P> {
    const {sort, pageSize, page, searchTerm} = this._state;

    const pageable = new Pageable(
      page - 1,
      pageSize,
      sort.sortColumn,
      sort.sortDirection
    );
    return this.loadData(searchTerm, pageable).pipe(
      catchError((err) => {
        this.erreur = err.message;
        this.isLoading = false;
        return [];
      })
    );
  }
}
