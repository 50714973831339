import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import {StatutUtilisateurEnum} from '../../api/model/statutUtilisateurEnum';
import {CoreModule} from '../core.module';
import {UserService} from '../services/user.service';

/**
 * Redirige le client vers la page de changement de mdp si nécessaire.
 */
@Injectable({
  providedIn: CoreModule,
})
export class ModificationMdpGuard  {

  constructor(private userService: UserService, private router: Router) {
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // on vérifie que l'utilisateur est connecté et doit changer son mdp
    if (this.userService.user && this.userService.user.profil && this.userService.user.profil.statut === StatutUtilisateurEnum.REINITIALISATIONMDP) {
      if (this.userService.user.profil.cleAnr === null) {
        if (state.url === '/profil/modification-mdp' || state.url === '/profil/modification-mdp/anr' || state.url === '/profil/reinit-date-mdp/anr') {
          return true;
        } else {
          this.router.navigate(['/profil/modification-mdp']);
          return false;
        }
      } else {
        if (state.url === '/profil/modification-mdp-socsecure') {
          return true;
        } else {
          this.router.navigate(['/profil/modification-mdp-socsecure']);
          return false;
        }
      }
    }
    return true;
  }
}
