import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MessageNotificationAlerte} from '../../../../api/model/messageNotificationAlerte';
import {BaseComponent} from '../../../classes/base-component';
import {AlertesService} from '../../../services/alertes.service';

@Component({
  selector: 'app-alertes',
  templateUrl: './alertes.component.html',
  styleUrls: ['./alertes.component.css'],
})
export class AlertesComponent extends BaseComponent implements OnInit {
  /** nombre des alertes non lu */
  nbMessageNonLu: string;
  /** liste des alertes */
  listeAlertes: MessageNotificationAlerte[];

  constructor(private readonly alertesService: AlertesService, private readonly router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.alertesService.nbMessageNonLu().then((value) => {
      this.nbMessageNonLu = value.toString();
    }).catch((reason) => {
      this.nbMessageNonLu = '!';
    }).finally(() => {
      this.isLoading = false;
    });

  }

  /** charge les lalertes à afficher */
  loadAlertes() {
    this.isLoading = true;
    this.alertesService.deniersMessagesAlerte().then((value) => {
      this.listeAlertes = value;
    }, (reason) => {
      this.erreur = reason.message;
    }).finally(() => {
      this.isLoading = false;
      this.nbMessageNonLu = undefined;
    });

  }

  toutesAlertes() {
    this.router.navigate(['/alerte']);
    // on ferme le menu
    $('.user-menu--noti').removeClass('js-active');
  }
}
