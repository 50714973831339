/**
 * Hoba/wesoc API 3.0
 * API pour l'application bancaire SOCREDO.
 *
 * OpenAPI spec version: 3.04
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Agence } from './agence';

/**
 * Client SAB
 */
export interface Client {
  /**
   * numéro client
   */
  id: string;
  /**
   * Nom complet du client (civilité + nom + prénom)
   */
  nomComplet?: string;
  typeAbonnement?: Client.TypeAbonnementEnum;
  typeClient?: Client.TypeClientEnum;
  /**
   * Charge de clientèle
   */
  chargeClient?: string;
  agence?: Agence;
  /**
   * true si le client est une entreprise individuelle
   */
  entrepriseIndividuelle?: boolean;
  /**
   * Le client a t'il des délégations suspendues ?
   */
  hasDelegationsSuspendues?: boolean;
  /**
   * Le client est en campagne KYC ?
   */
  campagneKYC?: boolean;
  /**
   * Le client à vue les informations KYC ?
   */
  vueInformationKYC?: boolean;
  /**
   * Le client est élligible au crédit ?
   */
  eligibleCredit?: boolean;

}
export namespace Client {
    export type TypeAbonnementEnum = 'free' | 'base_gratuit' | 'base' | 'premium' | 'uru' | 'light';
    export const TypeAbonnementEnum = {
        Free: 'free' as TypeAbonnementEnum,
        BaseGratuit: 'base_gratuit' as TypeAbonnementEnum,
        Base: 'base' as TypeAbonnementEnum,
        Premium: 'premium' as TypeAbonnementEnum,
        Uru: 'uru' as TypeAbonnementEnum,
        Light: 'light' as TypeAbonnementEnum
    };
    export type TypeClientEnum = 'physique' | 'morale';
    export const TypeClientEnum = {
        Physique: 'physique' as TypeClientEnum,
        Morale: 'morale' as TypeClientEnum
    };
}
