import {AfterViewInit, Directive, ElementRef, EventEmitter, HostListener, NgZone, OnDestroy, Output} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
// import * as $ from 'jquery';
import 'slick-carousel';

@Directive({
  selector: '[appSlickComptes]',
})
export class SlickComptesDirective implements AfterViewInit, OnDestroy {
  /** Est-ce que le slick est actif? */
  private isSlickFiring = false;
  /** Objet jquery de la liste. */
  private $accountsList: any;
  /** option du slick */
  private accountsOpt = {
    autoplay: false,
    arrows: false,
    dots: true,
  };

  /** Evénement sur le afterChange. */
  @Output() afterChange: EventEmitter<any> = new EventEmitter();

  constructor(public el: ElementRef, private zone: NgZone, private logger: NGXLogger) {
    this.$accountsList = $(this.el.nativeElement);
  }

  ngAfterViewInit(): void {
    this.fireAccountCarousel();
  }

  ngOnDestroy(): void {
    this.destroyAccountCarousel();
  }

  /**
   * Supprime le carousel.
   */
  private destroyAccountCarousel() {
    if (!this.isSlickFiring) {
      return;
    }
    this.logger.debug('slick : Destroy');
    this.zone.run(() => {
      this.$accountsList.slick('unslick');
    });
    this.isSlickFiring = false;
  }

  /**
   * sur le resize => refait le slick si nécessaire
   */
  @HostListener('window:resize')
  resize() {
    this.responsiveRecall();
  }

  /**
   * Crée le slick si nécessaire < 992px
   */
  private fireAccountCarousel() {
    if (this.isSlickFiring) {
      return;
    }
    if (window.innerWidth < 992) {
      this.logger.debug('Slick');

      this.zone.runOutsideAngular(() => {
        this.$accountsList.slick(this.accountsOpt);
        this.isSlickFiring = true;

        this.$accountsList.on('afterChange', (event, slick, currentSlide) => {
          this.zone.run(() => {
            this.afterChange.emit({event, slick, currentSlide});
          });
        });
      });
    }
  }

  /**
   * Crée le slick si nécessaire < 992 ou le supprime > = 992
   */
  private responsiveRecall() {
    if (window.innerWidth < 992) {
      this.fireAccountCarousel();
    } else {
      this.destroyAccountCarousel();
    }
  }
}
