/**
 * Hoba/wesoc API 3.0
 * API pour l'application bancaire SOCREDO.
 *
 * OpenAPI spec version: 3.00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Type (confirmation mot de passe ou ANR)
 */
export type TypeAnrEnum = 'CONFIRMATION_PWD' | 'ANR' | 'EMAIL' | 'SOCSECURE';

export const TypeAnrEnum = {
  CONFIRMATIONPWD: 'CONFIRMATION_PWD' as TypeAnrEnum,
  ANR: 'ANR' as TypeAnrEnum,
  EMAIL: 'EMAIL' as TypeAnrEnum,
  SOCSECURE: 'SOCSECURE' as TypeAnrEnum
};
