/**
 * Hoba/wesoc API 3.0
 * API pour l'application bancaire SOCREDO.
 *
 * OpenAPI spec version: 3.00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Statut de l'utilisateur
 */
export type StatutUtilisateurEnum =
  'REINITIALISATION_MDP'
  | 'NOUVEL_UTILISATEUR'
  | 'MIGRATION_UTILISATEUR_ETAPE1'
  | 'MIGRATION_UTILISATEUR_ETAPE2';

export const StatutUtilisateurEnum = {
  REINITIALISATIONMDP: 'REINITIALISATION_MDP' as StatutUtilisateurEnum,
  NOUVELUTILISATEUR: 'NOUVEL_UTILISATEUR' as StatutUtilisateurEnum,
  MIGRATIONUTILISATEURETAPE1: 'MIGRATION_UTILISATEUR_ETAPE1' as StatutUtilisateurEnum,
  MIGRATIONUTILISATEURETAPE2: 'MIGRATION_UTILISATEUR_ETAPE2' as StatutUtilisateurEnum
};
