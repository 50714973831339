<app-base-header [titre]="titre"></app-base-header>

<main class="main" *ngIf="!erreur else Erreur ">

  <ng-content></ng-content>

  <section class="anr_form_section">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 pb-4">
          <div class="anr_block py-5">

            <div class="anr_title_form text-center ">Validation de votre demande</div>
            <div class="text-center align-items-center p-4">
              <img alt="smartphone recevant un code sms" height="128" src="assets/images/ico-sms-code.png" width="128"/>
            </div>

            <p class="text-center">Vous allez recevoir un SMS sur votre téléphone portable connu par la Banque SOCREDO
              avec un code. <br/> Veuillez patienter, cette opération peut prendre quelques minutes.</p>
            <form [formGroup]="formGroup" class="anr_form" (validSubmit)="onSubmit()" id="anr_form">
              <div class="phone_number_block p-2 mb-3"><span class="d-block text-center">Numéro connu</span><span
                class="d-block text-center phone_number">{{demandeAnr.telephoneMasque}}</span></div>
              <div class="reset_block text-center">
                <button (click)="toggleInfosAnr()" class="btn btn-outline-primary custom-popover" type="button">
                  je n'ai pas reçu de sms
                </button>
              </div>
              <div class="code_block pt-5 pb-3">
                <div class="code_content_block form-group p-1">
                  <div class="input_block d-flex align-items-center justify-content-center flex-grow-1">
                    <input class="form-control" formControlName="anr" maxlength="6"
                           name="validation_code" type="text"
                           id="validation_code">
                  </div>
                </div>

                <div *ngIf="formGroup.controls.anr.touched && formGroup.controls.anr.hasError('pattern')"
                     class="invalid-feedback d-block text-center">
                  Le champ doit être composé de 6 caractères numériques.
                </div>
              </div>
              <div class="actions_block text-center pb-3">
                <button mwlConfirmationPopover type="button"
                        [popoverTitle]="''"
                        [closeOnOutsideClick]="true"
                        cancelText="Annuler"
                        confirmText="Confirmer"
                        [popoverMessage]="'Etes-vous sûr de vouloir abandonner votre saisie ?'"
                        (confirm)="retourLink()" class="btn btn-outline-secondary">Annuler
                </button>
                <button [ladda]="isSubmit" class="btn btn-outline-primary" type="submit">Valider</button>
              </div>

            </form>
          </div>

          <div *ngIf="bandeauAffiche">
            <div class="text-center align-items-center d-none d-md-block">
              <img src="assets/images/laptop-bandeau.jpg" alt="#" height="250" />
            </div>
            <div class="text-center align-items-center d-block d-md-none">
              <img src="assets/images/mobile-bandeau.png" alt="#" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>

<ng-template #Erreur>
  <app-message-erreur [erreur]="erreur"></app-message-erreur>
</ng-template>

<section class="modal confirm-remove-modal"
         id="infos-anr" role="dialog" tabindex="-1">
  <div [style.max-width.px]="800" class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg"
       role="document">
    <div class="modal-content position-relative py-5 px-3">
      <div class="modal-header"><a (click)="toggleInfosAnr()" class="close-modal-btn"><i
        class="icon-close cursor-pointer"></i></a>
      </div>
      <div class="modal-body text-start px-3">
        <app-infos-anr></app-infos-anr>
      </div>
    </div>
  </div>
</section>

