/**
 * Hoba/wesoc API 3.0
 * API pour l'application bancaire SOCREDO.
 *
 * OpenAPI spec version: 3.00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import {HttpClient, HttpEvent, HttpHeaders, HttpResponse,} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';

import {Beneficiaire} from '../model/beneficiaire';
import {DemandeAnr} from '../model/demandeAnr';
import {NewBeneficiaire} from '../model/newBeneficiaire';

import {Observable} from 'rxjs';
import {Configuration} from '../conf/configuration';
import {BASE_PATH} from '../conf/variables';
import {MultiBeneficiaire} from '../model/multiBeneficiaire';
import {NewMultiBeneficiaire} from '../model/newMultiBeneficiaire';
import {OrdreVirementMultiBeneficiaire} from '../model/ordreVirementMultiBeneficiaire';

@Injectable()
export class BeneficiaireService {

  protected basePath = 'https://www.socms.pf/hoba/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Création d&#x27;un bénéficiaire
   * Envoi une demande d&#x27;ajout d&#x27;un bénéficiaire dans la liste des bénéficiaires (nécessite une confirmation ANR)
   * @param body le beneficiaire à créer
   * @param numeroClient numéro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createBeneficiaire(body: NewBeneficiaire, numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<DemandeAnr>;
  public createBeneficiaire(body: NewBeneficiaire, numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DemandeAnr>>;
  public createBeneficiaire(body: NewBeneficiaire, numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DemandeAnr>>;
  public createBeneficiaire(body: NewBeneficiaire, numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling createBeneficiaire.');
    }

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling createBeneficiaire.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<DemandeAnr>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/beneficiaires`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }

  /**
   * Supprime un bénéficiaire
   * Supprimer un bénéficiaire de la liste des bénéficiaires
   * @param numeroClient numéro du client
   * @param idBeneficiaire id du bénéficiaire
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteBeneficiaire(numeroClient: string, idBeneficiaire: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public deleteBeneficiaire(numeroClient: string, idBeneficiaire: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public deleteBeneficiaire(numeroClient: string, idBeneficiaire: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public deleteBeneficiaire(numeroClient: string, idBeneficiaire: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling deleteBeneficiaire.');
    }

    if (idBeneficiaire === null || idBeneficiaire === undefined) {
      throw new Error('Required parameter idBeneficiaire was null or undefined when calling deleteBeneficiaire.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<any>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/beneficiaires/${encodeURIComponent(String(idBeneficiaire))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }

  /**
   * Consultation d&#x27;un bénéficiaire
   * retourne le bénéficiaire correspondant
   * @param numeroClient numéro du client
   * @param idBeneficiaire id du bénéficiaire
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public detailBeneficiaire(numeroClient: string, idBeneficiaire: string, observe?: 'body', reportProgress?: boolean): Observable<Beneficiaire>;
  public detailBeneficiaire(numeroClient: string, idBeneficiaire: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Beneficiaire>>;
  public detailBeneficiaire(numeroClient: string, idBeneficiaire: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Beneficiaire>>;
  public detailBeneficiaire(numeroClient: string, idBeneficiaire: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling detailBeneficiaire.');
    }

    if (idBeneficiaire === null || idBeneficiaire === undefined) {
      throw new Error('Required parameter idBeneficiaire was null or undefined when calling detailBeneficiaire.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Beneficiaire>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/beneficiaires/${encodeURIComponent(String(idBeneficiaire))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }

  /**
   *
   * Donne la liste des bénéficiaire du multi-bénéficiaire
   * @param numeroClient numéro du client
   * @param idMultiBeneficiaire id du multi-bénéficiaires
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listeBeneficiaireMultiBeneficiaire(numeroClient: string, idMultiBeneficiaire: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Beneficiaire>>;
  public listeBeneficiaireMultiBeneficiaire(numeroClient: string, idMultiBeneficiaire: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Beneficiaire>>>;
  public listeBeneficiaireMultiBeneficiaire(numeroClient: string, idMultiBeneficiaire: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Beneficiaire>>>;
  public listeBeneficiaireMultiBeneficiaire(numeroClient: string, idMultiBeneficiaire: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling listeBeneficiaireMultiBeneficiaire.');
    }

    if (idMultiBeneficiaire === null || idMultiBeneficiaire === undefined) {
      throw new Error('Required parameter idMultiBeneficiaire was null or undefined when calling listeBeneficiaireMultiBeneficiaire.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<Beneficiaire>>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/multibeneficiaires/${encodeURIComponent(String(idMultiBeneficiaire))}/beneficiaires`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Liste des bénéficiaires (simples)
   * retourne la liste des bénéficiaires (simples) des virements du client
   * @param numeroClient numéro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listeBeneficiaires(numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<Beneficiaire[]>;
  public listeBeneficiaires(numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Beneficiaire[]>>;
  public listeBeneficiaires(numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Beneficiaire[]>>;
  public listeBeneficiaires(numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling listeBeneficiaires.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Beneficiaire[]>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/beneficiaires`,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }

  /**
   * Modification d&#x27;un bénéficiaire
   * Envoi une demande de modification d&#x27;un bénéficiaire dans la liste des bénéficiaires (nécessite une confirmation ANR)
   * @param body le beneficiaire à modifier
   * @param numeroClient numéro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateBeneficiaire(body: Beneficiaire, numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<DemandeAnr>;
  public updateBeneficiaire(body: Beneficiaire, numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DemandeAnr>>;
  public updateBeneficiaire(body: Beneficiaire, numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DemandeAnr>>;
  public updateBeneficiaire(body: Beneficiaire, numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling updateBeneficiaire.');
    }

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling updateBeneficiaire.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<DemandeAnr>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/beneficiaires`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }

  /**
   *
   * Export des bénéficiaires XLS
   * @param numeroClient numéro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public exportBeneficiaires(numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
  public exportBeneficiaires(numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
  public exportBeneficiaires(numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
  public exportBeneficiaires(numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling exportBeneficiaires.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/vnd.ms-excel'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/beneficiaires/export`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        responseType: 'blob',
        reportProgress: reportProgress
      }
    );
  }


  /**
   *
   * Import des bénéficiaires XLS
   * @param numeroClient numéro du client
   * @param filename
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public importBeneficiaires(numeroClient: string, filename?: FormData, observe?: 'body', reportProgress?: boolean): Observable<DemandeAnr>;
  public importBeneficiaires(numeroClient: string, filename?: FormData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DemandeAnr>>;
  public importBeneficiaires(numeroClient: string, filename?: FormData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DemandeAnr>>;
  public importBeneficiaires(numeroClient: string, filename?: FormData, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling importBeneficiaires.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'multipart/form-data',
    ];

    const canConsumeForm = this.canConsumeForm(consumes);

    return this.httpClient.post(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/beneficiaires/export`, filename,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }


  /**
   * Création d&#x27;un multi-bénéficiaire
   * Envoi une demande d&#x27;ajout d&#x27;un multi-bénéficiaires
   * @param body le multi-beneficiaire à créer
   * @param numeroClient numéro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createMultiBeneficiaire(body: NewMultiBeneficiaire, numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public createMultiBeneficiaire(body: NewMultiBeneficiaire, numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public createMultiBeneficiaire(body: NewMultiBeneficiaire, numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public createMultiBeneficiaire(body: NewMultiBeneficiaire, numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling createMultiBeneficiaire.');
    }

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling createMultiBeneficiaire.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/multibeneficiaires`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }

  /**
   * Supprime un multi-bénéficiaires
   * Supprimer un multi-bénéficiaires
   * @param numeroClient numéro du client
   * @param idMultiBeneficiaire id du multi-bénéficiaires
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteMultiBeneficiaire(numeroClient: string, idMultiBeneficiaire: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public deleteMultiBeneficiaire(numeroClient: string, idMultiBeneficiaire: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public deleteMultiBeneficiaire(numeroClient: string, idMultiBeneficiaire: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public deleteMultiBeneficiaire(numeroClient: string, idMultiBeneficiaire: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling deleteMultiBeneficiaire.');
    }

    if (idMultiBeneficiaire === null || idMultiBeneficiaire === undefined) {
      throw new Error('Required parameter idMultiBeneficiaire was null or undefined when calling deleteMultiBeneficiaire.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<any>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/multibeneficiaires/${encodeURIComponent(String(idMultiBeneficiaire))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }

  /**
   * Liste des multi-bénéficiaires
   * retourne la liste des multi-bénéficiaires des virements du client
   * @param numeroClient numéro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listeMultiBeneficiaires(numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<MultiBeneficiaire[]>;
  public listeMultiBeneficiaires(numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MultiBeneficiaire[]>>;
  public listeMultiBeneficiaires(numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MultiBeneficiaire[]>>;
  public listeMultiBeneficiaires(numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling listeMultiBeneficiaires.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<MultiBeneficiaire[]>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/multibeneficiaires`,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }

  /**
   * Modification d&#x27;un multi-bénéficiaire
   * Envoi une demande de modification d&#x27;un multi-bénéficiaires
   * @param body le multi-beneficiaire à modifier
   * @param numeroClient numéro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateMultiBeneficiaire(body: MultiBeneficiaire, numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public updateMultiBeneficiaire(body: MultiBeneficiaire, numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public updateMultiBeneficiaire(body: MultiBeneficiaire, numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public updateMultiBeneficiaire(body: MultiBeneficiaire, numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling updateMultiBeneficiaire.');
    }

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling updateMultiBeneficiaire.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<any>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/multibeneficiaires`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }


  /**
   *
   * Donne la liste des ordres de virement d&#x27;un virement multi bénéficiaire
   * @param numeroClient numéro du client
   * @param idVirementMultiBeneficiaire id du virement multiBeneficiaire
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listeOrdreVirementMultiBeneficiaire(numeroClient: string, idVirementMultiBeneficiaire: number, observe?: 'body', reportProgress?: boolean): Observable<Array<OrdreVirementMultiBeneficiaire>>;
  public listeOrdreVirementMultiBeneficiaire(numeroClient: string, idVirementMultiBeneficiaire: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<OrdreVirementMultiBeneficiaire>>>;
  public listeOrdreVirementMultiBeneficiaire(numeroClient: string, idVirementMultiBeneficiaire: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<OrdreVirementMultiBeneficiaire>>>;
  public listeOrdreVirementMultiBeneficiaire(numeroClient: string, idVirementMultiBeneficiaire: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling listeOrdreVirementMultiBeneficiaire.');
    }

    if (idVirementMultiBeneficiaire === null || idVirementMultiBeneficiaire === undefined) {
      throw new Error('Required parameter idVirementMultiBeneficiaire was null or undefined when calling listeOrdreVirementMultiBeneficiaire.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<OrdreVirementMultiBeneficiaire>>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/multibeneficiaires/${encodeURIComponent(String(idVirementMultiBeneficiaire))}/ordres`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
