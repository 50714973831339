<section class="modal moreinfo-modal" id="moreinfo-modal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
    <div class="modal-content position-relative py-5 px-3">
      <div class="modal-header"><a class="close-modal-btn" [routerLink]="" (click)="toggleModal()"><i
        class="icon-close"></i></a></div>
      <div class="modal-body px-3">
        <div class="modal_taux_info">
          <div class="modal__description mt-0">
            <div class="note_info mb-5">
              <h6 class="text--bold"><em>Comment faire en pratique ?</em></h6>
              Utiliser une méthode simple pour définir un mot de passe robuste.
              <ul>
                <li class="m-1">
                  <u class="text--bold"><em>La méthode des premières lettres:</em></u> « Un homme averti en vaut deux »
                  peut
                  devenir « 1hom&#64;vertiEv2 »
                </li>
                <li class="m-1"><u class="text--bold"><em>La méthode de la phrase:</em></u> Votre mot de passe est une
                  phrase ou
                  une suite de mots avec ponctuation.
                  Long mais rapide à taper tout en étant facile à retenir c’est la méthode préconisée : « poulet
                  fafa&frite! »
                </li>
              </ul>
              Ces deux méthodes vous permettent d’avoir un mot de passe long, complexe mais facile à retenir.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
