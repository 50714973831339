<div [id]="idModal" class="modal fade" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Personnaliser mes choix</h5>
        <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
      </div>
      <div class="modal-body">

        <p class="text-justify">Nos sites utilisent des cookies nécessaires à leur fonctionnement.
          Les informations sont collectées pour la Banque SOCREDO, 115 Rue Dumont d’Urville à Papeete (98713),
          responsable du traitement de vos données personnelles. Pour améliorer votre expérience,
          d'autres cookies peuvent être utilisés. Vous pouvez choisir de les désactiver ou de les modifier à tout
          moment sur votre espace client grâce au menu “Paramètres” puis <b>"Mes informations personnelles"</b>.
          <br/> Vos choix seront conservés pendant 6 mois.</p>
        <div class="row">
          <div class="col-sm-2 icon_item"><i class="fas fa-cog"></i></div>
          <div class="col-sm-8 text-black-50"><strong>Requis (accès au site web)</strong><br/>
            <span>Ces cookies sont indispensables pour le bon fonctionnement de notre site.<br>
                Ce suivi est toujours activé pour l'adapter à vos besoins.</span>
          </div>
          <div class="col-sm-2 text-center">
            <div class="form-group checkbox-2 checkbox-2--green">
              <div class="form-check">
                <input [checked]="true" [disabled]="true" class="form-check-input"
                       id="requiredCheck" type="checkbox"/>
                <label class="form-check-label" for="requiredCheck"></label>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-sm-2 icon_item"><i class="fas fa-chart-bar"></i></div>
          <div class="col-sm-8"><strong>Fonctionnel (amélioration du site Web)</strong><br>
            <span>Nous utilisons le suivi fonctionnel pour analyser l'utilisation de notre site Web.<br>
            Ces cookies permettent de détecter d’éventuels problèmes techniques et d’optimiser notre site.
              Ils nous permettent également d’enrichir nos études publicitaires et marketing.</span>
          </div>
          <div class="col-sm-2 text-center">
            <div class="form-group checkbox-2 checkbox-2--green">
              <div class="form-check">
                <input (change)="cookies.switchAnalytics()" [checked]="cookies?.isGoogleAnalytics()"
                       class="form-check-input" id="gaCheck"
                       type="checkbox"/>
                <label class="form-check-label" for="gaCheck"></label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="col text-start">
          <a [href]="urlDonnesPerso" target="_blank">En savoir +</a>
        </div>
        <div class="col text-end">
          <button (click)="accepteCookies()" class="btn btn-outline-primary" data-bs-dismiss="modal" type="button">
            Valider
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
