import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {ANRService} from '../../api/api/aNR.service';
import {Anr} from '../../api/model/anr';
import {BaseService} from './base.service';
import {ErreursService} from './erreurs.service';
import {CoreServiceModule} from '../core-service.module';
import {HttpResponse} from '@angular/common/http';
import {CheckValidationAnr} from '../../api/model/checkValidationAnr';

export interface IAnr {
  /**
   * Effectue une validation Anr et renvoi true si l'anr est la première validation d'une double validation
   * @param anr l'anr a valider
   */
  validationAnr(anr: Anr): Promise<HttpResponse<any>>;
}

@Injectable({
  providedIn: CoreServiceModule,
})
export class AnrsService extends BaseService implements IAnr {

  constructor(private readonly anrService: ANRService, protected readonly logger: NGXLogger, readonly erreursService: ErreursService) {
    super(erreursService, logger);
  }

  checkValidationAnr(cleAnr: string): Promise<HttpResponse<CheckValidationAnr>> {
    return new Promise<HttpResponse<CheckValidationAnr>>((resolve, reject) => {
      this.anrService.checkValidationAnr(cleAnr, 'response').subscribe((value) => {
        this.logger.debug('Vérification de la validation ANR :', value.body.valider);
        resolve(value);
      }, (error1) => {
        this.logger.warn('Impossible de vérifier l\'anr', cleAnr);
        reject(this.errorBuilder(error1, 'Imposssible de vérifier si l\'opération est validée.'));
      });
    });
  }

  validationAnr(anr: Anr): Promise<HttpResponse<any>> {
    return new Promise<HttpResponse<any>>((resolve, reject) => {
      this.anrService.validationAnr(anr, 'response').subscribe((value) => {
        this.logger.debug('Anr validé avec succès :', anr);
        resolve(value);
      }, (error1) => {
        this.logger.warn('Impossible de valider l\'anr', anr);
        reject(this.errorBuilder(error1, 'La saisie de votre code est invalide ou le délai est dépassé. Veuillez renouveler votre opération.'));
      });
    });
  }

}
