<app-top-header></app-top-header>

<main class="main">

  <router-outlet></router-outlet>

  <app-contact-widget></app-contact-widget>
</main>

<app-footer-contact></app-footer-contact>
<app-footer></app-footer>
<app-simple-popup [bodyText]="erreur" (close)="closeError()" ></app-simple-popup>
