<section class="modal moreinfo-modal js-active" id="moreinfo-modal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
    <div class="modal-content position-relative py-5 px-3">
      <div class="modal-header"><a (click)="closeModal()" class="close-modal-btn cursor-pointer"><i
        class="icon-close"></i></a></div>
      <div class="modal-body px-3">
        <div class="modal_taux_info">
          <div class="modal__description mt-0">
            <div class="title_modal">LES BONNES PRATIQUES</div>
            <ul >
              <li class="p-2">&gt;  Ne cliquez pas trop vite sur un lien figurant dans un message
                (mail ou SMS). Assurez-vous avant de cliquer que le lien conduit bien à une adresse Socredo valide
                /connue en passant la souris sur le lien (sans cliquer).
              </li>
              <li class="p-2">&gt; Ne communiquez jamais vos identifiants (Login et mot de passe) ;
                même votre banque ne vous demandera jamais votre mot de passe.
              </li>

              <li class="p-2" >&gt; Ne vous envoyez pas votre propre mot de passe sur votre
                messagerie personnelle.
              </li>
              <li class="p-2" >&gt; N’enregistrez jamais votre mot de passe sur votre navigateur
              </li>
              <li class="p-2" >&gt; Consultez régulièrement les consignes de sécurité de la Banque
                Socredo
              </li>
              <li class="p-2" >&gt; Choisissez avec soin un mot de passe personnel et évitez
                d’utiliser le même mot de passe que pour d’autres services en ligne.
              </li>
              <li class="p-2" >&gt; Consultez régulièrement vos comptes en vous connectant à votre
                site client ; cela peut vous permettre de détecter un incident au plus tôt.
              </li>
              <li class="p-2" >&gt; Signalez rapidement toute anomalie sur vos comptes. Si une
                opération ne vous concerne pas, prévenez immédiatement la Banque Socredo.
              </li>
              <li class="p-2" >&gt; Réagissez en cas d’activité suspecte sur votre téléphone
                (réception d’un code à usage unique par SMS, indiquant qu’une opération est en cours sur l’un de vos
                comptes).
              </li>
              <li class="p-2" >&gt; Sécurisez vos connexions en vérifiant la présence de https (« s
                » pour secure) devant l’adresse du site.
              </li>
              <li class="p-2" >&gt; Protégez votre matériel (ordinateur, téléphone portable,
                tablette) contre les logiciels malveillants (malwares) de tous types par l’installation d’un antivirus
                (régulièrement mis à jour) et d’un pare-feu.
              </li>
              <li class="p-2" >&gt; Naviguez avec un navigateur à jour.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
