<header class="main-header">
  <div class="header-content">
    <div class="container">
      <div class="header-content-inner d-flex justify-content-between flex-wrap align-items-stretch">
        <h1 class="logo"><a class="logo__link d-inline-block" [href]="siteInsitutionelUrl()"><img
          class="logo__link__img"
          src="assets/images/logo.png"
          alt="Banque Socredo"></a></h1>
        <div class="user-block header_type2 d-flex align-items-end"><a
          class="user-item position-relative d-inline-block" [href]="devenirClientUrl()" >
          <div class="user-item__icon text-center"><i class="icon-user-institu"></i></div>
          <div class="user-item__text"><span>Devenir client</span></div>
        </a></div>
      </div>
    </div>
  </div>
</header>

<router-outlet></router-outlet>

<app-simple-popup (close)="closeError()" [bodyText]="erreur"></app-simple-popup>

<footer class="main-footer">
  <div class="contact_info_block">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 d-flex align-items-center flex-column flex-sm-row"><img class="logo-footer"
                                                                                     src="assets/images/logo-footer2.png"
                                                                                     alt="Banque Socredo"><span
          class="text_tel ps-0 pt-2 pt-sm-0 ps-sm-5">Centre de relation clientèle : 40 47 00 00</span></div>
        <div class="col-sm-4 d-flex align-items-center pt-3 pt-sm-0 justify-content-center justify-content-sm-end"><a
          [href]="urls.facebook" class="circle_icon"><i class="fab fa-facebook-f"></i></a><a [href]="urls.youtube"
                                                                                             class="circle_icon"><i
          class="fab fa-youtube"></i></a><a [href]="urls.instagram" class="circle_icon"><i class="fab fa-instagram"></i></a><a
          [href]="urls.linkedin" class="circle_icon"><i class="fab fa-linkedin"></i></a></div>
      </div>
    </div>
  </div>
  <div class="footer-content footer-content--type2 position-relative">
    <span class="position-absolute" style="bottom: 5px; right: 5px; font-size: 8px">{{version}}</span>
    <div class="container py-5">
      <div class="row">
        <div class="footer-list-wrapper col-md-9">
          <div class="row">
            <div *ngFor="let categorie of footerPublique" class="list col-6 col-md-3 col-lg-3 col-xl-3">
              <h4 class="list__title">{{categorie.titre}}</h4>
              <div *ngFor="let lien of categorie.liens" class="list__items">
                <a class="list__item" [href]="lien[1]">{{lien[0]}}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-3 d-none d-md-block text-center"><img src="assets/images/pattern2.png" alt="#"></div>
      </div>
    </div>
  </div>
</footer>

