<app-base-header
  [titre]=" consultation ? 'Conditions générales d’utilisation' : 'Validation des conditions générales d’utilisation'"></app-base-header>


<section class="connexion_section">
  <div class="container pt-5">
    <h3 class="mt-3 mb-2">Conditions générales d’utilisation des services de banque à distance</h3><br>
    <p>Prenez connaissance des conditions générales d'utilisation</p>
  </div>
</section>

<section class="informations_personnelles_section connexion_section">
  <div class="container pb-5">
    <form (validSubmit)="submitCgu()" [formGroup]="formGroup" id="informations_personnelles_form">
      <div class="block_content">

        <div class="cgu_block" *ngIf="urlCgu" (scroll)="scrollHandler($event)">
          <pdf-viewer [src]="urlCgu"
                      [render-text]="false" [fit-to-page]="true" [original-size]="false"
                      style="display: block; width: 100%; height: 100%;">
          </pdf-viewer>
        </div>

        <ng-container *ngIf="!consultation">
          <div class="d-flex justify-content-center">
            <div class="form-group checkbox-2 checkbox-2--green checkbox_validation_cgu pt-3">
              <div class="form-check">
                <input class="form-check-input " formControlName="accepte" id="confirmation_cgu" name="confirmation_cgu"
                       type="checkbox" value="1">
                <label class="form-check-label label_validation_cgu" for="confirmation_cgu"><span>J'ai lu et j'accepte les conditions d'utilisation</span></label>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-sm-12 text-center pt-3">
              <button [ladda]="isSubmit" class="btn btn-outline-primary" type="submit">Valider</button>
            </div>
          </div>
        </ng-container>
      </div>
    </form>
  </div>
</section>
