import {Injectable} from '@angular/core';
import {Parametre} from '../../api/model/parametre';
import {NGXLogger} from 'ngx-logger';
import {ParametreService} from '../../api/api/parametre.service';
import {BaseService} from './base.service';
import {ErreursService} from './erreurs.service';
import {Observable, of} from "rxjs";
import {tap} from "rxjs/operators";


/**
 * Interface de ParametresService.
 */
export interface IParametresService {
  /**
   * @returns la liste de tous les paramètres.
   */
  listeParametres(): Promise<Parametre[]>;

  /**
   * @returns le paramètre de l'URL des CGU
   */
  urlCgu(): Promise<string>;

  /**
   * @returns les paramètres SOCSECURE
   */
  urlSocSecure(): Promise<string[]>;


  /**
   * @returns l'url de campagne kyc
   */
  urlCampagneKyc(): Promise<string>;

  /**
   * @returns le nombre de mois de recherche pour les relevés
   */
  nbMoisRechercheReleves(): Promise<number>
}

/**
 * Service pour récupérer les paramètres
 */
@Injectable({
  providedIn: 'root'
})
export class ParametresService extends BaseService implements IParametresService {

  parametres: Parametre[];

  constructor(protected readonly logger: NGXLogger, private readonly parametreApiService: ParametreService,
              readonly erreursService: ErreursService) {
    super(erreursService, logger);
  }

  /**
   * @returns la liste de tous les paramètres.
   */
  listeParametres(): Promise<Parametre[]> {
    return new Promise<Parametre[]>((resolve, reject) => {
      this.recupereParametres().subscribe((value) => {
        this.logger.debug('Liste des paramètres reçue');
        resolve(value);
      }, (error1) => {
        this.logger.error('Impossible de récupérer la liste des paramètres', error1);
        reject(this.errorBuilder(error1, 'Impossible de récupérer la liste des paramètres'));
      });
    });
  }

  /**
   * @returns le paramètre de l'URL des CGU
   */
  urlCgu(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.recupereParametres().subscribe((value) => {
        this.logger.debug('Liste des paramètres reçue');
        const param = value.filter(value1 => value1.cle === 'url.cgu').pop();
        if (param) {
          this.logger.debug('URL des CGU', param);
          resolve(param.valeur);
        } else {
          reject(new Error('Impossible de trouver le paramètre des CGU'));
        }
      }, (error1) => {
        this.logger.error('Impossible de récupérer le paramètres des CGU', error1);
        reject(this.errorBuilder(error1, 'Impossible de récupérer le paramètres des CGU'));
      });
    });
  }

  /**
   * @returns les paramètres des URL SOC Secure
   */
  urlSocSecure(): Promise<string[]> {
    return new Promise<string[]>((resolve, reject) => {
      this.recupereParametres().subscribe((value) => {
        this.logger.debug('Liste des paramètres reçue');
        const param = value.filter(value1 => value1.cle === 'url.socsecure.angular').pop();
        const param2 = value.filter(value1 => value1.cle === 'url.socsecure.android').pop();
        const param3 = value.filter(value1 => value1.cle === 'url.socsecure.ios').pop();
        if (param && param2 && param3 && param.valeur && param2.valeur && param3.valeur) {
          this.logger.debug('URL ANGULAR SOCSECURE', param);
          this.logger.debug('URL ANDROID SOCSECURE', param2);
          this.logger.debug('URL IOS SOCSECURE', param3);
          resolve([param.valeur, param2.valeur, param3.valeur]);
        } else {
          reject(new Error('Impossible de trouver les paramètres socsecure'));
        }
      }, (error1) => {
        this.logger.error('Impossible de récupérer les paramètres socsecure', error1);
        reject(this.errorBuilder(error1, 'Impossible de récupérer les paramètres socsecure'));
      });
    });
  }

  /** récupère les parametres en cache ou depuis le WS si le cache est vide */
  recupereParametres(): Observable<Parametre[]> {
    if (this.parametres) {
      return of(this.parametres);
    } else {
      return this.parametreApiService.parametres().pipe(tap(x => this.parametres = x));
    }
  }

  /**
   * @returns le paramètre de l'URL des crédits
   */
  urlCredit(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.recupereParametres().subscribe((value) => {
        this.logger.debug('Liste des paramètres reçue');
        const param = value.filter(value1 => value1.cle === 'url.credit').pop();
        if (param) {
          this.logger.debug('URL des crédits', param);
          resolve(param.valeur);
        } else {
          reject(new Error('Impossible de trouver le paramètre des crédits'));
        }
      }, (error1) => {
        this.logger.error('Impossible de récupérer le paramètres des crédits', error1);
        reject(this.errorBuilder(error1, 'Impossible de récupérer le paramètres des crédits'));
      });
    });
  }

  /**
   * @returns le paramètre de l'URL des crédits
   */
  urlCampagneKyc(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.recupereParametres().subscribe((value) => {
        this.logger.debug('Liste des paramètres reçue');
        const param = value.filter(value1 => value1.cle === 'url.campagne.kyc').pop();
        if (param) {
          this.logger.debug('URL des crédits', param);
          resolve(param.valeur);
        } else {
          reject(new Error('Impossible de trouver le paramètre de l\'url de campagne kyc'));
        }
      }, (error1) => {
        this.logger.error('Impossible de récupérer le paramètres de l\'url de campagne kyc', error1);
        reject(this.errorBuilder(error1, 'Impossible de récupérer le paramètres de l\'url de campagne kyc'));
      });
    });
  }

  /**
   * @returns le nombre de mois de recherche pour les relevés
   */
  nbMoisRechercheReleves(): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      this.recupereParametres().subscribe((value) => {
        this.logger.debug('Liste des paramètres reçue');
        const param = value.filter(value1 => value1.cle === 'releves.nb.mois.recherche').pop();
        if (param) {
          this.logger.debug('Nombre de mois de recherche pour les relevés', param);
          resolve(Number(param.valeur));
        } else {
          reject(new Error('Impossible de trouver le paramètre du nombre de mois de recherche pour les relevés'));
        }
      }, (error1) => {
        this.logger.error('Impossible de récupérer le paramètres du nombre de mois de recherche pour les relevés', error1);
        reject(this.errorBuilder(error1, 'Impossible de récupérer le paramètres du nombre de mois de recherche pour les relevés'));
      });
    });
  }
}
