import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Cookie} from '../../models/cookie';
import {CookiesService} from '../../services/cookies.service';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-cookies-modal[idModal]',
  templateUrl: './cookies-modal.component.html',
  styleUrls: ['./cookies-modal.component.css'],
})
export class CookiesModalComponent implements OnInit {
  /** Les préférences des cookies. */
  public cookies: Cookie;
  /** environnement. */
  public readonly urlDonnesPerso = environment.urlsInstitutionelles.donneesPersonnelles;
  /** ID modale. */
  @Input() idModal: string;

  constructor(private cookiesService: CookiesService, public userService: UserService) {
  }

  ngOnInit(): void {
    this.cookies = this.cookiesService.cookies;
  }

  /**
   * Accepte les cookies en l'état.
   */
  accepteCookies(): void {
    this.cookiesService.accepteCookies();
  }
}
