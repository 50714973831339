import {Directive, ElementRef, Input, OnInit} from '@angular/core';


@Directive({
  selector: '[appForceAutoFocus]'
})
export class ForceAutoFocusDirective implements OnInit {

  @Input('forceFocus') isFocused = true;

  constructor(private hostElement: ElementRef) {
  }

  ngOnInit() {
    if (this.isFocused) {
      this.hostElement.nativeElement.focus();
    }
  }

}
