<div *ngIf="!erreur else Erreur">
    <div *ngIf="!isLoading else Loading">
        <ng-content></ng-content>
        <ng-container *ngIf="content" [ngTemplateOutlet]="content.templateRef"></ng-container>
    </div>
</div>

<ng-template #Erreur>
    <app-message-erreur [erreur]="erreur!"></app-message-erreur>
</ng-template>

<ng-template #Loading>
    <app-ajax-loader *ngIf="isLoading"></app-ajax-loader>
</ng-template>

