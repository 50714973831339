import {Component} from '@angular/core';
import {AnrComponent} from '../anr/anr.component';

@Component({
  selector: 'app-anr-email',
  templateUrl: './anr-email.component.html',
  styleUrls: ['./anr-email.component.css']
})
export class AnrEmailComponent extends AnrComponent {

}
