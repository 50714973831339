import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  toasts = new Map<string, Toast>();

  constructor() {
  }

  /**
   * Ajoute un toast.
   *
   * @param titre titre du toast
   * @param contenu contenu du toast
   * @param delay le temps d'affichage. Par défaut 5s.
   * @param id l'ID du toast pour éviter les doublons.
   */
  show(id: string, titre: string, contenu: string, delay: number = 5000) {
    this.toasts.set(id, new Toast(id, titre, contenu, delay));
  }

  /**
   * Retire un toast.
   * @param toast le toast à retirer
   */
  remove(toast: Toast) {
    this.toasts.delete(toast.id);
  }
}

// Un toast
export class Toast {

  constructor(public readonly id: string, public readonly titre: string, public readonly contenu: string, public readonly delay: number = 5000) {
  }
}
