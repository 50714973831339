import {addMonths} from 'date-fns';

export class Cookie {
  /** Les cookies ont-ils été acceptés. */
  public cookieAccepte = false;
  /** google analytics a-t-il été accepté. */
  public analyticsAccepte = false;
  /** Date acceptation cookies. */
  private dateAcceptation: Date;

  /**
   * Accepte tous les cookies.
   */
  accepteAllCookies(): void {
    this.cookieAccepte = true;
    this.analyticsAccepte = true;
    this.dateAcceptation = new Date();
  }

  /**
   * Accepte required cookies.
   */
  accepteRequiredCookies(): void {
    this.cookieAccepte = true;
    this.dateAcceptation = new Date();
  }

  /**
   * Refuse tous les cookies (sauf les required).
   */
  refuseAllCookies(): void {
    this.cookieAccepte = true;
    this.analyticsAccepte = false;
    this.dateAcceptation = new Date();
  }

  /** Vérifie si les cookies sont périmés. */
  checkPerime() {
    if (this.dateAcceptation && addMonths(this.dateAcceptation, 6).getTime() < new Date().getTime()) {
      this.cookieAccepte = false;
      this.analyticsAccepte = false;
      this.dateAcceptation = null;
    }
  }

  /**
   * @return true si les cookies on était validés
   */
  isAccepted(): boolean {
    return this.dateAcceptation != null;
  }

  /**
   *  @return true si les analytics sont cochés.
   */
  isGoogleAnalytics(): boolean {
    return this.analyticsAccepte;
  }

  /**
   * @return true si les analytics sont cochés et que les cookies ont été validés.
   */
  isGoogleAnalyticsAccepted(): boolean {
    return this.analyticsAccepte && this.isAccepted();
  }

  /**
   * On change le analytics.
   */
  switchAnalytics() {
    this.analyticsAccepte = !this.analyticsAccepte;
  }
}
