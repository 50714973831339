import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {BehaviorSubject} from 'rxjs';
import {AlerteService} from '../../api/api/alerte.service';
import {MessageNotificationAlerte} from '../../api/model/messageNotificationAlerte';
import {ProfilAlerteClient} from '../../api/model/profilAlerteClient';
import {ProfilAlerteCompte} from '../../api/model/profilAlerteCompte';
import {ReglagesAlertes} from '../classes/reglages-alertes';
import {CoreServiceModule} from '../core-service.module';
import {BaseService} from './base.service';
import {ErreursService} from './erreurs.service';
import {UserService} from './user.service';

export interface IAlertesService {

  reglagesAlertes$: BehaviorSubject<ReglagesAlertes>;

  /**
   * Retourne la liste des alertes
   */
  listeAlertes(): Promise<MessageNotificationAlerte[]>;

  /** cré un poril d'alert */
  createProfilAlerteCompte(profilAlert: ProfilAlerteCompte): Promise<void>;

  /** met à jour un porfil d'alerte */
  updateProfilAlerteCompte(profilAlert: ProfilAlerteCompte): Promise<void>;

  /** suprime une notification */
  deleteAllMessage(): Promise<void>;

  /** retourne le nombre de message non lu */
  nbMessageNonLu(): Promise<number>;

  /** suprime le profil d'alerte */
  deleteProfilAlerteCompte(numeroAlerte: number): Promise<void>;

  /** retourne les dernièresalertes à afficher en popup */
  deniersMessagesAlerte(): Promise<MessageNotificationAlerte[]>;

  /** récupère le profil d'alerte du client */
  profilAlerteClient(): Promise<ProfilAlerteClient>;

  /** suprime tout les message */
  deleteMessage(idMessage: number): Promise<void>;
}

@Injectable({
  providedIn: CoreServiceModule,
})
export class AlertesService extends BaseService implements IAlertesService {

  /** profilAlerteClient */
  reglagesAlertes$: BehaviorSubject<ReglagesAlertes> = new BehaviorSubject(undefined);

  constructor(private readonly userService: UserService, readonly erreurService: ErreursService,
              private readonly alerteApiService: AlerteService, protected readonly logger: NGXLogger) {
    super(erreurService, logger);
  }

  listeAlertes(): Promise<MessageNotificationAlerte[]> {
    return new Promise<MessageNotificationAlerte[]>((resolve, reject) => {
      this.alerteApiService.listeMessagesAlerte().subscribe((value) => {
        this.logger.debug('Liste des alertes récupérées', value);
        resolve(value);
      }, (error) => {
        this.logger.error('Impossible de récupérer la liste des alertes');
        reject(this.errorBuilder(error, 'Impossible de récupérer la liste des alertes'));
      });
    });
  }

  createProfilAlerteCompte(profilAlert: ProfilAlerteCompte): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.alerteApiService.createProfilAlerteCompte(profilAlert, this.userService.clientActif().id).subscribe((value) => {
        this.logger.debug('Nouvelle alerte créée', value);
        resolve();
      }, (error) => {
        this.logger.error('Impossible d\'enregistrer l\'alerte');
        reject(this.errorBuilder(error, 'Impossible d\'enregistrer l\'alerte'));
      });
    });
  }

  updateProfilAlerteCompte(profilAlert: ProfilAlerteCompte): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.alerteApiService.updateProfilAlerteCompte(profilAlert, this.userService.clientActif().id).subscribe((value) => {
        this.logger.debug('Alerte mise a jour', value);
        resolve();
      }, (error) => {
        this.logger.error('Impossible de mettre à jour l\'alerte');
        reject(this.errorBuilder(error));
      });
    });
  }

  deleteAllMessage(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.alerteApiService.deleteAllMessage().subscribe((value) => {
        this.logger.debug('Tout les messages suprimés', value);
        resolve();
      }, (error) => {
        this.logger.error('Une erreur est survenue à la supression');
        reject(this.errorBuilder(error));
      });
    });
  }

  deleteMessage(idMessage: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.alerteApiService.deleteMessageAlerte(idMessage).subscribe((value) => {
        this.logger.debug('Message suprimé', value);
        resolve();
      }, (error) => {
        this.logger.error('Une erreur est survenue à la supression');
        reject(this.errorBuilder(error));
      });
    });
  }

  nbMessageNonLu(): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      this.alerteApiService.infosMessagesAlerte().subscribe((value) => {
        this.logger.debug('Nombre de message non lu', value);
        resolve(value.nbMessageNonLu);
      }, (error) => {
        this.logger.error('Impossible de récupérer le nombre de message lu');
        reject(this.errorBuilder(error));
      });
    });
  }

  deleteProfilAlerteCompte(numeroAlerte: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.alerteApiService.deleteProfilAlerteCompte(this.userService.clientActif().id, numeroAlerte).subscribe((value) => {
        this.logger.debug('Profil d\'alerte suprimée', value);
        resolve();
      }, (error) => {
        this.logger.error('Impossible suprimer le profil d\'alerte', numeroAlerte, error);
        reject(this.errorBuilder(error));
      });
    });
  }

  deniersMessagesAlerte(): Promise<MessageNotificationAlerte[]> {
    return new Promise<MessageNotificationAlerte[]>((resolve, reject) => {
      this.alerteApiService.derniersMessagesAlerte().subscribe((value) => {
        this.logger.debug('DErnières notifications recupéré', value);
        resolve(value);
      }, (error) => {
        this.logger.error('Impossible de récupérer les dernières alertes', error);
        reject(this.errorBuilder(error));
      });
    });
  }

  profilAlerteClient(): Promise<ProfilAlerteClient> {
    return new Promise<ProfilAlerteClient>((resolve, reject) => {
      this.alerteApiService.profilAlerteClient(this.userService.clientActif().id).subscribe((value) => {
        this.logger.debug('Profil alerte client récupéré', value);
        resolve(value);
      }, (error) => {
        this.logger.error('Impossible de récupérer le profil des alertes', error);
        reject(this.errorBuilder(error));
      });
    });
  }

}
