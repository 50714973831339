/**
 * Hoba/wesoc API 3.0
 * API pour l'application bancaire SOCREDO.
 *
 * OpenAPI spec version: 3.01
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Type de liste de compte
 */
export type TypeListeCompteEnum = 'Virement' | 'RIB' | 'ListeChequier' | 'CommandeChequier' | 'Transfert' | 'PartageDonnees' | 'Budget';

export const TypeListeCompteEnum = {
  Virement: 'Virement' as TypeListeCompteEnum,
  RIB: 'RIB' as TypeListeCompteEnum,
  ListeChequier: 'ListeChequier' as TypeListeCompteEnum,
  CommandeChequier: 'CommandeChequier' as TypeListeCompteEnum,
  Transfert: 'Transfert' as TypeListeCompteEnum,
  PartageDonnees: 'PartageDonnees' as TypeListeCompteEnum,
  Budget: 'Budget' as TypeListeCompteEnum
};
