import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {FooterPublique} from '../../classes/footer/categorie-footer';
import {SimplePopupComponent} from '../../components/simple-popup/simple-popup.component';
import {ErreursService} from '../../services/erreurs.service';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.css'],
})
export class AuthLayoutComponent implements OnInit, OnDestroy {

  /** l'erreur à afficher */
  erreur: string;
  /** souscription. */
  private readonly subscription = new Subscription();
  footerPublique = FooterPublique;
  urls = environment.urlsInstitutionelles;
  version = environment.version;

  constructor(private readonly erreursService: ErreursService) {
  }

  ngOnInit() {
    this.subscription.add(this.erreursService.message$.subscribe((value) => {
      this.erreur = value;
      SimplePopupComponent.show();
    }));
  }

  /**
   * @return l'url du site institutionel
   */
  siteInsitutionelUrl(): string {
    return environment.urlsInstitutionelles.siteInstitutionel;
  }

  /**
   * @return l'url du site institutionel pour ouvrir un compte
   */
  devenirClientUrl(): string {
    return environment.urlsInstitutionelles.devenirClient;
  }

  /** ferme la popup d'erreur */
  closeError() {
    SimplePopupComponent.hide();
    this.erreur = undefined;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
