<app-erreur-or-load [erreur]="erreur" [isLoading]="isLoading">
  <ng-template appLoaded>
    <section id="ng-tables">

      <div class="row text-left">
        <div class="col-12">
          <form>
            <div class="table-responsive-lg">
              <table class="table">

                <ng-content select="thead"></ng-content>
                <ng-content select="tbody"></ng-content>


                <ng-container *ngIf="listData.length<=0">
                  <tfoot>
                  <tr>
                    <td class="no-data-available" colspan="12">Aucun element</td>
                  </tr>
                  </tfoot>
                </ng-container>
              </table>
            </div>

            <div class="d-flex justify-content-between p-2">

              <ngb-pagination [(page)]="page" [collectionSize]="total" [pageSize]="pageSize">
              </ngb-pagination>

              <select [(ngModel)]="pageSize" class="form-select" name="pageSize" style="width: auto">
                <option *ngFor="let pageSize of pageSizes" [ngValue]="pageSize"> {{pageSize}} elements par
                  page
                </option>
              </select>

            </div>

          </form>

        </div>
      </div>
    </section>
  </ng-template>
</app-erreur-or-load>
