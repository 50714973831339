import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {AnrsService} from '../../services/anrs.service';
import {BaseComponent} from '../../classes/base-component';
import {HttpResponse} from '@angular/common/http';
import {DemandeAnr} from '../../../api/model/demandeAnr';

@Component({
  selector: 'app-anr[titre][lienAnnulation]',
  templateUrl: './anr.component.html',
  styleUrls: ['./anr.component.css'],

})
export class AnrComponent extends BaseComponent implements OnInit {

  /** le titre de la page d'anr */
  @Input() titre: string;
  /** le lien vers la page de confirmation */
  @Output() confirmationEvent: EventEmitter<HttpResponse<DemandeAnr>> = new EventEmitter<HttpResponse<DemandeAnr>>();
  /** lien du bouton d'annulation */
  @Input() lienAnnulation;
  /** Bandeau affiché */
  @Input() bandeauAffiche = true;

  /** le form group concerné */
  formGroup: UntypedFormGroup;
  /** l'anr à envoyer */
  @Input() demandeAnr: DemandeAnr;

  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly anrsService: AnrsService,
              private readonly activatedRoute: ActivatedRoute, private readonly router: Router, private readonly logger: NGXLogger) {
    super();
  }

  ngOnInit() {

    this.formGroup = this.formBuilder.group({
      anr: ['', [Validators.required, Validators.pattern('\\d{6}')]],
    });

  }

  onSubmit() {
    if (!this.isSubmit) {
      this.isSubmit = true;
      this.anrsService.validationAnr({cleAnr: this.demandeAnr.cleAnr, codeAnr: this.formGroup.controls.anr.value})
        .then((value) => {
          this.confirmationEvent.emit(value);
        })
        .catch((reason) => this.erreur = reason.message)
        .finally(() => {
          this.isSubmit = false;
        });
    }
  }

  retourLink() {
    this.router.navigate([this.lienAnnulation]);
  }

  toggleInfosAnr() {
    $('#infos-anr').toggle();
  }

}
