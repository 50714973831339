import {Injectable} from '@angular/core';
import * as $ from 'jquery';
import {CoreServiceModule} from '../../core-service.module';

@Injectable({
  providedIn: CoreServiceModule,
})
export class UtilService {

  constructor() {
  }

  /** Est-ce que le composant est dans le viewport? */
  isInViewPort = ($elm) => {
    const elmTop = $elm.offset().top;
    const elmBottom = elmTop + $elm.outerHeight();
    const viewportTop = $(window).scrollTop();
    const viewportBottom = viewportTop + $(window).height();

    return elmTop >= viewportTop || elmBottom <= viewportBottom;
  };

}
