import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {NGXLogger} from 'ngx-logger';
import {UserService} from './user.service';
import {CoreServiceModule} from '../core-service.module';

declare var bootstrap: any;

@Injectable({
  providedIn: CoreServiceModule,
})
export class TutorielService {

  constructor(private sanitizer: DomSanitizer, private logger: NGXLogger, private http: HttpClient, private userService: UserService) {
  }

  tuto_set(file_data: FichierDataTutorielEnum) {

    const $body = $('body');

    const tuto_liste = [];
    let tuto_data = [];

    if (file_data) {
      this.http.get('assets/file/' + file_data, {responseType: 'text'}).subscribe((response) => {
        tuto_data = JSON.parse(response);

        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let x = 0; x < tuto_data.length; x++) {
          const id_tuto = tuto_data[x][0];

          if ($('#' + id_tuto).length && $('#' + id_tuto).is(':visible')) {
            tuto_liste.push(tuto_data[x]);
          }
        }

        if (tuto_liste.length) {
          let total_step = tuto_liste.length;
          let nb_step = 1;
          if ($('#tuto-modal').length) {
            nb_step++;
            total_step = total_step + 2;
          }
          for (let i = 0; i < tuto_liste.length; i++) {

            const id_tuto = tuto_liste[i][0];
            const title_tuto = tuto_liste[i][1] + '<span class=\'step_info\'>(' + nb_step + '/' + total_step + ')</span>';
            const text_tuto = tuto_liste[i][2];
            const bg_tuto = tuto_liste[i][3];
            const placement = tuto_liste[i][4];
            const modal_last = tuto_liste[i][5];
            const custom_class = tuto_liste[i][6];
            const image_tuto = tuto_liste[i][7];
            let prev_id_tuto = '';
            const z = i - 1;
            if (z >= 0) {
              prev_id_tuto = tuto_liste[z][0];
            }

            let next_id_tuto = '';
            const y = i + 1;
            if (y < tuto_liste.length) {
              next_id_tuto = tuto_liste[y][0];
            }

            const $current_id = $('#' + id_tuto);
            $current_id.data('container', 'body');
            $current_id.data('placement', placement);
            $current_id.data('prev', prev_id_tuto);
            $current_id.data('next', next_id_tuto);
            $current_id.data('title', title_tuto);
            $current_id.attr('data-bs-title', title_tuto);
            $current_id.data('content', text_tuto);
            $current_id.data('bg', bg_tuto);
            $current_id.data('last', modal_last);
            $current_id.data('class', custom_class);
            $current_id.data('image', image_tuto);
            nb_step++;
          }

          const first_id = tuto_liste[0][0];

          if ($('#tuto-modal').length) {
            $('#tuto-modal .step_info').html('(1/' + total_step + ')');
            $('#tuto-last-modal .step_info').html('(' + total_step + '/' + total_step + ')');
            $('#tuto-submit').data('first', first_id);

            if ($(window).width() > 992 && $('#' + first_id).length !== 0) {
              $('#tuto-modal').addClass('js-active');
              $body.addClass('no-scroll');
              this.userService.user.isPremiereVisiteSynthese = false;
            }
          } else {
            if ($(window).width() > 992 && $('#' + first_id).length !== 0 && tuto_liste.length > 1) {
              this.tutostart($('#' + first_id), '');

              switch (file_data) {
                case 'detail_tuto_data.txt':
                  this.userService.user.isPremiereVisiteDetail = false;
                  break;
                case 'liste_tuto_data.txt':
                  this.userService.user.isPremiereVisiteListe = false;
                  break;
                case 'synthese_tuto_data.txt':
                  this.userService.user.isPremiereVisiteSynthese = false;
                  break;
              }
            }
          }
        }
      });
    }

  }

  tutostart($elt_id, $elt_current) {
    const $body =
      $('body');
    let next3;
    if ($elt_current.length !== 0) {
      const popover = bootstrap.Popover.getOrCreateInstance('#' + $elt_current.attr('id'));
      popover.hide();
      popover.disable();
      $elt_current.off();

      const next_old = $elt_current.data('next');
      const new_id = $elt_id.attr('id');
      if (new_id === next_old) {
        next3 = $('#' + new_id).data('next');
      } else {
        next3 = $('#' + new_id).data('prev');
      }
    }
    const custom_class = $elt_id.data('class');
    if (custom_class !== '' && custom_class !== undefined) {
      $body.addClass(custom_class);
    }

    const popover = new bootstrap.Popover($elt_id, {
      container: 'body',
      content: 'default body',
      html: true,
      delay: 500,
      offset: [0, 20]
    });

    $elt_id.on('show.bs.popover', (e) => {
      const bg = $(e.currentTarget).data('bg');
      $('.backdrop_tuto').remove();
      $('.backdrop_tuto2').remove();
      $('body').prepend('<div class="backdrop_tuto"></div><div class="backdrop_tuto2"></div>');
      $(e.currentTarget).addClass('popover_item');
      if (bg === '1') {
        $(e.currentTarget).addClass('bg-pop');
      }
      $('html, body').animate({scrollTop: $(e.currentTarget).offset().top - 200}, 500);
      $body.addClass('tuto');
    });

    $elt_id.on('shown.bs.popover', (e) => {
      let content = $elt_id.data('content');
      const image = $elt_id.data('image');
      const prev = $elt_id.data('prev');
      const next = $elt_id.data('next');
      const last = $elt_id.data('last');
      let next_label = 'Suite';

      if (image !== '' && image !== undefined) {
        content += '<div class="popover-image pt-3"><img src="' + image + '" /></div>';
      }

      content += '<div class="popover-footer">';
      if (prev !== '' && prev !== undefined) {
        content += '<button class="btn btn-outline-primary button_popover" data-last="" data-next="' + prev + '"><i class="icon-angle-left"></i> Précédent</button>';
      }
      if (!(next !== '' && next !== undefined || last !== '' && last !== undefined)) {
        next_label = 'Terminer';
      }
      content += '<button class="btn btn-outline-primary button_popover" data-last="' + last + '" data-next="' + next + '">' + next_label + ' <i class="icon-angle-right"></i></button>';
      content += '<button class="button_popover_close"><i class="icon-close"></i></button>';
      content += '</div>';
      $('.popover-body').html(content);

      $('.button_popover').on('click', (e1) => {
        // renomé pour évité le shadowing
        const next1 = $(e1.currentTarget).data('next');
        const last1 = $(e1.currentTarget).data('last');

        if (next1 === '' || next1 === undefined) {
          popover.hide();
          popover.disable();
          $('.backdrop_tuto').remove();
          $('.backdrop_tuto2').remove();
          $('html, body').animate({scrollTop: 0}, 500);

          if (last1 !== '' && last1 !== undefined) {
            $('#' + last1).addClass('js-active');
            $body.addClass('no-scroll');
          }
        } else {
          if ($('.popover').length !== 0) {
            popover.hide();
            popover.disable();
          }
          this.tutostart($('#' + next1), $elt_id);
        }
      });

      $('.button_popover_close').on('click', (e1) => {
        popover.hide();
        popover.disable();
        $('.backdrop_tuto').remove();
        $('.backdrop_tuto2').remove();
        $('html, body').animate({scrollTop: 0}, 500);
      });
    });

    $elt_id.on('hide.bs.popover', (e) => {
      if (custom_class !== '' && custom_class !== undefined) {
        $body.removeClass(custom_class);
      }
      $(e.currentTarget).removeClass('popover_item');
      $(e.currentTarget).removeClass('tuto');
      $body.removeClass('tuto');
      popover.disable();
    });

    if ($elt_id.is(':visible')) {
      popover.update();
      popover.enable();
      popover.show();
    } else {
      this.tutostart($('#' + next3), $elt_id);
    }
  }

  iniTutoriel() {
    const $body = $('body');

    $('#tuto-submit').on('click', (e) => {
      const first_id = $(e.currentTarget).data('first');
      $('#tuto-modal').removeClass('js-active');
      $body.removeClass('no-scroll');

      this.tutostart($('#' + first_id), '');
    });

    $('.tuto_start').on('click', (e) => {
      e.preventDefault();
      this.tuto_set('synthese_tuto_data.txt');
      this.tuto_set('liste_tuto_data.txt');
      this.tuto_set('detail_tuto_data.txt');
    });

    $('#tuto-close').on('click', () => {
      $('#tuto-last-modal').removeClass('js-active');
      $body.removeClass('no-scroll');
      $('html, body').animate({scrollTop: 0}, 500);
    });

    // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
    $(window).resize(() => {
      if ($(window).width() < 992) {
        if ($('.popover_item').length !== 0) {
          $('.popover_item').popover('hide');
        }
        $('.backdrop_tuto').remove();
        $('.backdrop_tuto2').remove();

        if ($('#tuto-modal').hasClass('js-active')) {
          $('#tuto-modal').removeClass('js-active');
          $body.removeClass('no-scroll');
        }
      }
    });
  }

  /** lance la modale de bienvenu du tutoriel de la synthese */
  lanceModaleTutorielSynthese() {
    if (this.userService.premiereConnexion() && this.userService.user.isPremiereVisiteSynthese) {
      setTimeout(() => {
        this.tuto_set('synthese_tuto_data.txt');


      }, 3000);
    }

  }

  lanceTutorielListe() {
    if (this.userService.premiereConnexion() && this.userService.user.isPremiereVisiteListe) {
      setTimeout(() => {
        this.tuto_set(FichierDataTutorielEnum.ListeCompte);

      }, 3000);
    }
  }

  lanceTutorielDetail() {
    if (this.userService.premiereConnexion() && this.userService.user.isPremiereVisiteDetail) {
      setTimeout(() => {
        this.tuto_set(FichierDataTutorielEnum.DetailCompte);
      }, 3000);
    }
  }

  /** ferme la modal de bienvenu de la synthese */
  fermeModaleTutoriel() {
    const $body = $('body');
    $('#tuto-modal').removeClass('js-active');
    $body.removeClass('no-scroll');
  }

  lanceModaleFinTutoriel() {
    const $body = $('body');
    $('#tuto-last-modal').addClass('js-active');
    $body.addClass('no-scroll');
  }

  /** ferme la modal de fin de tutoriel */
  fermeModaleFinTutoriel() {
    const $body = $('body');
    $('#tuto-last-modal').removeClass('js-active');
    $body.removeClass('no-scroll');
  }

  removePopover($elt_id) {
    $elt_id.popover('hide');
    $elt_id.popover('disable');
    $('.backdrop_tuto').remove();
    $('html, body').animate({scrollTop: 0}, 500);
  }

}

type FichierDataTutorielEnum = 'synthese_tuto_data.txt' | 'detail_tuto_data.txt' | 'liste_tuto_data.txt' ;
const FichierDataTutorielEnum = {
  Synthese: 'synthese_tuto_data.txt' as FichierDataTutorielEnum,
  DetailCompte: 'detail_tuto_data.txt' as FichierDataTutorielEnum,
  ListeCompte: 'liste_tuto_data.txt' as FichierDataTutorielEnum,
};
