/**
 * Classe pour la pagination des tables et des appels des WS paginés.
 */
export class Pageable {

  constructor(public page: number, public size: number, public sortProperty?: string, public sens?: string) {

  }

  getSort(): string {
    if (this.sortProperty) {
      if (this.sens) {
        // Tout le monde est là, on envoie
        return `${this.sortProperty},${this.sens}`;
      }
      // On a que la property, utilisation avec le sens par défaut
      return this.sortProperty;
    }
    // pas de sort
    return '';
  }
}
