import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import * as $ from 'jquery';
import {UserService} from '../../services/user.service';
import {InfosMessageAlerte} from '../../../api/model/infosMessageAlerte';
import {MessagerieService} from '../../services/messagerie.service';
import {Subscription} from 'rxjs';
import {AccesUserService} from '../../services/acces-user.service';

@Component({
  selector: 'app-top-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  /** Nombre de messages de la messagerie non-lus */
  nbMessageNonLu: InfosMessageAlerte;

  /** Subscription a détruire au onDestroy */
  subscription: Subscription = new Subscription();

  constructor(readonly userService: UserService, private router: Router, private messagesService: MessagerieService,
              public accesUserService: AccesUserService) {
  }

  ngOnInit() {
    // on récupère au moins une fois le nombre de messages non lus
    this.messagesService.actualiseMessagesNonLus();
    // on écoute les mises a jours, en cas de lecture ou de supression
    this.subscription.add(this.messagesService.nombreMessageNonLu$.subscribe((value) => {
      this.nbMessageNonLu = value;
    }));

    $(() => {
      const activeClass = 'js-active';
      const $body = $('body');
      const $userItemIcons = $('.user-item__icon');

      $userItemIcons.on('click', (e) => {
        const screenWidth = window.innerWidth;
        const $icon = $(e.currentTarget);
        const $item = $icon.closest('.user-item');
        const $siblingsUserMenus = $item.siblings().find('.user-menu');
        const $userMenu = $icon.parent().find('.user-menu');
        if (!$userMenu.length) {
          return;
        }

        e.preventDefault();
        $item.siblings().removeClass(activeClass);
        $siblingsUserMenus.removeClass(activeClass);
        $userMenu.toggleClass(activeClass);
        $icon.parent().toggleClass(activeClass);

        if ($(`.user-menu.${activeClass}`).length && screenWidth <= 768) {
          $body.addClass('no-scroll');
        } else {
          $body.removeClass('no-scroll');
        }
      });

      $body.on('mouseup', (e) => {
        const $items = $('.user-menu-wrapper');
        const $icons = $('.user-item__icon__image, .user-item__icon__number, .user-item__icon');
        const $usermenu_link = $('.user-menu__item');
        const $usermenu_button = $('.user-menu-button');

        if ($icons.is(e.target) && $(e.target).closest('.user-item.' + activeClass).length) {
          return;
        }

        if (!$items.parent().is(e.target) && $items.parent().has(e.target).length === 0 || $usermenu_link.is(e.target)
          || $usermenu_link.has(e.target).length > 0 || $usermenu_button.is(e.target)) {
          $items.parent().removeClass(activeClass);
          $items.parent().parent().removeClass(activeClass);

          if (!$('.' + activeClass).length) {
            $body.removeClass('no-scroll');
          }
        }
      });
    });
  }

  /**
   * Pour se déconnecter.
   */
  logout(): void {
    this.userService.logOut();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
