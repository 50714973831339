import {AfterViewInit, Component, Input, OnDestroy} from '@angular/core';
import {ScriptLoaderService} from 'angular-google-charts';
import {isBefore, parse, subMonths} from 'date-fns';
import {NGXLogger} from 'ngx-logger';
import {Assurance} from '../../../api/model/assurance';
import {fromEvent, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-graphe-valorisation-assurance',
  templateUrl: './graphe-valorisation-assurance.component.html',
  styleUrls: ['./graphe-valorisation-assurance.component.css'],
})
export class GrapheValorisationAssuranceComponent implements AfterViewInit, OnDestroy {
  /** couleur du graphe */
  private static COLOR = '#9EBE38';
  @Input() assurance: Assurance;
  options: any = {
    title: '',
    hAxis: {
      format: 'dd/MM/yy',
      gridlineColor: '#fff',
      textStyle: {
        fontSize: 10,
      },
      // ticks: [parse('2019-05-01'), parse('2019-06-03'), parse('2019-07-01')],
      ticks: [],
    },
    vAxis: {
      format: '### ###',
      gridlineColor: '#f1f1f1',
      textStyle: {
        fontSize: 10,
      },
    },
    tooltip: {isHtml: true},
    legend: {position: 'bottom'},
    chartArea: {left: '7%', top: '10%', width: '90%', height: '70%'},
    series: {
      0: {color: '#fff'},
    },
  };
  height: 300;
  /** Le graphe. */
  private chart: any;
  /** souscription au resize. */
  private subscription: Subscription = new Subscription();

  constructor(protected loaderService: ScriptLoaderService, private logger: NGXLogger) {
  }

  ngAfterViewInit() {
    this.loaderService.loadChartPackages('corechart').subscribe(() => {

      const data = new google.visualization.DataTable();
      data.addColumn('datetime', 'Date');
      data.addColumn('number', '');
      data.addColumn({type: 'string', role: 'style'});
      if (this.assurance.valorisation.length > 0) {
        let i = 0;
        let valorisation = this.assurance.valorisation[i];
        while (valorisation && isBefore(subMonths(new Date(), 12), parse(valorisation.dateValorisation))) {
          data.addRow([parse(valorisation.dateValorisation), valorisation.lignes.reduce((previousValue, currentValue) => {
            return previousValue + currentValue.valeurTotalXpf;
          }, 0), GrapheValorisationAssuranceComponent.COLOR]);
          // on doit ajouter les dates que l'on souhaite dans l'axe
          this.options.hAxis.ticks.push(parse(valorisation.dateValorisation));
          this.logger.debug('Valorisation :', valorisation);
          valorisation = this.assurance.valorisation[++i];
        }
      }

      const formatter_date = new google.visualization.DateFormat({pattern: 'dd/MM/yy'});
      const formatter_number = new google.visualization.NumberFormat({
        groupingSymbol: ' ',
        fractionDigits: 0,
        suffix: ' XPF'
      });
      formatter_date.format(data, 0);
      formatter_number.format(data, 1);

      try {
        this.chart = new google.visualization.ColumnChart(document.getElementById('chart'));
      } catch (error) {
        this.logger.warn('Impossible de charge le graphe', error);
        this.chart = null;
      }
      if (this.chart !== null) {
        this.chart.draw(data, this.options);

        this.subscription.add(fromEvent(window, 'resize')
          .pipe(debounceTime(100))
          .subscribe(() => {
            this.chart.draw(data, this.options);
          }));
      }
    });
  }

  ngOnDestroy() {
    if (this.chart) {
      this.subscription.unsubscribe();
      this.chart.clearChart();
    }
  }
}
