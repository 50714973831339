/***
 * Classe stockant les informations de l'utilisateur connecté.
 */
import {Client} from '../../api/model/client';
import {Profil} from '../../api/model/profil';
import {DroitEnum} from '../../api/model/droitEnum';
import TypeAbonnementEnum = Client.TypeAbonnementEnum;

export class User {
  /**
   * Constructeur.
   * @param token le token de l'utilisateur
   * @param numeroAbonne le numéro d'abonné
   */
  constructor(token?: string, numeroAbonne?: string) {
    this._token = token;
    this._numeroAbonne = numeroAbonne
  }

  /** Token fournis par le WebService à l'utilisateur lors de son authentification */
  private readonly _token: string;
  /** Numéro d'abonné */
  private readonly _numeroAbonne: string;

  /** la page synthese a t'elle déjà été visitée pour le tutoriel ? */
  public isPremiereVisiteSynthese: boolean;
  /** la page Liste des comptes a t'elle déjà été visitée pour le tutoriel ? */
  public isPremiereVisiteListe: boolean;
  /** la page détail d'un compte a t'elle déjà été visitée pour le tutoriel ? */
  public isPremiereVisiteDetail: boolean;
  /** l'utilisateur est-il celui de démo ? */
  public isDemo = false;

  get token(): string {
    return this._token;
  }

  get numeroAbonne(): string {
    return this._numeroAbonne;
  }

  /** Profils. */
  private _profil: Profil;

  get profil(): Profil {
    return this._profil;
  }

  set profil(profil: Profil) {
    this._profil = profil;
  }

  /** Client sélectionné. */
  private _client: Client;

  get client(): Client {
    return this._client;
  }

  set client(client: Client) {
    this._client = client;
  }

  /** Token Quadient */
  private _tokenQuadient: string;

  get tokenQuadient(): string {
    return this._tokenQuadient;
  }

  set tokenQuadient(tokenQuadient: string) {
    this._tokenQuadient = tokenQuadient;
  }

  /**
   * @return la date de dernière connexion de l'utilisateur
   */
  derniereConnexion(): Date {
    if (this._profil.derniersAcces[1]) {
      return this._profil.derniersAcces[1].date;
    } else {
      return this._profil.derniersAcces[0].date;
    }
  }

  dernierDevice(): string {
    if (this._profil.derniersAcces[1]) {
      return this._profil.derniersAcces[1].device;
    } else {
      return this._profil.derniersAcces[0].device;
    }
  }

  /**
   * A-t-il un accès en consultation sur ce compte?
   * @param numeroCompte le compte concerné
   */
  public isConsultation(numeroCompte: string): boolean {
    return this.isComplet() || !!this.profil.droits.find((value) => {
      return value.numeroCompte === numeroCompte;
    });
  }

  public isSaisisseur(numeroCompte: string): boolean {
    return this.isComplet() || !!this.profil.droits.find((value) => {
      return value.numeroCompte === numeroCompte &&
        (value.droit === DroitEnum.Saisisseur || value.droit === DroitEnum.SaisisseurValideur);
    });
  }

  public isValideur(numeroCompte: string): boolean {
    return this.isComplet() || !!this.profil.droits.find((value) => {
      return value.numeroCompte === numeroCompte &&
        (value.droit === DroitEnum.Valideur || value.droit === DroitEnum.SaisisseurValideur);
    });
  }

  /**
   * A-t-il tous les droits : soit un adhérent, soit un délégué avec accès complet.
   */
  public isComplet(): boolean {
    return (this.profil?.typeProfil === 'adherent' || this.profil?.accesComplet);
  }

  /**
   * A-t-il au moins un droit saisisseur?
   */
  public hasDroitSaisisseur(): boolean {
    return this.isComplet() || !!this.profil.droits.find((value) => {
      return (value.droit === DroitEnum.Saisisseur || value.droit === DroitEnum.SaisisseurValideur);
    });
  }

  /**
   * A-t-il au moins un droit valideur?
   */
  public hasDroitValideur(): boolean {
    return this.isComplet() || !!this.profil.droits.find((value) => {
      return (value.droit === DroitEnum.Valideur || value.droit === DroitEnum.SaisisseurValideur);
    });
  }

  isPremium(): boolean {
    return this.client.typeAbonnement === TypeAbonnementEnum.Premium;
  }
}
