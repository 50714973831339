import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.css'],
})
export class ErrorPageComponent {
  erreur: string;

  constructor(private readonly router: Router, private readonly location: Location) {
    if (this.location.getState()) {
      const state = this.location.getState() as { erreur: string };
      this.erreur = state.erreur;
    }
  }

}
