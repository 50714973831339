<a class="user-item__icon d-flex align-items-center position-relative" href="#">
  <i class="icon-user user-item__icon__image"></i>
</a>
<div class="user-menu">
  <div class="user-menu-wrapper">
    <a (click)="changeClient(client)" *ngFor="let client of clients"
       class="user-menu__item cursor-pointer"><span>{{client.nomComplet}}</span></a>
  </div>
</div>

