import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {DemandeAnr} from '../../../api/model/demandeAnr';
import {BaseComponent} from '../../classes/base-component';
import {HttpResponse} from '@angular/common/http';
import {AnrComponent} from '../anr/anr.component';
import {UserService} from '../../services/user.service';


export interface RetourAnr {
  created: boolean;
  bilan: string;
}

@Component({
  selector: 'app-anr-generique',
  templateUrl: './anr-generique.component.html',
  styleUrls: ['./anr-generique.component.css']
})
/**
 * Composant qui a pour role de déterminer quel type d'anr gérer
 */
export class AnrGeneriqueComponent extends BaseComponent implements OnInit, OnDestroy {

  @Input() titre: string;

  @Input() lienAnnulation = '/home';
  /** Bandeau affiché */
  @Input() bandeauAffiche = true;
  /**
   * On affiche la confirmation ?
   */
    // isAsync = true, pour éviter les ExressionChangedAfterCheck dans la démo
  @Output() confirmationEvent: EventEmitter<RetourAnr> = new EventEmitter<RetourAnr>(true);
  demandeAnr: DemandeAnr;
  /** le component Anr Sms pour surcharger la methode OnSubmit si besoin */
  @ViewChild(AnrComponent) public anrComponent: AnrComponent;
  private state$: Observable<any>;

  constructor(private readonly activatedRoute: ActivatedRoute, private readonly logger: NGXLogger, private readonly userService: UserService) {
    super();
  }


  ngOnInit() {


    this.state$ = this.activatedRoute.paramMap
      .pipe(map(() => window.history.state));


    if (this.userService?.user?.isDemo) {
      // on affiche la confirmation immédiatement, en mode simple validation au cas ou.
      this.confirmationEvent.emit({created: true, bilan: undefined});
      return;
    }

    this.subscription.add(this.state$.subscribe((state) => {

      if (state && state.demandeAnr) {
        this.demandeAnr = state.demandeAnr;
      } else {
        this.erreur = 'La page a expiré, veuillez recommencer.';
        this.logger.warn('L\'utilisateur essaye certainement de charger la page anr hors du cycle normal. State : ', state);
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * On analyse la réponse pour voir si l'on continue vers une page de confirmation ou que l'on enchaine vers un nouvel Anr
   * @param reponse la reponse du WS
   */
  onConfirmation(reponse: HttpResponse<any>) {
    if (reponse.status === 200) {
      // la resource est en attente de validation
      this.confirmationEvent.emit({created: false, bilan: reponse.body});
    } else if (reponse.status === 201) {// la ressource a été crée
      this.confirmationEvent.emit({created: true, bilan: reponse.body});
    } else if (reponse.status === 202) {
      this.demandeAnr = reponse.body;
    } else {
      this.erreur = 'Une erreur est survenue dans l\'application';
    }
  }
}
