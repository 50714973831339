import { Directive, TemplateRef } from '@angular/core';

/**
 * A utiliser pour les templates dans les composants enfants.
 * Voir: https://angular.io/guide/content-projection#conditional
 */
@Directive({
  selector: '[appLoaded]',
})
export class LoadedDirective {
  constructor(public templateRef: TemplateRef<unknown>) {}
}
