/**
 * Hoba/wesoc API 2.0
 * API pour l'application bancaire SOCREDO.
 *
 * OpenAPI spec version: 3.00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {Observable} from 'rxjs';
import {Configuration} from '../conf/configuration';
import {CustomHttpUrlEncodingCodec} from '../conf/encoder';
import {BASE_PATH} from '../conf/variables';
import {Delegation} from '../model/delegation';
import {DemandeAnr} from '../model/demandeAnr';
import {Favoris} from '../model/favoris';
import {FavorisDispo} from '../model/favorisDispo';
import {FavorisUpdate} from '../model/favorisUpdate';
import {ListesDelegations} from '../model/listesDelegations';
import {NewDelegation} from '../model/newDelegation';
import {ProfilClient} from '../model/profilClient';
import {Synthese} from '../model/synthese';
import {ComptesDelegation} from '../model/comptesDelegation';
import {ListeVirementMultiBeneficiaire} from '../model/listeVirementMultiBeneficiaire';
import {InfoSouscription} from '../model/infoSouscription';
import {Souscription} from '../model/souscription';

@Injectable()
export class ClientService {

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  protected basePath = 'https://www.socms.pf/hoba/api';

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   *
   * Retourne la liste des comptes/cartes/crédits favoris de l&#x27;utilisateur pour ce client
   * @param numeroClient numéro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public favoris(numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<Favoris>;

  public favoris(numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Favoris>>;

  public favoris(numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Favoris>>;

  public favoris(numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling favoris.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Favoris>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/favoris`,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }

  /**
   *
   * Retourne la liste des comptes/cartes/crédits disponibles pour les favoris
   * @param numeroClient numéro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public favorisDispo(numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<FavorisDispo>;

  public favorisDispo(numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FavorisDispo>>;

  public favorisDispo(numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FavorisDispo>>;

  public favorisDispo(numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling favorisDispo.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<FavorisDispo>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/favoris/dispo`,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }

  /**
   *
   * Récupère la liste complète des comptes courants, épargnes, devises, placements, les crédits
   * @param numeroClient numéro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public synthese(numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<Synthese>;

  public synthese(numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Synthese>>;

  public synthese(numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Synthese>>;

  public synthese(numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling compte.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Synthese>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/synthese`,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }

  /**
   *
   * Récupération du token quadient
   * @param numeroClient numéro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public tokenQuadient(numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
  public tokenQuadient(numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
  public tokenQuadient(numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
  public tokenQuadient(numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling tokenQuadient.');
    }

    let headers = this.defaultHeaders;

    // authentication (BearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/tokenQuadient`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        responseType: 'text',
        reportProgress: reportProgress
      }
    );

  }

  /**
   *
   * Enregistre les favoris
   * @param body Les favoris à mettre à jour
   * @param numeroClient numéro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateFavoris(body: FavorisUpdate, numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<any>;

  public updateFavoris(body: FavorisUpdate, numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;

  public updateFavoris(body: FavorisUpdate, numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;

  public updateFavoris(body: FavorisUpdate, numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling updateFavoris.');
    }

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling updateFavoris.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<FavorisUpdate>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/favoris`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }

  /**
   *
   * Liste des virements multi-bénéficiaire
   * @param numeroClient numéro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public virementsMultiBeneficiaire(numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<ListeVirementMultiBeneficiaire>;
  public virementsMultiBeneficiaire(numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListeVirementMultiBeneficiaire>>;
  public virementsMultiBeneficiaire(numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListeVirementMultiBeneficiaire>>;
  public virementsMultiBeneficiaire(numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling virementsMultiBeneficiaire.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<ListeVirementMultiBeneficiaire>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/virementsMultiBeneficiaire`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Récupère le profil client
   * @param numeroClient numéro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public profilClient(numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<ProfilClient>;
  public profilClient(numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProfilClient>>;
  public profilClient(numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProfilClient>>;
  public profilClient(numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling profilClient.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<ProfilClient>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }

  /**
   *
   * Met à jour le profil client (email et agence)
   * @param body Le nouveau profil client : seuls l&#x27;email et l&#x27;agence sont mis à jour, le reste est ignoré
   * @param numeroClient numéro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateProfilClient(body: ProfilClient, numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<DemandeAnr>;
  public updateProfilClient(body: ProfilClient, numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DemandeAnr>>;
  public updateProfilClient(body: ProfilClient, numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DemandeAnr>>;
  public updateProfilClient(body: ProfilClient, numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling updateProfilClient.');
    }

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling updateProfilClient.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<DemandeAnr>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }

  /**
   *
   * Création d&#x27;une délégation
   * @param body La délégation à créer
   * @param numeroClient numéro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createDelegation(body: NewDelegation, numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<DemandeAnr>;
  public createDelegation(body: NewDelegation, numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DemandeAnr>>;
  public createDelegation(body: NewDelegation, numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DemandeAnr>>;
  public createDelegation(body: NewDelegation, numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling createDelegation.');
    }

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling createDelegation.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<DemandeAnr>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/delegations`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }

  /**
   *
   * Retourne la liste des délégations d&#x27;un client personne morale
   * @param numeroClient numéro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public delegations(numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<ListesDelegations>;
  public delegations(numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListesDelegations>>;
  public delegations(numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListesDelegations>>;
  public delegations(numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling delegations.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<ListesDelegations>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/delegations`,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }

  /**
   *
   * Renvoi le détail d&#x27;une délégation
   * @param numeroClient numéro du client
   * @param idDelegation id de la delegation
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public detailDelegation(numeroClient: string, idDelegation: number, observe?: 'body', reportProgress?: boolean): Observable<Delegation>;
  public detailDelegation(numeroClient: string, idDelegation: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Delegation>>;
  public detailDelegation(numeroClient: string, idDelegation: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Delegation>>;
  public detailDelegation(numeroClient: string, idDelegation: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling detailDelegation.');
    }

    if (idDelegation === null || idDelegation === undefined) {
      throw new Error('Required parameter idDelegation was null or undefined when calling detailDelegation.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Delegation>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/delegations/${encodeURIComponent(String(idDelegation))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }


  /**
   *
   * Modifie une délégation
   * @param body La délégation à modifier
   * @param numeroClient numéro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateDelegation(body: NewDelegation, numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<DemandeAnr>;
  public updateDelegation(body: NewDelegation, numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DemandeAnr>>;
  public updateDelegation(body: NewDelegation, numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DemandeAnr>>;
  public updateDelegation(body: NewDelegation, numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling updateDelegation.');
    }

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling updateDelegation.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<DemandeAnr>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/delegations`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Valide ou rejette une délégation
   * @param numeroClient numéro du client
   * @param idDelegation id de la delegation
   * @param validation validation (true) ou rejet (false) de la délégation
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public valideDelegation(numeroClient: string, idDelegation: number, validation?: boolean, observe?: 'body', reportProgress?: boolean): Observable<DemandeAnr>;

  public valideDelegation(numeroClient: string, idDelegation: number, validation?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DemandeAnr>>;

  public valideDelegation(numeroClient: string, idDelegation: number, validation?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DemandeAnr>>;

  public valideDelegation(numeroClient: string, idDelegation: number, validation?: boolean, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling valideDelegation.');
    }

    if (idDelegation === null || idDelegation === undefined) {
      throw new Error('Required parameter idDelegation was null or undefined when calling valideDelegation.');
    }


    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (validation !== undefined && validation !== null) {
      queryParameters = queryParameters.set('validation', <any>validation);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.put<DemandeAnr>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/delegations/${encodeURIComponent(String(idDelegation))}`,
      null,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Liste des comptes sur lesquels on peut affecter une délégation
   *
   * @param numeroClient numéro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listeComptesDelegation(numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<ComptesDelegation>;

  public listeComptesDelegation(numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ComptesDelegation>>;

  public listeComptesDelegation(numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ComptesDelegation>>;

  public listeComptesDelegation(numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling listeComptesDelegation.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<ComptesDelegation>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/delegations/comptes/`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Information nécéssaires à la souscription
   * @param numeroClient numéro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public infoSouscription(numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<InfoSouscription>;

  public infoSouscription(numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InfoSouscription>>;

  public infoSouscription(numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InfoSouscription>>;

  public infoSouscription(numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling infoSouscription.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<InfoSouscription>('get', `${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/souscription`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Soumet le formulaire de souscription
   * @param body Le formulaire de souscription
   * @param numeroClient numéro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public submitSubscription(body: Souscription, numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<DemandeAnr>;

  public submitSubscription(body: Souscription, numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DemandeAnr>>;

  public submitSubscription(body: Souscription, numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DemandeAnr>>;

  public submitSubscription(body: Souscription, numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling submitSubscription.');
    }

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling submitSubscription.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<DemandeAnr>('post', `${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/souscription`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * Met à jour la date vue information KYC
   * @param numeroClient numéro du client
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateVueInformationKyc(numeroClient: string, observe?: 'body', reportProgress?: boolean): Observable<any>;

  public updateVueInformationKyc(numeroClient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;

  public updateVueInformationKyc(numeroClient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;

  public updateVueInformationKyc(numeroClient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling updateVueInformationKyc.');
    }

    let headers = this.defaultHeaders;

    // authentication (BearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('put', `${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/informationKyc`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


}
