<div class="contact-widget" id="tuto9">
  <a  [routerLink]="['/messagerie']" class="contact-widget__item">
    <div class="contact-widget__item__icon"><img src="assets/images/icons/phone.png" alt="#"></div>
    <div class="contact-widget__item__text">Nous contacter</div>
  </a>
  <a [href]="urls.simulateur" class="contact-widget__item" target="_blank">
    <div class="contact-widget__item__icon"><img src="assets/images/icons/simulator.png" alt="#"></div>
    <div class="contact-widget__item__text">Simulateur</div>
  </a>
  <a [href]="urls.devises" class="contact-widget__item" target="_blank">
    <div class="contact-widget__item__icon"><img src="assets/images/icons/currency.png" alt="#"></div>
    <div class="contact-widget__item__text">Devises</div>
  </a>
  <a [href]="urls.tarifs" class="contact-widget__item" target="_blank">
    <div class="contact-widget__item__icon"><img src="assets/images/icons/tarif.png" alt="#"></div>
    <div class="contact-widget__item__text">Tarifs</div>
  </a>
  <a [routerLink]="['/faq']" class="contact-widget__item">
    <div class="contact-widget__item__icon"><img src="assets/images/icons/faq.png" alt="#"></div>
    <div class="contact-widget__item__text">FAQ</div>
  </a>
</div>
