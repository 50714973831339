import {AfterViewInit, Directive, ElementRef, HostListener, Input, NgZone, OnChanges, SimpleChanges} from '@angular/core';
import * as $ from 'jquery';
import {NGXLogger} from 'ngx-logger';
import 'radial-indicator';
import {UtilService} from '../services/prox-i/util.service';

/**
 * Le graphe ne s'affiche que s'il est dans la zone visible (inViewport) et pas déjà instancié.
 * C'est pour ce l'on écoute sur scroll pour ne l'afficher quand on arrive dessus.
 */
@Directive({
  selector: '[appRadialIndicator]',
})
export class RadialIndicatorDirective implements AfterViewInit, OnChanges {
  @Input() pourcent: number;
  /** Define inner radius of indicator. */
  @Input() radius = 50;
  /** background color for the bar */
  @Input() barBgColor = '#fff';

  private display = false;

  constructor(public el: ElementRef, private utilService: UtilService, public zone: NgZone, public logger: NGXLogger) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const radial = $(this.el.nativeElement).data('radialIndicator');
    if (radial) {
      radial.animate(this.pourcent);
    }
  }

  ngAfterViewInit() {
    this.radialChartHandle(0, this.pourcent);
  }

  @HostListener('window:scroll')
  scroll() {
    // on regarde s'il est visible quand on scroll
    this.radialChartHandle(0, this.pourcent);
  }

  radialChartHandle(startValue: number = 0, endValue: number = 0, color: string = '#00bdff'): void {
    if (this.display) {
      return;
    }

    const $element = $(this.el.nativeElement);
    if (!$element.length || !this.utilService.isInViewPort($element) || $element.find('canvas').length) {
      // on ne l'affiche pas s'il n'est pas visible (isInViewPort) ou déjà créé (canvas)
      return;
    }

    const radialOpts = {
      barBgColor: this.barBgColor,
      barColor: color,
      barWidth: 10,
      initValue: startValue,
      roundCorner: true,
      percentage: true,
      radius: this.radius,
      displayNumber: false,
    };

    this.zone.runOutsideAngular(() => {
      $element.radialIndicator(radialOpts);
      const chart = $element.data('radialIndicator');
      chart.animate(endValue);
    });
    this.display = true;
  }

}
