import {AfterViewInit, Directive, ElementRef, EventEmitter, NgZone, OnDestroy, Output} from '@angular/core';
import {NGXLogger} from 'ngx-logger';

@Directive({
  selector: '[appSlickCartes]',
})
export class SlickCartesDirective implements AfterViewInit, OnDestroy {
  /** Objet jquery de la liste. */
  private $cartesList: any;
  /** option du slick */
  private encoursOpt = {
    slidesToShow: 3,
    slidesToScroll: 3,
    infinite: false,
    dots: false,
    autoplay: false,
    autoplaySpeed: 5000,
    arrows: true,
    responsive: [{
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    }, {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
      },
    }],
  };

  /** Evénement sur le afterChange. */
  @Output() afterChange: EventEmitter<any> = new EventEmitter();

  constructor(public el: ElementRef, private zone: NgZone, private logger: NGXLogger) {
    this.$cartesList = $(this.el.nativeElement);
  }

  ngAfterViewInit(): void {
    this.fireAccountCarousel();
  }

  ngOnDestroy(): void {
    this.destroyAccountCarousel();
  }

  /**
   * Supprime le carousel.
   */
  private destroyAccountCarousel() {
    this.logger.debug('slick : Destroy');
    this.zone.run(() => {
      this.$cartesList.slick('unslick');
    });
  }

  /**
   * Crée le slick
   */
  private fireAccountCarousel() {
    this.logger.info('création slick cartes');
    this.zone.runOutsideAngular(() => {
      this.$cartesList.slick(this.encoursOpt);

      this.$cartesList.on('afterChange', (event, slick, currentSlide) => {
        this.zone.run(() => {
          this.afterChange.emit({event, slick, currentSlide});
        });
      });
    });
  }
}
