/**
 * Hoba/wesoc API 2.0
 * API pour l'application bancaire SOCREDO.
 *
 * OpenAPI spec version: 3.00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse,} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {Observable} from 'rxjs';
import {Configuration} from '../conf/configuration';
import {CustomHttpUrlEncodingCodec} from '../conf/encoder';
import {BASE_PATH} from '../conf/variables';
import {Mouvement} from '../model/mouvement';
import {NumeroCompte} from '../model/numeroCompte';
import {RechercheMouvementExport} from '../model/rechercheMouvementExport';

@Injectable()
export class MouvementService {

  protected basePath = 'https://www.socms.pf/hoba/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   * Pointage d&#x27;un mouvement
   * @param numeroClient numéro du client
   * @param idMouvement id du mouvement
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public pointage(numeroClient: string, idMouvement: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public pointage(numeroClient: string, idMouvement: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public pointage(numeroClient: string, idMouvement: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public pointage(numeroClient: string, idMouvement: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling pointage.');
    }

    if (idMouvement === null || idMouvement === undefined) {
      throw new Error('Required parameter idMouvement was null or undefined when calling pointage.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.put<any>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/mouvements/${encodeURIComponent(String(idMouvement))}/pointage`,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }

  /**
   *
   * Liste des opérations à venir
   * @param numeroClient numéro du client
   * @param numeroCompte numéro du compte
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public aVenir(numeroClient: string, numeroCompte: string, observe?: 'body', reportProgress?: boolean): Observable<Mouvement[]>;
  public aVenir(numeroClient: string, numeroCompte: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Mouvement[]>>;
  public aVenir(numeroClient: string, numeroCompte: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Mouvement[]>>;
  public aVenir(numeroClient: string, numeroCompte: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling aVenir.');
    }

    if (numeroCompte === null || numeroCompte === undefined) {
      throw new Error('Required parameter numeroCompte was null or undefined when calling aVenir.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Mouvement[]>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/comptes/${encodeURIComponent(String(numeroCompte))}/aVenir`,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }

  /**
   *
   * Catégorise un mouvement
   * @param numeroClient numéro du client
   * @param idMouvement id du mouvement
   * @param codeCategorie le catégorie du mouvement
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateCategorieMouvement(numeroClient: string, idMouvement: string, codeCategorie?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public updateCategorieMouvement(numeroClient: string, idMouvement: string, codeCategorie?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public updateCategorieMouvement(numeroClient: string, idMouvement: string, codeCategorie?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public updateCategorieMouvement(numeroClient: string, idMouvement: string, codeCategorie?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling updateCategorieMouvement.');
    }

    if (idMouvement === null || idMouvement === undefined) {
      throw new Error('Required parameter idMouvement was null or undefined when calling updateCategorieMouvement.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (codeCategorie !== undefined && codeCategorie !== null) {
      queryParameters = queryParameters.set('codeCategorie', codeCategorie as any);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.put<any>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/mouvements/${encodeURIComponent(String(idMouvement))}/categorie`,
      undefined,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }

  /**
   *
   * Export des mouvements sur la période en PDF ou CSV
   * @param body La recherche des mouvements
   * @param numeroClient numéro du client
   * @param numeroCompte numéro du compte
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public mouvementsExport(body: RechercheMouvementExport, numeroClient: string, numeroCompte: NumeroCompte, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
  public mouvementsExport(body: RechercheMouvementExport, numeroClient: string, numeroCompte: NumeroCompte, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
  public mouvementsExport(body: RechercheMouvementExport, numeroClient: string, numeroCompte: NumeroCompte, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
  public mouvementsExport(body: RechercheMouvementExport, numeroClient: string, numeroCompte: NumeroCompte, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling mouvementsExport.');
    }

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling mouvementsExport.');
    }

    if (numeroCompte === null || numeroCompte === undefined) {
      throw new Error('Required parameter numeroCompte was null or undefined when calling mouvementsExport.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/pdf',
      'text/csv'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<Blob>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/comptes/${encodeURIComponent(String(numeroCompte))}/mouvements/export`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        responseType: 'blob' as 'json',
      }
    );
  }

}
