/**
 * Hoba/wesoc API 2.0
 * API pour l'application bancaire SOCREDO.
 *
 * OpenAPI spec version: 3.00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import {
  HttpClient, HttpEvent, HttpHeaders,
  HttpParams, HttpResponse,
} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';

import {Observable} from 'rxjs';
import {Configuration} from '../conf/configuration';
import {CustomHttpUrlEncodingCodec} from '../conf/encoder';
import {BASE_PATH} from '../conf/variables';
import {Credit} from '../model/credit';
import {EcheanceCredit} from '../model/echeanceCredit';

@Injectable()
export class CreditService {

  protected basePath = 'https://www.socms.pf/hoba/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   * Récupère le détail d&#x27;un crédit
   * @param numeroClient numéro du client
   * @param idCredit id du crédit
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public credit(numeroClient: string, idCredit: string, observe?: 'body', reportProgress?: boolean): Observable<Credit>;
  public credit(numeroClient: string, idCredit: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Credit>>;
  public credit(numeroClient: string, idCredit: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Credit>>;
  public credit(numeroClient: string, idCredit: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling credit.');
    }

    if (idCredit === null || idCredit === undefined) {
      throw new Error('Required parameter idCredit was null or undefined when calling credit.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient
      .get<Credit>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/credits/${encodeURIComponent(String(idCredit))}`,
        {
          withCredentials: this.configuration.withCredentials,
          headers,
          observe,
          reportProgress,
        },
      );
  }

  /**
   *
   * Récupère les échéances d&#x27;un crédit
   * @param numeroClient numéro du client
   * @param idCredit id du crédit
   * @param dateDebut date de début au format yyyy-mm-dd
   * @param dateFin date de fin au format yyyy-mm-dd
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public echeancierCredit(numeroClient: string, idCredit: string, dateDebut: string, dateFin: string, observe?: 'body', reportProgress?: boolean): Observable<EcheanceCredit[]>;
  public echeancierCredit(numeroClient: string, idCredit: string, dateDebut: string, dateFin: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EcheanceCredit[]>>;
  public echeancierCredit(numeroClient: string, idCredit: string, dateDebut: string, dateFin: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EcheanceCredit[]>>;
  public echeancierCredit(numeroClient: string, idCredit: string, dateDebut: string, dateFin: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling echeancierCredit.');
    }

    if (idCredit === null || idCredit === undefined) {
      throw new Error('Required parameter idCredit was null or undefined when calling echeancierCredit.');
    }

    if (dateDebut === null || dateDebut === undefined) {
      throw new Error('Required parameter dateDebut was null or undefined when calling echeancierCredit.');
    }

    if (dateFin === null || dateFin === undefined) {
      throw new Error('Required parameter dateFin was null or undefined when calling echeancierCredit.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (dateDebut !== undefined && dateDebut !== null) {
      queryParameters = queryParameters.set('dateDebut', dateDebut as any);
    }
    if (dateFin !== undefined && dateFin !== null) {
      queryParameters = queryParameters.set('dateFin', dateFin as any);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient
      .get<EcheanceCredit[]>(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/credits/${encodeURIComponent(String(idCredit))}/echeancier`,
        {
          params: queryParameters,
          withCredentials: this.configuration.withCredentials,
          headers,
          observe,
          reportProgress,
        },
      );
  }

  /**
   *
   * Export des échéances crédit sur la période en PDF ou CSV
   * @param numeroClient numéro du client
   * @param idCredit id du crédit
   * @param dateDebut date de début des mouvements au format yyyy-mm-dd
   * @param dateFin date de fin des mouvements au format yyyy-mm-dd (maximum 90 jours)
   * @param format format de fichier : PDF ou Excel
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public echeancierCreditExport(numeroClient: string, idCredit: string, dateDebut: string, dateFin: string, format?: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
  public echeancierCreditExport(numeroClient: string, idCredit: string, dateDebut: string, dateFin: string, format?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
  public echeancierCreditExport(numeroClient: string, idCredit: string, dateDebut: string, dateFin: string, format?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
  public echeancierCreditExport(numeroClient: string, idCredit: string, dateDebut: string, dateFin: string, format?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (numeroClient === null || numeroClient === undefined) {
      throw new Error('Required parameter numeroClient was null or undefined when calling echeancierCreditExport.');
    }

    if (idCredit === null || idCredit === undefined) {
      throw new Error('Required parameter idCredit was null or undefined when calling echeancierCreditExport.');
    }

    if (dateDebut === null || dateDebut === undefined) {
      throw new Error('Required parameter dateDebut was null or undefined when calling echeancierCreditExport.');
    }

    if (dateFin === null || dateFin === undefined) {
      throw new Error('Required parameter dateFin was null or undefined when calling echeancierCreditExport.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (dateDebut !== undefined && dateDebut !== null) {
      queryParameters = queryParameters.set('dateDebut', dateDebut as any);
    }
    if (dateFin !== undefined && dateFin !== null) {
      queryParameters = queryParameters.set('dateFin', dateFin as any);
    }
    if (format !== undefined && format !== null) {
      queryParameters = queryParameters.set('format', format as any);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/pdf',
      'text/csv',
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get(`${this.basePath}/clients/${encodeURIComponent(String(numeroClient))}/credits/${encodeURIComponent(String(idCredit))}/echeancier/export`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers,
        responseType: 'blob',
        observe,
        reportProgress,
      },
    );
  }
}
