<nav class="main-nav">
  <div class="container">
    <div class="navbar">
      <div class="navbar-header"><a class="close-navbar cursor-pointer"><i class="plus-icon"></i></a><span
        class="visit-date">Dernière connexion le {{derniereConnexion() | date:'short'}}</span></div>
      <ul class="navbar-nav">

        <li *ngFor="let item of menuService.menu" class="nav-item">
          <ng-container *ngIf="item">
            <a *ngIf="item.externalUrl && item.action" [ngClass]="item.sousMenus?'':'submenu_none'" target="_blank"
               (click)="item.action()" class="nav-link dropdown cursor-pointer ">{{item.libelle}}</a>
            <a *ngIf="!item.externalUrl && item.url" [ngClass]="item.sousMenus?'':'submenu_none'"
               [routerLink]="[item.url]"
               class="nav-link dropdown">{{item.libelle}}</a>
            <a *ngIf="!item.externalUrl && !item.url" [ngClass]="item.sousMenus?'':'submenu_none'"
               class="nav-link dropdown cursor-pointer ">{{item.libelle}}</a>
          </ng-container>
          <div *ngIf="item.sousMenus" class="subnav"><a class="subnav-back" href="#"><i
            class="icon-angle-right"></i><span>{{item.libelle}}</span></a>
            <ul #d *ngFor="let subList of item.sousMenus" class="subnav__list">
              <li *ngIf="subList.libelle" class="subnav__list__item subnav__list__item--head">
                <span class="subnav__list__item__link2">{{subList.libelle}}</span>
              </li>
              <ng-container *ngFor="let sousItem of subList.sousMenus">
                <li class="subnav__list__item">
                  <a class="subnav__list__item__link cursor-pointer" *ngIf="sousItem.externalUrl && sousItem.action"
                     target="_blank"
                     (click)="sousItem.action()">{{sousItem.libelle}}</a>
                  <a class="subnav__list__item__link" *ngIf="sousItem.externalUrl && !sousItem.action" target="_blank"
                     [href]="sousItem.url">{{sousItem.libelle}}</a>
                  <a class="subnav__list__item__link" *ngIf="!sousItem.externalUrl"
                     [routerLink]="[sousItem.url]">{{sousItem.libelle}}</a>
                </li>
              </ng-container>
            </ul>
          </div>
        </li>

      </ul>
    </div>
  </div>
</nav>
