import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {Anr} from '../../../api/model/anr';
import {DemandeAnr} from '../../../api/model/demandeAnr';
import {AnrsService} from '../../services/anrs.service';
import {ErreursService} from '../../services/erreurs.service';
import {HttpResponse} from '@angular/common/http';

@Component({
  selector: 'app-confirmation-wesoc',
  templateUrl: './confirmation-wesoc.component.html',
  styleUrls: ['./confirmation-wesoc.component.css'],
})
export class ConfirmationWesocComponent implements OnInit {

  /** l'anr est validé il faut afficher la page de confirmation */
  @Output() confirmationEvent: EventEmitter<HttpResponse<DemandeAnr>> = new EventEmitter<HttpResponse<DemandeAnr>>();
  /** le titre de la page */
  @Input() titre: string;

  /** en cas d'erreur */
  erreur: string;
  /** le formulaire */
  formGroup: UntypedFormGroup;
  /** La demande ANR. */
  @Input() demandeAnr: DemandeAnr;
  /** submit? */
  isSubmit = false;

  constructor(private activatedRoute: ActivatedRoute, private logger: NGXLogger,
              private formBuilder: UntypedFormBuilder, private anrsService: AnrsService, private router: Router,
              private erreursService: ErreursService) {
  }

  ngOnInit() {

    this.formGroup = this.formBuilder.group({
      mdp: [null, [Validators.required, Validators.maxLength(64)]],
    });

  }


  retourLink() {
    this.router.navigate(['/home']);
  }

  validSubmit() {
    if (!this.isSubmit) {
      this.isSubmit = true;
      const anr: Anr = {
        codeAnr: this.formGroup.controls.mdp.value,
        cleAnr: this.demandeAnr.cleAnr,
      };
      this.anrsService.validationAnr(anr)
        .then((value) => {
          this.confirmationEvent.emit(value);
        }).catch((reason: Error) => {
        if (reason.name === '403') {
          this.erreursService.traiteErreur('Numéro d’abonnement ou mot de passe erroné. Vérifiez les informations saisies et réessayez');
        } else {
          this.erreur = reason.message;
        }
      })
        .finally(() => this.isSubmit = false);
    }
  }

}
