import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-contact-widget',
  templateUrl: './contact-widget.component.html',
  styleUrls: ['./contact-widget.component.css'],
})
export class ContactWidgetComponent implements OnInit {

  urls = environment.urlsInstitutionelles;

  constructor() { }

  ngOnInit() {
  }

}
